import React from "react"
import PropTypes from "prop-types"
import {
  interpolate as d3Interpolate,
  arc as d3Arc,
  select as d3Select,
  easeLinear as d3EaseLinear,
  easeQuadIn as d3EaseQuadIn,
  easeQuadOut as d3EaseQuadOut,
  easeQuadInOut as d3EaseQuadInOut,
  easeCubicIn as d3EaseCubicIn,
  easeCubicOut as d3EaseCubicOut,
  easeCubicInOut as d3EaseCubicInOut,
  easePolyIn as d3EasePolyIn,
  easePolyOut as d3EasePolyOut,
  easePolyInOut as d3EasePolyInOut,
  easeSinIn as d3EaseSinIn,
  easeSinOut as d3EaseSinOut,
  easeSinInOut as d3EaseSinInOut,
  easeExpIn as d3EaseExpIn,
  easeExpOut as d3EaseExpOut,
  easeExpInOut as d3EaseExpInOut,
  easeCircleIn as d3EaseCircleIn,
  easeCircleOut as d3EaseCircleOut,
  easeCircleInOut as d3EaseCircleInOut,
  easeBounceIn as d3EaseBounceIn,
  easeBounceOut as d3EaseBounceOut,
  easeBounceInOut as d3EaseBounceInOut,
  easeBackIn as d3EaseBackIn,
  easeBackOut as d3EaseBackOut,
  easeBackInOut as d3EaseBackInOut,
  easeElasticIn as d3EaseElasticIn,
  easeElasticOut as d3EaseElasticOut,
  easeElasticInOut as d3EaseElasticInOut,
  easeElastic as d3EaseElastic,
} from "d3";
import { calculateNeedleHeight } from "./utils";

const percToDeg = perc => perc * 360
const deg2rad = deg => (deg * Math.PI) / 180;
const percToRad = perc => deg2rad(percToDeg(perc));

function arcTween(arc, newAngle) {
  return function (d) {
    const interpolate = d3Interpolate(d.endAngle, newAngle);
    return function (t) {
      d.endAngle = interpolate(t);
      return arc(d);
    };
  };
}

function isStr(obj){
  return obj && typeof obj === "string";
}

const _table = {
  "easeLinear":  d3EaseLinear,
  "easeQuadIn":  d3EaseQuadIn,
  "easeQuadOut":  d3EaseQuadOut,
  "easeQuadInOut":  d3EaseQuadInOut,
  "easeCubicIn":  d3EaseCubicIn,
  "easeCubicOut":  d3EaseCubicOut,
  "easeCubicInOut":  d3EaseCubicInOut,
  "easePolyIn":  d3EasePolyIn,
  "easePolyOut":  d3EasePolyOut,
  "easePolyInOut":  d3EasePolyInOut,
  "easeSinIn":  d3EaseSinIn,
  "easeSinOut":  d3EaseSinOut,
  "easeSinInOut":  d3EaseSinInOut,
  "easeExpIn":  d3EaseExpIn,
  "easeExpOut":  d3EaseExpOut,
  "easeExpInOut":  d3EaseExpInOut,
  "easeCircleIn":  d3EaseCircleIn,
  "easeCircleOut":  d3EaseCircleOut,
  "easeCircleInOut":  d3EaseCircleInOut,
  "easeBounceIn":  d3EaseBounceIn,
  "easeBounceOut":  d3EaseBounceOut,
  "easeBounceInOut":  d3EaseBounceInOut,
  "easeBackIn":  d3EaseBackIn,
  "easeBackOut":  d3EaseBackOut,
  "easeBackInOut":  d3EaseBackInOut,
  "easeElasticIn":  d3EaseElasticIn,
  "easeElasticOut":  d3EaseElasticOut,
  "easeElasticInOut":  d3EaseElasticInOut,
  "easeElastic":  d3EaseElastic
};

class Gauge extends React.Component {
  static displayName = "Gauge";
  
  constructor(props) {
    super(props)

    this._d3_refs = {
      powerGauge: false,
    }

    this.resetInitValue();
  }

  resetInitValue(){
    this.initialValue = this.props.value || 0;
  }

  componentDidMount() {
    this.renderGauge();
  }

  render = () => {
    return <div ref={(ref) => (this.gaugeDiv = ref)} />
  }

  UNSAFE_componentWillReceiveProps() {
    this.resetInitValue();
  }

  shouldComponentUpdate(new_props) {
    this.props = new_props;
    return true;
  }

  componentDidUpdate() {
    this.renderGauge()
  }

  getGauge() {
    var self = this // save reference
    var PROPS = this.props

    return (container) => {
      // default config that are 'not' configurable
      var default_config = {
        minAngle: -90,
        maxAngle: 90,
        parentWidth: self.gaugeDiv.parentNode.clientWidth,
        parentHeight: self.gaugeDiv.parentNode.clientHeight
      }

      // START: Configurable values
      var config = {
        chartId: PROPS.chartId,
        width: PROPS.fluidWidth ? default_config.parentWidth : PROPS.width,
        height: PROPS.fluidWidth ? default_config.parentHeight : PROPS.height,
        // ring width should be 1/4 th of width
        ringWidth: PROPS.ringWidth,
        ringInset: PROPS.ringInset,
        // min/max values
        value: PROPS.value,
        // color of the speedometer needle
        needleColor: PROPS.needleColor,
        needleWidth: PROPS.needleWidth,
        // segments in the speedometer
        segments: PROPS.segments,
        segmentsColors: PROPS.segmentsColors,
        segmentGap: PROPS.segmentGap,
        barLabels: PROPS.barLabels,
        labelsLocations: PROPS.labelsLocations,
        gradientChange: PROPS.gradientChange,
        // needle configuration
        needleTransition: PROPS.needleTransition,
        needleTransitionDuration: PROPS.needleTransitionDuration,
        needleTransitionDelay: PROPS.needleTransitionDelay,
        needleHeightRatio: PROPS.needleHeightRatio,
        // text color
        labelsColor: PROPS.labelsColor,
      };
      // END: Configurable values

      // merge default config with the config
      config = Object.assign({}, default_config, config);

      var range = null,
        r = null,
        needleLength = null,
        svg = null,
        arc = null

      function configure() {
        range = config.maxAngle - config.minAngle
        r = Math.min(config.width, config.height * 2) / 2;

        needleLength = calculateNeedleHeight(config.needleHeightRatio, r)

        arc = d3Arc()
          .innerRadius(r - config.ringWidth - config.ringInset)
          .outerRadius(r - config.ringInset)
          .padAngle(config.segmentGap);
      }

      function render(newValue) {
        svg = d3Select(container)
          .append("svg:svg")
          .attr("class", "speedometer")
          .attr("width", config.width)
          .attr("height", config.height)

        var centerTx = "translate(" + r + "," + r + ")";

        var arcs = svg
          .append("g")
          .attr("class", "arc")
          .attr("transform", centerTx)

        if (config.gradientChange) {
          const arc = d3Arc()
            .innerRadius(r - config.ringWidth - config.ringInset)
            .outerRadius(r - config.ringInset)
            .startAngle(-0.5 * Math.PI)

          arcs
            .append("path")
            .datum({endAngle: 0.5 * Math.PI})
            .style("fill", "#F5F5F5").attr("d", arc);

            const foreground = arcs.append("path").style("fill", () => {
            let colors = config.segmentsColors[0];
            colors = isStr(colors)? [colors] : colors;
            const defs = arcs.append('defs');
            const linearGradient = defs
              .append('linearGradient')
              .attr('id', 'section-color-' + config.chartId)
              .attr('x1', '0%')
              .attr('y1', '100%')
              .attr('x2', '100%')
              .attr('y2', '0%');

            linearGradient
              .append('stop')
              .attr('offset', '0%')
              .attr('style', 'stop-color:' + colors[0] + ';stop-opacity:1');

            linearGradient
              .append('stop')
              .attr('offset', '100%')
              .attr('style', 'stop-color:' + colors[1] + ';stop-opacity:1');

              return 'url(' + '#section-color-' + config.chartId + ')';
          })
          .datum({endAngle: -0.5 * Math.PI}).attr("d", arc);

          foreground
            .transition()
            .delay(config.needleTransitionDelay)
            .duration(config.needleTransitionDuration)
            .attrTween("d", arcTween(arc, (newValue - 0.5) * Math.PI));

        } else {
          const arcData = [];
          let totalPercent = config.minAngle;
          for (let index = 0; index < config.segments.length; ++index) {
            const segmentPercentage = config.segments[index] * range;

            const startAngle = totalPercent;
            const endAgnle = startAngle + segmentPercentage;
            arcData[index] = {
              startAngle: deg2rad(startAngle),
              endAngle: deg2rad(endAgnle)
            }
            totalPercent += segmentPercentage;
          }

          arcs
            .selectAll("path")
            .data(arcData)
            .enter()
            .append("path")
            .attr("class", "speedo-segment")
            .style("fill", function(d, i) {
              const colors = config.segmentsColors[i];
              
              if(isStr(colors)){
                return [colors];
              }else if (colors.length <= 1) {
                return colors;
              } else {
                const defs = svg.append('defs');
                const linearGradient = defs
                  .append('linearGradient')
                  .attr('id', 'section-color-' + config.chartId)
                  .attr('x1', '0%')
                  .attr('y1', '100%')
                  .attr('x2', '100%')
                  .attr('y2', '0%');
                linearGradient
                  .append('stop')
                  .attr('offset', '0%')
                  .attr('style', 'stop-color:' + colors[0] + ';stop-opacity:1');
                linearGradient
                  .append('stop')
                  .attr('offset', '100%')
                  .attr('style', 'stop-color:' + colors[1] + ';stop-opacity:1');

                  return 'url(' + '#section-color-' + config.chartId + ')';
              }
            })
            .attr("d", arc)
        }

        /*
          Set the location of the labels of the guage
        */
        if (config.barLabels) {
          const locations = config.labelsLocations;
          config.barLabels.forEach((o, p) => {
            let labelX;
            let labelY;
            if (locations && locations.length > 0) {
              labelX = locations[p][0];
              labelY = locations[p][1];
            } else {
              labelX = 0;
              labelY = 0;
            }
            arcs
              .append('text')
              .attr('x', labelX)
              .attr('y', labelY)
              .attr('fill', config.labelsColor)
              .attr('style', 'font-size:12px;')
              .html(config.barLabels[p]);
          })
        }

        function _getRotate(percent) {
          const halfPI = Math.PI / 2;
          const thetaRad = percToRad(percent / 2);
          const rotate = (thetaRad - halfPI) * 180 / Math.PI;

          return 'rotate(' + rotate + ')';
        }

        var pg = svg
          .append("g")
          .attr("class", "pointer")
          .attr("transform", centerTx)
          .style("fill", config.needleColor)


        self._d3_refs.pointer = pg
          .append("path")
          .attr("d", 'M ' + (-1 * config.needleWidth) + ' ' + 0 + ' L ' + config.needleWidth + ' ' + 0 + ' L ' + (config.needleWidth / 2) + ' ' + (-1 * needleLength) + ' L ' + ( -1 * config.needleWidth / 2) + ' ' + (-1 * needleLength))
          .attr("transform", "rotate(" + config.minAngle + ")")
          .transition()
          .delay(config.needleTransitionDelay);

          if(!config.gradientChange){
            self._d3_refs.pointer.ease(self.getTransitionMethod(config.needleTransition));
          }

          self._d3_refs.pointer
            .duration(config.needleTransitionDuration)
            .attr('transform', _getRotate(config.value));
      }

      configure()

      // return a object with all our functions;
      // also expose the 'config' object; for now, we will update the 'labelFormat' while updating
      return {
        configure,
        render,
        config
      }
    }
  }

  renderGauge() {
    d3Select(this.gaugeDiv)
      .select("svg")
      .remove()

    this._d3_refs.powerGauge = this.getGauge()(this.gaugeDiv);
    this._d3_refs.powerGauge.render(this.initialValue);
  }


  

  getTransitionMethod(transition) {
    return _table[transition] || d3EaseQuadInOut;
  }
}

Gauge.propTypes = {
  chartId: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  fluidWidth: PropTypes.bool.isRequired,
  segments: PropTypes.array.isRequired,
  segmentsColors: PropTypes.array.isRequired,
  segmentGap: PropTypes.number.isRequired,
  barLabels: PropTypes.array.isRequired,
  labelsLocations: PropTypes.array.isRequired,
  needleColor: PropTypes.string.isRequired,
  needleTransition: PropTypes.string.isRequired,
  needleTransitionDuration: PropTypes.number.isRequired,
  needleHeightRatio: PropTypes.number.isRequired,
  ringWidth: PropTypes.number.isRequired,
  ringInset: PropTypes.number.isRequired,
  labelsColor: PropTypes.string.isRequired,
  gradientChange: PropTypes.bool.isRequired
}

// define the default proptypes
Gauge.defaultProps = {
  chartId: '',
  value: 0,
  segmentGap: 0,
  width: 120,
  height: 80,
  fluidWidth: false,
  segments: [],
  segmentsColors: [['#C62B12'], ['#DD8075'], ['#7ED2FB'], ['#25B4F8']],
  barLabels: [],
  labelsLocations: [['-47', '15'], ['32', '15']],
  needleWidth: 3.5,
  needleColor: "#202020",
  gradientChange: false,
  needleHeightRatio: 0.9,
  ringWidth: 12,
  ringInset: 0,
  labelsColor: "#666",
  needleTransition: "easeQuadInOut",
  needleTransitionDuration: 3000,
  needleTransitionDelay: 0
};

export default Gauge;
