//@flow
import * as React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import ReactDOM from 'react-dom';

const KODIV_CN = 'a-keyboard-hover-only-div';

///a duv only shows outline when keyboard focus
// no outline when mouse focus
class KODIV extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      allowOutline: true
    };
  }

  //hacking for upload open file dialog
  disableOutlineForFileDialog() {
    this.setState({
      allowOutline: false,
    });
    this.cancelBlurOnce = true;
  }

  onMouseDown = event => {
    this.setState({
      allowOutline: false,
    });

    const { onMouseDown } = this.props;
    onMouseDown && onMouseDown(event);
  };

  onBlur = event => {
    if (this.cancelBlurOnce) {
      this.cancelBlurOnce = false;
      return;
    }

    this.setState({
      allowOutline: true
    });

    const { onBlur } = this.props;
    onBlur && onBlur(event);
  };

  onDragEnter = event => {
    if (!this.props.dragable) {
      return;
    }

    this.setState({
      allowOutline: true,
      inDrag: true
    });

    this.props.onDragEnter && this.props.onDragEnter(event);
  }

  onDragLeave = event => {
    if (!this.props.dragable) {
      return;
    }

    event.preventDefault();

    const dom = ReactDOM.findDOMNode(this.dom);
    const container = dom.getClientRects();
    if(!container[0]){
      return;
    }
    const { left, right, top, bottom } = container[0];

    const x = event.clientX;
    const y = event.clientY;

    if (x <= left || x >= right || y <= top || y >= bottom) {
      this.setState({
        allowOutline: false,
        inDrag: false
      });
    }

    this.props.onDragLeave && this.props.onDragLeave(event);
  }

  onDrop = () => {
    if (!this.props.dragable) {
      return;
    }

    this.setState({
      allowOutline: false,
      inDrag: false
    });
  }

  onKeyDownDefault = event => {
    if (this.props.isButton) {
      return;
    }
    if (event.key === "Enter") {
      const dom = ReactDOM.findDOMNode(this.dom);
      dom.click();
    }
  }

  render() {
    const {
      className,
      children,
      isSpan,
      isButton,
      tabIndex,
      dragable,
      isLi,
      isLoading,
      isLoadingBlocking,

      onClick,
      onMouseDown,
      onBlur,
      onDragEnter,
      onDragLeave,
      onDrop,
      onKeyDown,

      ...others
    } = this.props;

    const classNames = ClassNames(className, KODIV_CN, {
      "a-no-outline-click": !this.state.allowOutline,
      "in-drag": this.state.allowOutline && this.state.inDrag
    });


    const commonProps = {
      tabIndex: (tabIndex || 0),
      className: classNames,
      onClick: onClick || (() => { }),
      onMouseDown: this.onMouseDown,
      onBlur: this.onBlur,
      onDragEnter: this.onDragEnter,
      onDragLeave: this.onDragLeave,
      onDrop: this.onDrop,
      onKeyDown: onKeyDown || this.onKeyDownDefault,
      ref: ref => this.dom = ref,
    }

    if (isLi) {
      return (
        <li {...commonProps}  {...others}>
          {children}
        </li>
      );
    } else if (isButton) {
      if (this.props.isLoading && this.props.isLoadingBlocking) {
        commonProps.onClick = () => { }
      }

      return (
        <button type='button'
          {...commonProps}  {...others}>
          {children}
        </button>
      );
    } else if (isSpan) {
      return (
        <span {...commonProps}  {...others}>
          {children}
        </span>
      );
    } else {
      return (
        <div {...commonProps} {...others}>
          {children}
        </div>
      );
    }
  };
}

KODIV.propTypes = {
  isSpan: PropTypes.bool,
  isButton: PropTypes.bool,
  isLi: PropTypes.bool,
  dragable: PropTypes.bool
};

KODIV.displayName = 'KODIV';

export default KODIV;
