import PropTypes from 'prop-types';

const treeNode = {
  label: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.node
};

const TreeNodeWithChildren = PropTypes.oneOfType([
  PropTypes.shape(treeNode),
  PropTypes.shape({
    ...treeNode,
    children: PropTypes.arrayOf(treeNode).isRequired
  })
]);

export default TreeNodeWithChildren;
