//@flow
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';
import { ListItem } from '../index';

type SelectListItemProps = {
  disabled: boolean,
  /**
   * Callback fired when the listitem is clicked.
   */
  onSelect?: Function,
  /**
   * If `true`, the listitem is selected
   */
  active?: boolean | string,
  /**
   * The value of the listitem, once selected, the value will be passed thru onSelect func
   */
  value?: any
}

type SelectListItemState = {
  active?: boolean
};

class SelectListItem extends PureComponent<
  SelectListItemProps & CommonType,
  SelectListItemState
> {
  constructor(props: Object) {
    super(props);
    const active: boolean =
      'active' in this.props ? Boolean(this.props.active) : false;
    this.state = {
      active
    };
  }
  static contextTypes = {
    selectList: PropTypes.any
  };

  handleListClick (e: SyntheticEvent<*>) {
    //https://stackoverflow.com/questions/50819162/why-is-my-function-being-called-twice-in-react
    e.preventDefault && e.preventDefault();
    const { value, showAddButton, disabled, disabledForTags } = this.props;

    if(disabled || disabledForTags){
      return;
    }

    if(showAddButton && e.target.className !== "a-select-list-add-button"){
      return;
    }

    if (e.target.type === 'radio') {
      return;
    }

    const { active } = this.state;
    this.setState({
      active: !active
    });

    if(this.context && this.context.selectList){
      const { onSelect } = this.context.selectList;
      onSelect && onSelect(e, value);
    }
  }

  onKeyDown(e: SyntheticKeyboardEvent<*>) {
    if (e.keyCode === 13) {
      this.handleListClick(e);
    }
  }

  render() {
    const {
      disabled = false,
      disabledForTags = false,
      style,
      className,
      subHeader,
      children,
      active,
      showAddButton,
      keyboardHighlight,
      ...otherProps
    } = this.props;
    const _active =
      'active' in this.props
        ? Boolean(this.props.active)
        : Boolean(this.state.active);
    const classes: string = ClassNames(className, {
      [`active`]: !!_active && !disabled && !disabledForTags ,
      "with-add-button": showAddButton,
      keyboardHighlight: keyboardHighlight
    });

    if (!subHeader) {
      return (
        <div
          onKeyDown={this.onKeyDown}
          onClick={this.handleListClick.bind(this)}
          className={ClassNames('list-item-wrapper', {
            disabled: disabled,
            disabledForTags: disabledForTags
          })}>
          <ListItem
            style={style}
            className={classes}
            subHeader={subHeader}
            disabled={disabled}
            {...otherProps}
          >
            {children}
          </ListItem>
        </div>
      );
    }
    return (
      <ListItem className={classes} subHeader={subHeader} {...otherProps}>
        {children}
      </ListItem>
    );
  }
}

SelectListItem.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  subHeader: PropTypes.bool,
  onSelect: PropTypes.func,
  active: PropTypes.bool,
  value: PropTypes.any,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  disabledForTags: PropTypes.bool,
  showAddButton: PropTypes.bool
};

SelectListItem.displayName = 'SelectListItem';

export default SelectListItem;
