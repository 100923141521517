//@flow
import React, { Component } from 'react';
import type { Node } from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import type { CommonType } from 'appkit-react-utils/commonType';

type ListItemTextProps = {
  /**
   * The content of List
   */
  children?: Node,
  /**
   * This is the primary text in the list.
   */
  primary?: string,
  /**
   * This is the secondary text in the list.
   */
  secondary?: string,
};

type ListItemTextState = {};

class ListItemText extends Component<
  ListItemTextProps & CommonType,
  ListItemTextState
> {

  render() {
    const {
      style,
      className,
      children,
      primary: primaryProp,
      secondary: secondaryProp,
      ...otherProps
    } = this.props;

    let primary = primaryProp != null ? primaryProp : children;
    if (primary != null) {
      primary = (
        <span className="primary">{primary}</span>
      );
    }

    let secondary = secondaryProp;
    if (secondary != null) {
      secondary = (
        <span className="secondary a-opacity-50">{secondary}</span>
      );
    }
    const content=(primary&&secondaryProp)?<React.Fragment>
                          {primary}
                          {secondary}
                        </React.Fragment>:primary
    return (
      <div
        className={ClassNames('a-list-item-text', className)}
        style={style}
        {...otherProps}
      >
        {content}
      </div>
    );
  }
}

ListItemText.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  primary: PropTypes.string,
  secondary: PropTypes.string,
  children: PropTypes.any
};

ListItemText.displayName = 'ListItemText';

export default ListItemText;
