//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';

type ListItemIconProps = {
  /**
   * If kind equals `action`, it will be an action icon
   */
  kind?: 'default' | 'action',
  icon: any
};

type ListItemIconState = {};

class ListItemIcon extends Component<ListItemIconProps & CommonType, ListItemIconState> {
  render() {
    const { kind, className, icon, ...otherProps } = this.props;
    const classes: string = classNames(className, 'a-list-item-icon', {
      [`a-control-icon`]: kind === 'action'
    });
    return (
      <div className={classes} {...otherProps}>
        {icon}
      </div>
    );
  }
}

ListItemIcon.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  kind: PropTypes.oneOf(['default', 'action']),
  icon: PropTypes.any
};

ListItemIcon.displayName = 'ListItemIcon';

export default ListItemIcon;
