//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import type { Node } from 'react';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';
import { isEmptyObject } from 'appkit-react-utils/commonFunc';
import TreeNode from './TreeNode';
import ClickOutside from 'react-click-outside';


type TreeProps = {
  nodes: Array<Node>,
  checked: Array<string>,
  disabled?: boolean,
  expanded: Array<string>,
  expandDisabled: boolean,
  showCheckbox?: boolean,
  showNodeIcon: boolean,
  onExpand: Function,
  onCheck: Function,
  children?: Node,
  /**
   * @First Icon : Open
   * @Second Icon: Close
   */
  collapseIcons?: Array<any>,
  size?: 'default' | 'large'
};

type TreeState = {
  checked: Array<string>
};

function findNode(nodes, value, parentStack){
  for(let ii = 0; ii < nodes.length; ii++){
    const n = nodes[ii];
    if(n.value === value){
      return n;
    }

    parentStack && parentStack.push(n.value);
    const temp = n.children && findNode(n.children, value, parentStack);
    if(temp){
      return temp;
    }
    parentStack && parentStack.pop();
  }
  return null;
}


//if all child in arr, so do parent
function postOrderTreeTreversal(node, arr){
  const checked = arr.includes(node.value);
  if(checked){
    return true;
  } else if(!node.children){
    return checked;
  } else {
    let allChildChecked = true;

    node.children.forEach(child => {
      allChildChecked = postOrderTreeTreversal(child, arr) && allChildChecked;
    });

    if(allChildChecked){
      arr.push(node.value);
      return true;
    }else{
      return false;
    }
  }
}

//if parent in arr, so do all child
function prerorderTreeTreversal(node, arr, parentAdd){
  let checked = arr.includes(node.value);

  if(!checked && parentAdd){
    arr.push(node.value);
    checked = true;
  }

  if(node.children){
     node.children.forEach(child => {
      prerorderTreeTreversal(child, arr, checked);
    })
  }
}

function getAllSubchildren(node, arr){
  const e = node.value;
  arr.push(e);

  if(node.children){
    node.children.forEach(c => {
      getAllSubchildren(c, arr);
    })
  }

  return arr;
}

//pre-order: parent -> child
function addAllChildren2Arr(node, arr){
  //add its child recursively
  const e = node.value;
  if(!arr.includes(e)){
    arr.push(e);
  }

  if(node.children){
    node.children.forEach(c => {
      addAllChildren2Arr(c, arr);
    })
  }
}

class Tree extends Component<TreeProps & CommonType, TreeState> {

  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      expanded: [],
      selectedValue: ''
    }
  }

  onExpand(node) {
    if(!this.props.expanded){
      const value = node.value;
      let newExpanded = this.state.expanded.slice();
      if(newExpanded.includes(value)){
        newExpanded = newExpanded.filter(e => e !== value);
      }else{
        newExpanded.push(value);
      }
      this.setState({expanded: newExpanded});
    }
    if (this.props.onExpand) {
      this.props.onExpand(node);
    }
  }

  onCheck(node) {
    if(!this.props.checked){
      this.toggleChecked(node);
    }
    if (this.props.onCheck) {
      this.props.onCheck(node);
    }
  }

  onSelect(node) {
    this.setState({
      selectedValue: node.value
    });
    this.props.onSelect && this.props.onSelect(node);
  }

  toggleChecked(node) {
    const value = node.value;
    let newChecked = this.state.checked.slice();
    let parentStack = [];
    const singleNode = findNode(this.props.nodes, value, parentStack);
    const includedByParent = parentStack.some(p => newChecked.includes(p));

    if(!singleNode){
      //for ut
      return;
    }

    if( !newChecked.includes(value) && !includedByParent){
      newChecked.push(value);
      addAllChildren2Arr(singleNode, newChecked);
      postOrderTreeTreversal(this.props.nodes[0], newChecked);

      this.setState({
        checked: newChecked
      });

    } else {
      //remove itsself and its parent
      parentStack.push(value);
      const subchildren = getAllSubchildren(singleNode, []);
      parentStack = parentStack.concat(subchildren);

      newChecked = newChecked.filter(e => {
        return !parentStack.includes(e);
      });

      postOrderTreeTreversal(this.props.nodes[0], newChecked);
      this.setState({
        checked: newChecked
      })
    }
  }

  isNodeExpanded(node){
    return (this.props.expanded || this.state.expanded).includes(node.value);
  }

  initialNodes(nodes) {
    return nodes.map(node => {
      const initialNode = { ...node };
      initialNode.expanded = this.isNodeExpanded(node);

      if (Array.isArray(node.children) && node.children.length > 0) {
        initialNode.children = this.initialNodes(node.children);
      } else {
        initialNode.children = null;
      }
      return initialNode;
    });
  }

  getNodesInTabOrder(nodes, resultArr){
    const { showCheckbox } = this.props;
    nodes.forEach(node => {
      const hasChild = Array.isArray(node.children) && node.children.length > 0;

      if(!showCheckbox){
        if (hasChild) {
          resultArr.push(node);

          if(this.isNodeExpanded(node)){
            this.getNodesInTabOrder(node.children, resultArr);
          }
        }
      }else{
        resultArr.push(node);
        if (hasChild && this.isNodeExpanded(node)) {
          this.getNodesInTabOrder(node.children, resultArr);
        }
      }
    });
  }

  onKeyDown(e){
    const isEnter = e.key === "Enter";
    const isTab = e.key === "Tab";
    const isDown = e.key === "ArrowDown";
    const isUp = e.key === "ArrowUp";
    const isSpace = e.key === " ";
    const isShift = e.shiftKey;
    const goUp = (isTab && isShift) || isUp;


    let preventDefault = true;
    const { currentTabNode } = this.state;
    const { showCheckbox, disabled, expandDisabled } =  this.props;

    if(disabled || expandDisabled){
      return;
    }

    if(isTab || isDown || isUp){
      const nodesInTab = [];
      this.getNodesInTabOrder(this.props.nodes, nodesInTab);
      const lastIndex = nodesInTab.length - 1;

      if(!currentTabNode){
        this.setState({
          currentTabNode: isTab && isShift? nodesInTab[lastIndex] : nodesInTab[0]
        });
      }else{
        let index = nodesInTab.map(e => e.value).indexOf(currentTabNode.value);
        if(goUp){
          index--;
        }else{
          index++;
        }

        if(index < 0 || index > lastIndex){
          this.setState({
            currentTabNode: null
          });
          preventDefault = false;
        }else{
          this.setState({
            currentTabNode: nodesInTab[index]
          });
        }
      }
    } else if(isEnter && currentTabNode){
        this.onExpand({
          value: currentTabNode.value
        });
    }else if(showCheckbox && currentTabNode && isSpace){
      this.onCheck({
        value: currentTabNode.value
      });
    }

    if(preventDefault){
      e.preventDefault();
    }
  }

  handleClickOutside() {
    this.setState({
      currentTabNode: null
    })
  }

  getDisabledStatus(node, parent, disabled) {
    if (disabled) {
      return true;
    }
    if (parent.disabled) {
      return true;
    }

    return Boolean(node.disabled);
  }

  precalculateChecked(){
    if(!this.props.showCheckbox){
      return;
    }

    this.checked = (this.props.checked || this.state.checked || []).slice();
    prerorderTreeTreversal(this.props.nodes[0], this.checked);

    //decide from children ----> parent
    postOrderTreeTreversal(this.props.nodes[0], this.checked);
  }

  renderTreeNode(nodes, parent, level) {
    const {
      disabled = false,
      expandDisabled,
      showCheckbox,
      showNodeIcon,
      collapseIcons
    } = this.props;

    const {
      currentTabNode
    } = this.state;


    const isParentEmpty = isEmptyObject(parent);

    const olClasses = ClassNames("a-tree-sub-node-list", {
      "nested": !isParentEmpty && nodes
    });

    const treeNodes = nodes.map(node => {
      const key = `${node.value}`;
      const checked = this.props.showCheckbox && this.checked.includes(node.value);
      const children = this.renderChildNodes(node, level + 1);
      const nodeDisabled = this.getDisabledStatus(node, parent, disabled);

      return (
        <TreeNode
          key={key}
          checked={checked}
          className={node.className}
          disabled={nodeDisabled}
          expandDisabled={expandDisabled}
          expanded={node.expanded}
          visible={!isParentEmpty ? parent.expanded : true}
          icon={node.icon}
          keyboardHighlight={currentTabNode && key === currentTabNode.value}
          label={node.label}
          rawChildren={node.children}
          showCheckbox={showCheckbox}
          showNodeIcon={showNodeIcon}
          value={node.value}
          collapseIcons={collapseIcons}
          onCheck={this.onCheck.bind(this)}
          onExpand={this.onExpand.bind(this)}
          onSelect={this.onSelect.bind(this)}
          selectedValue={this.state.selectedValue}
        >
          {children}
        </TreeNode>
      );
    });
    return <div className={olClasses}>{treeNodes}</div>;
  }

  //recursive rendering
  renderChildNodes(node, level: number) {
    if (node.children !== null && node.expanded) {
      return this.renderTreeNode(node.children, node||{}, level);
    }

    return null;
  }

  render() {
    const {nodes, className, disabled, expandDisabled} = this.props;

    const _nodes = this.initialNodes(nodes);
    this.precalculateChecked(_nodes);
    const treeNodes = this.renderTreeNode(_nodes, {}, 0);
    const cn = ClassNames(className, "a-tree", {
      'a-tree-disabled': disabled
    });

    return <div className={cn}
                onKeyDown={this.onKeyDown.bind(this)}
                tabIndex={(disabled || expandDisabled )? -1 : 0}>
              {treeNodes}
            </div>;
  }
}

Tree.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  size: PropTypes.oneOf(['default', 'large']),

  checked: PropTypes.arrayOf(PropTypes.string),
  expanded: PropTypes.arrayOf(PropTypes.string),
  expandDisabled: PropTypes.bool,

  showCheckbox: PropTypes.bool,
  showNodeIcon: PropTypes.bool,

  collapseIcons:  PropTypes.arrayOf(PropTypes.any)
};

Tree.displayName = 'Tree';

export default ClickOutside(Tree);
