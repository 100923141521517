//@flow
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import type { CommonType } from "appkit-react-utils/commonType";
import { NoClickOutsideCheckbox } from "../index";
import SelectListItem from "./SelectListItem";
import ClassNames from "classnames";

type SelectOptionProps = {
  /**
   * True or false to switch on or off the highlight background.
   */
  isActive?: boolean,
  value: any,
  multiple?: boolean,
  children?: Node,
  //the component display on the select toggle
  selectedDisplay: any
};

type SelectOptionState = {};

class SelectOption extends PureComponent<
  SelectOptionProps & CommonType,
  SelectOptionState
> {
  state: SelectOptionState;

  static defaultProps = {
      showAddButton: false,
      disabled: false,
      disabledForTags: false
  }

  constructor(props: Object) {
    super(props);
  }

  render() {
    const {
      children,
      multiple,
      value,
      isActive,
      selectedDisplay,
      searchableText,
      showAddButton,
      disabled,
      disabledForTags,
      tagStyle,
      tagHeader,
      tagText,
      keyboardHighlight,
      ...others
    } = this.props;

    let content =
      multiple && !showAddButton && !disabledForTags ? (
        <NoClickOutsideCheckbox switchTabIndex={-1} checked={isActive} disabled={disabled}>
          {children}
        </NoClickOutsideCheckbox>
      ) : (
        children
      );

    const addClass = ClassNames("a-select-list-add-button", {
      isActive: isActive
    });
    const addButton = showAddButton && (
      <div className={addClass}>{isActive ? "ADDED" : "ADD"}</div>
    );

    return (
      <SelectListItem
        value={value}
        active={isActive}
        disabled={disabled}
        disabledForTags={disabledForTags}
        showAddButton={showAddButton}
        keyboardHighlight={keyboardHighlight}
        {...others}
      >
        { content }
        { addButton }
      </SelectListItem>
    );
  }
}

SelectOption.propTypes = {
  isActive: PropTypes.bool,
  value: PropTypes.any,
  children: PropTypes.any,
  selectedDisplay: PropTypes.any,
  disabled: PropTypes.bool,
  // internal prop
  disabledForTags: PropTypes.bool,

  // when true, add ui and different effect
  // only clickable in add button
  showAddButton: PropTypes.bool,

  //read by select
  searchableText: PropTypes.string,

  tagStyle: PropTypes.object,
  tagHeader: PropTypes.any,
  tagText: PropTypes.any,

  keyboardHighlight: PropTypes.bool,
};

SelectOption.displayName = "SelectOption";

export default SelectOption;
