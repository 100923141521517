import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function ListItemAction(props) {
  const { children, className, ...other } = props;

  return (
    <div className={classNames(className, 'a-list-item-action')} {...other}>
      {children}
    </div>
  );
}

ListItemAction.propTypes = {
  /**
   * The content of the component, normally an `IconButton` or selection control.
   */
  children: PropTypes.node,

  className: PropTypes.string,
};

export default ListItemAction;
