//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';

import {
  sortableHandle,
} from 'react-sortable-hoc';

const DragHandle = sortableHandle(({icon}) => {
  return icon;
});

export type ListItemProps = {
  /**
   * The content of ListItem
   */
  children?: any,
  /**
   * If `true`, the listitem will be performed as list subheader
   */
  subHeader?: boolean,
  /**
   * If `true`, the left and right padding is removed from the listitem
   */
  disableGutters?: boolean,
  /**
   * The value of the listitem, once selected, the value will be passed thru onSelect func
   */
  value?: any,
  renderListItem?: Function
};

type ListItemState = {};

/**
 * Check if the component is List
 * @param {*} item
 */

class ListItem extends Component<ListItemProps & CommonType, ListItemState> {
  render() {
    const {
      style,
      className,
      disableGutters,
      subHeader,
      children,
      dragIcon,
      ...otherProps
    } = this.props;

    const classes: string = ClassNames('a-list-item', className, {
      [`list-item-disable-gutters`]: disableGutters,
      [`list-sub-header`]: subHeader
    });
    return (
      <li style={style} className={classes} {...otherProps}>
        {children}
        {dragIcon && <DragHandle icon={dragIcon} />}
      </li>
    );
  }
}

ListItem.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  subHeader: PropTypes.bool,
  disableGutters: PropTypes.bool,
  children: PropTypes.any,
  dragIcon: PropTypes.any
};

ListItem.displayName = 'ListItem';

export default ListItem;
