//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';

type StringOrStringArray = string | Array<string>;

type AccordionsProps = {
  kind?: 'normal' | 'transparent',
  /**
   * Enable opening more than one items
   */
  multiOpen?: boolean,
  /**
   * The opened items
   */
  activeItem?: StringOrStringArray,
  /**
   * Callback function that is fired when a accordion has been clicked.
   */
  onChange?: Function,
  children?: Node
};

type AccordionsState = {
  activeItem: Array<string>
};

class Accordions extends Component<
  AccordionsProps & CommonType,
  AccordionsState
> {
  state: AccordionsState;

  static defaultProps = {
    kind: 'normal',
    activeItem: [],
    multiOpen: true,
    onChange() {}
  };

  constructor(props: Object) {
    super(props);
    const { activeItem = [] } = this.props;
    this.state = { activeItem: this.getActiveItem(activeItem) };
  }

  getActiveItem(activeItem){
    let result;
    const isArray = Array.isArray(activeItem);
    if (!this.props.multiOpen && isArray) {
        result = [activeItem[0]];
    } else {
        result = isArray? activeItem : [activeItem];
    }
    return result;
  }

  //getDerivedStateFromProps is static, need to refactor way too many code
  //componentDidUpdate is called after each render
  //ends up implementing my own componentWillReceiveProps
  //!!!trash. Just use UNSAFE_ for now
  UNSAFE_componentWillReceiveProps(nextProps: Object) {
    this.setActiveItem(nextProps.activeItem);
  }

  setActiveItem(activeItem: StringOrStringArray): void {
    if (activeItem) {
      this.setState({ activeItem: this.getActiveItem(activeItem) });
    }
  }

  handleItemClick(key: string) {
    const { activeItem } = this.state;
    if (this.props.multiOpen) {
      if (activeItem.includes(key)) {
        this.setActiveItem(activeItem.filter(item => item !== key));
      } else {
        this.setActiveItem(activeItem.concat(key));
      }
    } else {
      this.setActiveItem(activeItem[0] && activeItem[0] === key ? '' : key);
      if (activeItem.includes(key)) {
        this.setActiveItem(activeItem.filter(item => item !== key));
      }
    }
    this.props.onChange && this.props.onChange(key);
  }
  render() {
    const {
      style,
      className,
      kind,
      children,
      activeItem,
      multiOpen,
      onChange,
      ...otherProps
    } = this.props;
    const classes = ClassNames('a-accordion', className, {
      'a-accordion-transparent': kind === 'transparent'
    });
    const content = React.Children.map(this.props.children, (child, index) => {
      let itemId = child.props.itemId;
      if (itemId === undefined) {
        itemId = (index + 1).toString(10);
      }
      return React.cloneElement(child, {
        itemId: itemId,
        isActive: this.state.activeItem.includes(itemId),
        onClick: item => this.handleItemClick(item)
      });
    });
    return (
      <div style={style} className={classes} {...otherProps}>
        {content}
      </div>
    );
  }
}

Accordions.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  kind: PropTypes.oneOf(['normal', 'transparent']),
  multiOpen: PropTypes.bool,
  activeItem: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func,
  children: PropTypes.any
};

Accordions.displayName = 'Accordions';

export default Accordions;
