//@flow
import React from 'react';
import type { CommonType } from 'appkit-react-utils/commonType';

export type RadioGroupProps = {
  /**
   * The name that will be applied to all child radio buttons.
   */
  name?: string,

  /**
   * Should be used to pass RadioButton or Radio components.
   */
  children?: any,
  /**
   * Callback function that is fired when a radio button has been checked.
   */
  onChange?: Function,
  /**
   * The value of the currently selected radio button
   */
  value?: any,
  /**
   * The default selected radio button
   */
  defaultValue?: any
} & CommonType;

export type RadioGroupState = {
  /**
   * The value of the currently selected radio button
   */
  value: string
};

class RadioGroup extends React.Component<RadioGroupProps, RadioGroupState> {
  constructor(props: Object) {
    super(props);
    this.isControlled = props.value != null;

    if (!this.isControlled) {
      this.state = {
        value: props.defaultValue
      };
    }
  }

  handleChange(e: SyntheticInputEvent<*>) {
    if (!this.isControlled) {
      this.setState({
        value: e.target.value
      });
    }
    if (this.props.onChange) {
      this.props.onChange(e, e.target.value);
    }
  }

  render() {
    const { name, className, style, value: valueProp, children } = this.props;

    const value = this.isControlled ? valueProp : this.state.value;

    return (
      <div className={`radio-group ${className}`} style={style}>
        {React.Children.map(children, child => {
          if (!React.isValidElement(child)) {
            return null;
          }

          return React.cloneElement(child, {
            name,
            checked: value === child.props.value,
            onChange: this.handleChange.bind(this)
          })
        })}
      </div>
    );
  }
}

export default RadioGroup;
