import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { NoClickOutsideCheckbox } from '../index';
import { SlideDown } from 'react-slidedown';
import TreeNodeWithChildren from './TreeNodeWithChildren';
import { matches } from '../utils/domHelper'

class TreeNode extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: ''
    }
  }

  onCheck(e) {
    if (this.props.onCheck) {
      this.props.onCheck({
        value: this.props.value,
        children: this.props.rawChildren
      });
    }
  }


  isInteractive(){
    return this.props.onExpand && !this.props.disabled &&  !this.props.expandDisabled && this.hasChildren();
  }

  onSelect(e) {
    if (this.isInteractive() && !matches(e.target, ".a-checkbox-input")) {
      this.props.onExpand({
        value: this.props.value
      });
    }

    this.props.onSelect({value: this.props.value});


  }

  renderCollapseButton() {
    const { expandDisabled, disabled } = this.props;

    if (!this.hasChildren()) {
      return null;
    }

    const cn = ClassNames("a-tree-node-collapse-btn", {
      "disabled": expandDisabled || disabled
    })

    return (
      <div
        className={cn}
      >
        {this.renderCollapseIcon()}
      </div>
    );
  }

  renderCollapseIcon() {
    const { collapseIcons } = this.props;
    if (!this.props.expanded) {
      if (collapseIcons && collapseIcons.length > 0) {
        return collapseIcons[0];
      } else {
        return <span className="a-icon a-icon-closed appkiticon icon-plus-outline" />
      }
    } else {
      if (collapseIcons && collapseIcons.length > 1) {
        return collapseIcons[1];
      } else {
        return <span className="a-icon a-icon-opened appkiticon icon-minus-outline" />
      }
    }
  }

  renderNodeIcon() {
    let result;
    if(this.props.showNodeIcon){
      if (this.props.icon !== null) {
        result = (<span className="a-tree-node-icon">{this.props.icon}</span>);
      } else if (!this.hasChildren()) {
        result = (<span className="a-tree-node-icon">
                    <span className="a-icon appkiticon icon-file-outline" />
                  </span>);
      }
    }
    return result;
  }

  renderLabel(){
    const {
      label
    } = this.props;

    return (<div className="a-tree-node-title">{label}</div>)
  }

  hasChildren() {
    return this.props.rawChildren !== null;
  }

  renderChildren() {
    if (!this.props.expanded) {
      return <SlideDown transitionOnAppear={false}></SlideDown>;
    } else {
      return <SlideDown transitionOnAppear={false}>{this.props.children}</SlideDown>;
    }
  }

  render() {
    const {
      className,
      style,
      checked,
      disabled,
      keyboardHighlight,
      showCheckbox = false,
      expandDisabled
    } = this.props;

    const hasChildren = this.hasChildren();

    const totalNodeCn = ClassNames("a-tree-node", className, {
      "is-folder": hasChildren,
      "is-file": !hasChildren,
      disabled: disabled,
      'selected': this.props.selectedValue === this.props.value
    });
    const checkbox = showCheckbox && (<NoClickOutsideCheckbox className="a-tree-node-checkbox"
                                                tabIndex={-1}
                                                onChange={this.onCheck.bind(this)}
                                                disabled={disabled}
                                                checked={checked} />);
    const icon = this.renderCollapseButton() || this.renderNodeIcon();


    const nodeCn = ClassNames("a-tree-single-node", className, {
      "is-folder": hasChildren,
      "is-file": !hasChildren,
      "keyboardHighlight" : keyboardHighlight,
      disabled: disabled,
      expandDisabled: expandDisabled,
      'selected': this.props.selectedValue === this.props.value
    });

    return (
      <div className={totalNodeCn} style={style}>
        <div className={nodeCn} onClick={this.onSelect.bind(this)}>
            {checkbox}
            {icon}
            {this.renderLabel()}
        </div>
        {this.renderChildren()}
      </div>
    );
  }
}

TreeNode.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  checked: PropTypes.bool,
  children: PropTypes.any,
  rawChildren: PropTypes.arrayOf(TreeNodeWithChildren),

  label: PropTypes.string,
  disabled: PropTypes.bool,
  expanded: PropTypes.bool,
  expandDisabled: PropTypes.bool,
  showNodeIcon: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  icon: PropTypes.any,
  collapseIcons: PropTypes.any,

  //event
  onCheck: PropTypes.func,
  onExpand: PropTypes.func,

  value: PropTypes.string
};

TreeNode.displayName = 'TreeNode';

export default TreeNode;
