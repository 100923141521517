//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import type { Node } from 'react';
import ClassNames from 'classnames';
import ReactDOM from 'react-dom';
import type { CommonType } from 'appkit-react-utils/commonType';
import { getAnimationEvent } from 'appkit-react-utils/commonFunc';
type AnimationsProps = {
  /**
   * The content of Animations
   */
  children?: Node,
  effect?: string,
  active?: boolean,
  afterAnimation?: Function
};

type AnimationsState = {};

class Animations extends Component<
  AnimationsProps & CommonType,
  AnimationsState
> {
  componentDidMount() {
    let element = ReactDOM.findDOMNode(this);
    if (element) {
      element.addEventListener(getAnimationEvent(), () => {
        this.props.afterAnimation && this.props.afterAnimation();
      });
    }
  }
  componentWillUnmount() {
    let element = ReactDOM.findDOMNode(this);
    if (element) {
      element.removeEventListener(getAnimationEvent(), () => {});
    }
  }
  renderContent: any = () => {
    const { style, className, active, children, effect = '' } = this.props;
    let content;
    content = React.Children.map(children, child =>
      React.cloneElement(child, {
        className: ClassNames(
          (child.props && child.props.className),
          className,
          {
            [`animated ${effect}`]: !!effect && active
          }
        ),
        style: style
      })
    );
    return content;
  };

  render() {
    return this.renderContent() || null;
  }
}

Animations.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  effect: PropTypes.string,
  active: PropTypes.bool,
  afterAnimation: PropTypes.func
};

Animations.displayName = 'Animations';

export default Animations;
