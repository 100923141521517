//@flow
import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';

type Props = {
  children: React.Element<any>
};

const Panel = function({
  className = '',
  style,
  children,
  title = '',
  renderRight = () => null,
  ...others
}: Props & CommonType): React.Element<any> {
  const classNames = classnames('a-panel', className);

  return (
    <div className={classNames} style={style} {...others}>
      {
        title &&
        <div className="a-panel-header">
          <span className="a-panel-title">{title}</span>
          <div className="a-panel-header-right">
            {renderRight()}
          </div>
        </div>
      }
      <div className="a-panel-content">
        {children}
      </div>
    </div>
  );
};

Panel.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};

export default Panel;
