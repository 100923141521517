//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import type { Node } from 'react';
import type { CommonType } from 'appkit-react-utils/commonType';
import Pager from './Pager';
import WithPaginator from './WithPaginator';
import PaginationOptions from './PaginationOptions';
import type {
  exposedPaginatorProps,
  CommonPaginatorProps
} from './WithPaginator';

import NaviButton from './NaviButton';

let outlineAction = {
  NOACTION: 0,
  CLICK: 1,
  CHANGE: 2,
  BLUR: 3,
  TAB: 4,
};

type PaginationProps = {
  /**
   * default : Paginator with field and buttons
   * simple : Paginator without boxshadow
   * numeric : Pagination with numbers
   * carousel : Carousel Paginator
   * @type {('default' | 'numeric' | 'carousel')}
   */
  kind?: 'default' | 'numeric' | 'carousel',
  /**
   * Customizes the options of the rows per page select field.
   * If less than two options are available, no select field will be displayed.
   *
   * @type {Array}
   */
  defaultPageSize?: Number,
  defaultCurrent?: Number,
  pageSizeOptions?: Array<Number>,
  /**
   * to customized prev button
   *
   * @type {Function}
   */
  renderPrevButton?: Function,
  /**
   * to customized next button
   *
   * @type {Function}
   */
  renderNextButton?: Function,
  /**
   *
   * If `true`, will show pagination prev & next button
   * @type {Boolean}
   */
  showPrevAndNextBtn?: Boolean,
  /**
   * If `true`, pageSize can be changed,
   * It will only be avaiable when set kind as 'default' or 'simple'
   *
   * @type {Boolean}
   */
  showSizeOptions?: Boolean,
  showTextWithOnlyOneOption?: boolean
} & CommonPaginatorProps &
  CommonType;

type PaginationState = {};

const defaultPageSizeOptions = [5, 10, 15, 20];
class Pagination extends Component<
  PaginationProps & exposedPaginatorProps,
  PaginationState
> {
  constructor(props: Object) {
    super(props);
  }

  renderFieldsPager = (): Node => {
    const { calculatePages, setCurrentPage, value: current } = this.props;
    const pageNums: number = calculatePages();
    return (
      <Pager
        key="pagination-fields"
        kind="fields"
        current={current}
        pageNums={pageNums}
        onPageChange={setCurrentPage}
      />
    );
  };

  renderNumericPager = (): any => {
    const { calculatePages, setCurrentPage, value: current } = this.props;
    const pageNums: number = calculatePages();
    const maxCountOfPagers: number = 5;
    let pagers: Array<*> = [],
      startPager: number;
    //push first page
    pagers.push(
      <Pager
        key="pager1"
        kind="numeric"
        text={1}
        current={current}
        pageNums={pageNums}
        onPageChange={setCurrentPage}
      />
    );
    const roundMaxCountOfPagers: number = Math.round(maxCountOfPagers / 2);
    if (current > roundMaxCountOfPagers + 1) {
      pagers.push(
        <Pager
          key="pager-more-start"
          text="..."
          current={current}
          kind="numeric"
        />
      );
      if (current <= pageNums - roundMaxCountOfPagers) {
        startPager = current - roundMaxCountOfPagers + 1;
      } else {
        startPager = pageNums - maxCountOfPagers + 1;
      }
    } else {
      startPager = 2;
    }
    let displayCount: number = maxCountOfPagers;
    if (current < roundMaxCountOfPagers + 1) {
      displayCount = maxCountOfPagers - 1;
    }
    displayCount = Math.min(displayCount, pageNums - 1);

    for (let i: number = 0; i < displayCount; i++) {
      if (startPager + i === current) {
        pagers.push(
          <Pager
            kind="numeric"
            key={`pager-${startPager + i}`}
            current={current}
            text={startPager + i}
            pageNums={pageNums}
            onPageChange={setCurrentPage}
          />
        );
      } else {
        pagers.push(
          <Pager
            kind="numeric"
            key={`pager-${startPager + i}`}
            text={startPager + i}
            current={current}
            pageNums={pageNums}
            onPageChange={setCurrentPage}
          />
        );
      }
    }
    if (pageNums > maxCountOfPagers + startPager) {
      pagers.push(
        <Pager
          key="pager-more-end"
          kind="numeric"
          current={current}
          text="..."
        />
      );
    }
    if (maxCountOfPagers + startPager <= pageNums) {
      pagers.push(
        <Pager
          key={`pager-${pageNums}`}
          kind="numeric"
          text={pageNums}
          current={current}
          pageNums={pageNums}
          onPageChange={setCurrentPage}
        />
      );
    }
    return pagers;
  };

  renderCarouselPaginator = (): any => {
    const { calculatePages, value: current, setCurrentPage } = this.props;
    const pageNums: number = calculatePages();
    let pagers: Array<*> = [];
    for (let i = 0; i < pageNums; i++) {
      pagers.push(
        <Pager
          key={i}
          kind="carousel"
          current={current}
          text={i + 1}
          pageNums={pageNums}
          onPageChange={setCurrentPage}
        />
      );
    }
    return pagers;
  };

  renderPrevItem = (): any => {
    const { hasPrev, prev } = this.props;
    const { renderPrevButton, kind = 'default' } = this.props;
    const prevLinkClasses: string = ClassNames('a-page a-page-link', {
      ['disabled']: !hasPrev(),
      'circle': kind === 'numeric',
    });
    let renderedPrevBtn: any;
    if (renderPrevButton) {
      const prevBtn: any = renderPrevButton();
      renderedPrevBtn = React.cloneElement(prevBtn, {
        onClick: prev,
        className: ClassNames(
          prevBtn.props.className,
          'pagination-button pagination-prev'
        )
      });
    } else {
      renderedPrevBtn = (
        <NaviButton className="-previous">
          <span className="appkiticon icon-left-chevron-fill"></span>
        </NaviButton>
      );
    }
    return (
      <li key="pagination-prev" className={prevLinkClasses} onClick={prev}>
        {renderedPrevBtn}
      </li>
    );
  };

  renderNextItem = () => {
    const { hasNext, next } = this.props;
    const { renderNextButton, kind = 'default' } = this.props;
    const nextLinkClasses: string = ClassNames('a-page a-page-link', {
      ['disabled']: !hasNext(),
      'circle': kind === 'numeric'
    });
    let renderedNextBtn: any;
    if (renderNextButton) {
      const nextBtn: any = renderNextButton();
      renderedNextBtn = React.cloneElement(nextBtn, {
        onClick: next,
        className: ClassNames(
          nextBtn.props.className,
          'pagination-button pagination-next'
        )
      });
    } else {
      renderedNextBtn = (
        <NaviButton className="-next">
          <span className="appkiticon icon-right-chevron-fill"></span>
        </NaviButton>
        // <div
        //   className={ClassNames('-next', {
        //     'without-outline': !this.state.hasOutline
        //   })}
        //   onMouseDown={() => {
        //     this.setState({
        //       hasOutline: false,
        //       lastAction: outlineAction.CLICK
        //     });
        //   }}
        //   onKeyDown={e => {
        //     console.log('onKeyDown====');
        //     if (e.which === 9 || e.keyCode === 9) {
        //       this.setState({
        //         hasOutline: true,
        //         lastAction: outlineAction.TAB
        //       })
        //     }
        //   }}
        // >
        //   <span className="appkiticon icon-right-chevron-fill"></span>
        // </div>
      );
    }

    return (
      <li key="pagination-next" className={nextLinkClasses} onClick={next}>
        {renderedNextBtn}
      </li>
    );
  };

  renderPager = () => {
    const { kind = 'default', showPrevAndNextBtn = true } = this.props;
    let pagers: Array<*> = [],
      leftPager: any,
      rightPager: any;
    if (showPrevAndNextBtn && kind !== 'carousel') {
      leftPager = this.renderPrevItem();
      rightPager = this.renderNextItem();
    }
    if (kind === 'default' || kind === 'simple') {
      pagers.push(this.renderFieldsPager());
    } else if (kind === 'numeric') {
      pagers = [...pagers, ...this.renderNumericPager()];
    } else if (kind === 'carousel') {
      pagers = [...pagers, ...this.renderCarouselPaginator()];
    }

    leftPager && pagers.unshift(leftPager);
    rightPager && pagers.push(rightPager);

    return pagers;
  };

  render() {
    let {
      style,
      className,
      kind = 'default',
      total,
      hasPrev,
      hasNext,
      prev,
      next,
      setCurrentPage,
      calculatePages,
      showPrevAndNextBtn,
      onChange,
      showSizeOptions = false,
      renderPrevButton,
      renderNextButton,
      pageSizeOptions = defaultPageSizeOptions,
      onPageSizeChange,
      changePageSize,
      value: current,
      pageSize,
      showTextWithOnlyOneOption = false,
      defaultCurrent,
      defaultPageSize,
      ...otherProps
    } = this.props;

    const paginationClasses: string = ClassNames(className, {
      'a-pagination': true,
      [`a-pagination-${kind}`]: kind !== 'default',
    });
    if (!showSizeOptions) {
      return (
        <nav
          style={style}
          className={className}
          {...otherProps}
          aria-label="Page navigation"
        >
          <ul className={paginationClasses}>{this.renderPager()}</ul>
        </nav>
      );
    } else {
      let node: any = (
        <nav aria-label="Page navigation">
          <ul className={paginationClasses}>{this.renderPager()}</ul>
        </nav>
      );
      return (
        <div
          style={style}
          className={ClassNames('clearfix a-pagination-wrapper', className)}
        >
          <PaginationOptions
            current={current}
            total={total}
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
            onPageSizeChange={changePageSize}
            showTextWithOnlyOneOption={showTextWithOnlyOneOption}
          />
          {node}
        </div>
      );
    }
  }
}

Pagination.propTypes = {
  kind: PropTypes.string,
  current: PropTypes.number,
  defaultPageSize: PropTypes.number,
  defaultCurrent: PropTypes.number,
  total: PropTypes.number,
  showPrevAndNextBtn: PropTypes.bool,
  onChange: PropTypes.func,
  renderPrevButton: PropTypes.func,
  renderNextButton: PropTypes.func,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  onPageSizeChange: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  prev: PropTypes.func,
  next: PropTypes.func,
  hasPrev: PropTypes.func,
  hasNext: PropTypes.func,
  setCurrentPage: PropTypes.func,
  calculatePages: PropTypes.func,
  changePageSize: PropTypes.func,
  value: PropTypes.number
};

Pagination.displayName = 'Pagination';

export default WithPaginator(Pagination);
