//@flow
import * as React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';

type Props = {
  children?: React.Element<any>
};

const ModalBody = function({
  style,
  className = '',
  children,
  ...others
}: Props & CommonType): React.Element<any> {
  const classNames = ClassNames('modal-body', className, {
    "a-increase-child-color-level-by-1": !(className && className.includes("a-increase-child-color-level-by"))
  });
  return (
    <div className={classNames} style={style} {...others}>
      {children}
    </div>
  );
};

ModalBody.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string
};

export default ModalBody;
