//@flow
import * as React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import KODIV from '../utils/KeyboardHoverOnlyDiv';

class AccordionItemHeader extends React.Component{
  onKeyDown(e){
    if(e.key === "Enter"){
      const {
        itemId,
        onClick,
      } = this.props;

      onClick(itemId)
    }
  }

  render(){
    const {
      title = '',
      style,
      className = '',
      children,
      itemId,
      onClick,
      iconPosition="right",
      isActive,
      ...others
    } = this.props;

      const classNames = ClassNames('a-accordion-item-header a-accordion-title', className);
      const classForIcon = ClassNames("a-accordion-icon", (isActive? "icon-up-chevron-fill": "icon-down-chevron-fill"),
                                                    (iconPosition === "left"? "icon-left": "icon-right"));
      return (
        <KODIV className={classNames} style={style} {...others} onClick={() => onClick(itemId)} onKeyDown={this.onKeyDown.bind(this)}>
            <div className="a-ccordion-item-header-text"> <div className="ellipip-able">{title} </div>  {children}    </div>
            <span className={classForIcon} />
        </KODIV>
      );
    };
}


AccordionItemHeader.propTypes = {
  title: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  iconPosition: PropTypes.string,
  itemId: PropTypes.any,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};

AccordionItemHeader.displayName = 'AccordionItemHeader';

export default AccordionItemHeader;
