//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassName from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';
import { add, sub } from './util';
import KODIV from '../utils/KeyboardHoverOnlyDiv';

function isExist(e){
  return e !== undefined && e !== null;
}

function isNumber(num){
  var number = Number(num); //Number function convert "" to zero
  return isExist(num) && num !== "" && !isNaN(number) && (!!number || number === 0);
}

function isEmptyStr(num){
  return num==="";
}

type InputNumberProps = {
  className?: string,
  style?: Object,
  inputAttr?: Object,
  kind?: 'default' | 'arrow' | 'separated',
  inputBoxSize?: 'sm' | 'lg',
  value?: number | string,
  step?: number | string,
  max?: number | string,
  min?: number | string,
  disabled?: boolean,
  integerOnly?: boolean,
  onChange: Function,
  onFocus?: Function,
  onBlur?: Function,
  readonly?: boolean
};

type InputNumberState = {
  value: string;  //store as string. need to convert to number when doing calculation
  focused: boolean; // whether input is focused
};

class InputNumber extends Component<
  InputNumberProps & CommonType,
  InputNumberState
> {
  state: InputNumberState;

  static defaultProps = {
    step: 1,
    max: Infinity,
    min: 0,
    value: 0,
    integerOnly: true
  };

  constructor(props: Object) {
    super(props);
    let value = this.checkValueMinMax(props.value);
    this.state = {
      value: isNumber(value)? value.toString() : this.defaultValue,
      focused: false,
    };
  }

  getInstructionIcon(key){
    const INSTRUCTION_ICONS = {
      'default-increase-icon': 'appkiticon icon-plus-fill',
      'default-decrease-icon': 'appkiticon icon-minus-fill',
      'separate-increase-icon': 'appkiticon icon-plus-fill',
      'separate-decrease-icon': 'appkiticon icon-minus-fill',
      'arrow-increase-icon': 'appkiticon icon-up-chevron-fill',
      'arrow-decrease-icon': 'appkiticon icon-down-chevron-fill',
    };

    const isDecrease = key.includes("decrease");
    const isIncrease = key.includes("increase");

    let extraCn;
    if(isDecrease) {
      extraCn = this.props.decreaseIconClassName || "";
    }else if(isIncrease) {
      extraCn = this.props.increaseIconClassName || "";

    } 
    const cn = ClassName(INSTRUCTION_ICONS[key], extraCn);
    return <span className={cn} />;
  }

  checkValueMinMax(value: number): number{
    let result = value;
    if (isNumber(this.props.min)) {
      result = Math.max(Number(this.props.min), result);
    }
    if (isNumber(this.props.max)) {
      result = Math.min(Number(this.props.max), result);
    }
    return result;
  }

  UNSAFE_componentWillReceiveProps(props: Object) {
    if (props.value !== this.props.value && isNumber(props.value)) {
        this.setState({ value: props.value.toString() });
    }
  }

  onBlur = () => {
    let value = this.state.value;
    if (this.isValid) {
      value = this.checkValueMinMax(Number(value));
    }
    this.setState({ value: value.toString() }, this.onChange);
  };

  onKeyDown = (e: SyntheticKeyboardEvent<*>) => {
    switch (e.keyCode) {
      case 38: // KeyUp
        e.preventDefault && e.preventDefault();
        this.increaseNumber();
        break;
      case 40: // KeyDown
        e.preventDefault && e.preventDefault();
        this.decreaseNumber();
        break;
      default:
        break;
    }
  };

  get defaultValue(): string{
    return this.checkValueMinMax(0).toString();
  }

  get isValid(): boolean {
    return isNumber(this.state.value)||isEmptyStr(this.state.value);
  }

  get minDisabled(): boolean {
    return (
      !this.isValid ||
      Number(this.state.value) - Number(this.props.step) < Number(this.props.min)
    );
  }

  get maxDisabled(): boolean {
    return (
      this.state.value !== "" &&
      (!this.isValid ||
      Number(this.state.value) + Number(this.props.step) > Number(this.props.max))
    );
  }

  handleChange = (e: SyntheticInputEvent<*>) => {
    const input = e.target.value;
    const hasWord = /[^\d\-.]/.exec(input);
    const invalid = hasWord || (this.props.integerOnly &&  input.toString().indexOf(".") > -1);
    if (!this.props.disabled && !invalid) {
      let value = this.defaultValue;
      if (isNumber(input)) {
        value = input.toString();
      } else if(input === "" || input === "-" || (input === "." && !this.props.integerOnly)){
        value = input;
      }

      this.setState({
        value
      }, () => {
        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          this.onBlur();
        }, 750);
      });
    }
  };

  handleFocus = (e: SyntheticFocusEvent<*>) => {
    this.setState({
      focused: true,
    });
    const { onFocus } = this.props;
    onFocus && onFocus(e);
  }

  handleBlur = (e: SyntheticFocusEvent<*>) => {
    this.setState({
      focused: false,
    });
    const { onBlur } = this.props;
    onBlur && onBlur(e);
  }

  onChange = () => {
    if (this.props.onChange) {
      this.props.onChange(Number(this.state.value));
    }
  };

  // precision is now set inside the InputNumber Component.
  // if user wants a precision prop to control in the future, reuse this part.
  getPrecision = (value: string) => {
    if (!value) return 0;
    if (value.indexOf('e-') >= 0) {
      return parseInt(value.slice(value.indexOf('e-') + 2), 10);
    }
    if (value.indexOf('.') >= 0) {
      return value.split('.')[1].length;
    }
    return 0;
  }

  getMaxPrecision = () => {
    const stepPrecision = this.getPrecision('' + this.props.step);
    const currValPrecision = this.getPrecision(this.state.value);
    return Math.max(stepPrecision, currValPrecision);
  }

  increaseNumber = (e: SyntheticKeyboardEvent<*>) => {
    const { step, max, disabled, min } = this.props;
    const precision = this.getMaxPrecision();
    let value = Number(this.state.value);
    if (!disabled && !this.maxDisabled) {
      if (this.state.value === ""){
        value = this.props.min;
      } else {
        if (value + Number(step) > Number(max)) {
          return;
        }
        if (value + Number(step) < Number(min)){
          value = Number(min) - Number(step);
        }
        value = add(value, Number(step));
      }
      this.setState({ value: value.toFixed(precision) }, this.onChange);
    }
    e && typeof(e.preventDefault) === "function" && e.preventDefault();
  };

  decreaseNumber = (e: SyntheticKeyboardEvent<*>) => {
    const { step, min, disabled, max } = this.props;
    const precision = this.getMaxPrecision();
    let value = Number(this.state.value);

    if (!disabled && !this.minDisabled) {
      if (value - Number(step) < Number(min)) {
        return;
      }
      if (value - Number(step) > Number(max)){
        value = Number(max) + Number(step);
      }
      value = sub(value, Number(step));
      this.setState({ value: value.toFixed(precision) }, this.onChange);
    }
    e && typeof(e.preventDefault) === "function" && e.preventDefault();
  };

  render() {
    const {
      kind = 'default',
      inputBoxSize = 'sm',
      step,
      min,
      disabled,
      max,
      readonly,
      onChange,
      integerOnly,
      style,
      className,
      inputAttr = {},
      onFocus,
      onBlur,
      ...others
    } = this.props;
    const { value } = this.state;

    //a-input-size-lg a-input-size-sm
    //a-input-number-arrow, a-input-number-default, a-input-number-seperate
    const inputNumberWrapperClasses = ClassName(`a-input-number-wrapper`,
                                                `a-input-size-${inputBoxSize}`, 
                                                `a-input-number-${kind}`, className, 
                                                {'focused': this.state.focused});
    const inputNumberClasses = ClassName(`a-input-text a-input-number`);
    const disableIncreaseIcon = disabled || +this.state.value + step > max;
    const disableDecreaseIcon = disabled || +this.state.value - step < min;

    return (
      <div className={inputNumberWrapperClasses} style={style} {...others}>
        <input
          className={inputNumberClasses}
          value={value}
          min={min}
          max={max}
          onKeyDown={this.onKeyDown}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          type="text"
          readOnly={readonly}
          disabled={disabled}
          {...inputAttr}
        />
        {[
          <KODIV
            isButton
            className='a-increase-icon a-instruction-icon'
            onClick={this.increaseNumber}
            key='key-for-inputnumber-increase-icon'
            disabled={disableIncreaseIcon}
          >
            {this.getInstructionIcon(`${kind}-increase-icon`)}
          </KODIV>,
          <KODIV
            isButton
            className='a-decrease-icon a-instruction-icon'
            onClick={this.decreaseNumber}
            key='key-for-inputnumber-decrease-icon'
            disabled={disableDecreaseIcon}
          >
            {this.getInstructionIcon(`${kind}-decrease-icon`)}
          </KODIV>
        ]}
      </div>
    );
  }
}

InputNumber.propTypes = {
  inputBoxSize:  PropTypes.oneOf(["sm", "lg"]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  kind: PropTypes.oneOf(["default", "arrow", "separate"]),
  integerOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  inputAttr: PropTypes.object,
  onChange: PropTypes.func,
  increaseIconClassName: PropTypes.string,
  decreaseIconClassName: PropTypes.string,
};

export default InputNumber;
