//@flow
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import type { CommonType } from 'appkit-react-utils/commonType';
import ClassNames from 'classnames';


type SelectGroupTitleProps = {
  children?: PropTypes.any,
  showSelectAll?: boolean,
};

type SelectGroupTitleState = {};

class SelectGroupTitle extends PureComponent<
  SelectGroupTitleProps & CommonType,
  SelectGroupTitleState
> {
  state: SelectGroupTitleState;

  static defaultProps = {
    showSelectAll: false
  };

  constructor(props: Object) {
    super(props);
  }

  render() {
    const {
       children, style, className, tab, spacing
    } = this.props;

    const classes = ClassNames('a-select-group-title', className, {
      "top-space-4": spacing && spacing.top === 4,
      "bottom-space-4": spacing && spacing.bottom === 4,
      "bottom-space-8": spacing && spacing.bottom === 8,
    });

    return (
      <div className={classes} style={style} tab={tab}>{children}</div>
    );
  }
}

SelectGroupTitle.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.any,
  showSelectAll: PropTypes.bool,
  tab: PropTypes.string
};

SelectGroupTitle.displayName = "SelectGroupTitle";

export default SelectGroupTitle;
