//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import type { Node } from 'react';
import type { CommonType } from 'appkit-react-utils/commonType';
import ReactDOM from 'react-dom';

type ProgressProps = {
  /**
   * The value of progress, value between 0 and 100.
   */
  percent?: number,
  isVertical?: boolean,
  indicators: string[]
};

type ProgressState = {};

const INDICATOR_HEIGHT = 12;
const HALF_INDICATOR_HEIGHT = INDICATOR_HEIGHT /2;
const CHILD_INDICATOR_HEIGHT = 44;
const BOTTOM_MARGIN = 30;
const UP_ALIGN = 12;

class Progress extends Component<ProgressProps & CommonType, ProgressState> {
  constructor(props: Object) {
    super(props);
    this.textDivs = [];
    this.activeTable = {};

    (this.props.verticalIndicators || []).forEach(e =>{
      this.activeTable[e.props.text] = e.props.defaultActive;
    })
  }

  static defaultProps = {
    percent: 0
  }

  adjustProgress(){
    //when there is only one text  ??
    let {
      isVertical,
    } = this.props;

    if (isVertical && this.verticalIndicatorDivs && this.verticalIndicatorDivs.length > 0) {
        //!!in order to do the two animation simultaneously, I have to predict height before rendering
        // hard code height
        let height = 0;
        let len = this.verticalIndicatorDivs.length;
        this.verticalIndicatorDivs.forEach((e, index) => {
          height += INDICATOR_HEIGHT;
          if(index !== len -1){
            height += BOTTOM_MARGIN;
          }
          if(e.props.isActive){
            height += e.props.subIndicators.length * CHILD_INDICATOR_HEIGHT;
          }
        })
        const progress =  ReactDOM.findDOMNode(this.progress);
        progress.style.setProperty("height", Math.max(height - HALF_INDICATOR_HEIGHT, 0)+"px");
        progress.style.setProperty("margin-top", HALF_INDICATOR_HEIGHT+"px");

        //find percent from verticalIndicatorDivs
        let pHeight = 0;
        let sameheight = false;
        for(let index = 0; index < len; index++){
          let e = this.verticalIndicatorDivs[index];
          let allDone = e.props.doneIndicators.length === e.props.subIndicators.length;
          let next = this.verticalIndicatorDivs[index+1];
          const hasNext = next && next.props.doneIndicators.length > 0;
          const percent100 = allDone && index === len -1;

          if(e.props.isActive){
            //expanded
              pHeight += INDICATOR_HEIGHT;
              pHeight +=  e.props.doneIndicators.length * CHILD_INDICATOR_HEIGHT;
              if( allDone && hasNext){
                pHeight += BOTTOM_MARGIN;
              } else if(percent100){
                pHeight -= UP_ALIGN;  //align to last indicator
                sameheight = true;
              }else{
                pHeight -= UP_ALIGN;  //align to sub indicator
              }
          } else {
            if ( allDone && hasNext) {
              pHeight += INDICATOR_HEIGHT + BOTTOM_MARGIN;
            } else {
              pHeight += HALF_INDICATOR_HEIGHT;
            }

            if(index === len -1){
              sameheight = true;
            }
          }

          if(!allDone || !hasNext){
            //progress ends here
            break;
          }
        }
        const progressBar =  ReactDOM.findDOMNode(this.progressBar);
        progressBar.style.setProperty("height", Math.max(pHeight - HALF_INDICATOR_HEIGHT, 0)+"px");
        if(sameheight){
          progress.style.setProperty("height", Math.max(pHeight - HALF_INDICATOR_HEIGHT, 0)+"px");
        }

    } else if (this.textDivs.length > 1 && isVertical) {
        const textDiv =  ReactDOM.findDOMNode(this.textDiv);
        const progress =  ReactDOM.findDOMNode(this.progress);
        progress.style.setProperty("height", textDiv.clientHeight+"px");

        const progressBar =  ReactDOM.findDOMNode(this.progressBar);
        const percent = this.getPercent() / 100;
        progressBar.style.setProperty("height", textDiv.clientHeight * percent +"px");
    }
  }

  onActiveToggle(indicator, value){
    this.activeTable[indicator.props.text] = value;
    this.forceUpdate();
  }

  componentDidMount(){
   this.adjustProgress();
  }

  componentDidUpdate(){
    this.adjustProgress();
  }

  getPercent(){
    let percent = this.props.percent;
    percent = percent > 100 ? 100 : percent;
    percent = percent < 0? 0 : percent;
    return percent;
  }

  render() {
    let {
      style,
      className,
      isVertical,
      percent = 0,
      indicators,
      verticalIndicators,
      color,
      theme,
      progressBarStyle,
      progressBarClassname,
      ...otherProps
    } = this.props;
    percent = this.getPercent();

    //backward compatibility
    const _color = color || theme;

    const classNames = ClassNames('a-progress', className, {
      "a-vertical-progress":  isVertical,
      "a-progress-dark":  _color === "dark",
      "a-progress-white":  _color === "white",
    });

    const wrapperCn = ClassNames("a-progress-wrapper", {
      "a-vertical-progress-wrapper": isVertical,
      "has-animation": verticalIndicators && verticalIndicators.length > 0
    })

    this.textDivs = [];
    const textCn = ClassNames("a-progress-text", _color,  {
        "a-vertical-progress-text": isVertical,
        "a-horizontal-progress-text": !isVertical
    });
    let texts;
    const that = this;
    if(verticalIndicators && verticalIndicators.length > 0){
      let verticalIndicatorDivs = verticalIndicators.map((e) => {
        return React.cloneElement(e, {
          onToggle: that.onActiveToggle.bind(this),
          isActive: !!this.activeTable[e.props.text]
        })
      })
      this.verticalIndicatorDivs = verticalIndicatorDivs;
      texts = (<div ref={e => {this.textDiv = e}} className={textCn}>{verticalIndicatorDivs}</div>);
    }else if(indicators && indicators.length > 0){
      const len = indicators.length;
      const indicatorDivs = indicators.map((e, index) => {
        const isDone = (index / (len-1) * 100) <= percent;
        const indicatorCn = ClassNames('a-indicator', {'a-progress-done': isDone});
        return <div ref={e => {this.textDivs.push(e)}} key={e} className={indicatorCn}>{e}</div>;
      });
      texts = (<div ref={e => {this.textDiv = e}} className={textCn}>{indicatorDivs}</div>);
    }

    let _progressBarStyle;
    if(!isVertical && !progressBarStyle){
      _progressBarStyle = { width: `${percent}%`, height: '100%'};
    }

    return (
      <div className={wrapperCn} style={style} {...otherProps}>
        <div className={classNames} ref={e => {this.progress = e}}>
          <div
            className={ClassNames("a-progress-bar", progressBarClassname)}
            style={progressBarStyle || _progressBarStyle}
            ref={e => {this.progressBar = e}}
          />
        </div>
        <div>
          {texts}
        </div>
      </div>
    );
  }
}

Progress.propTypes = {
  style: PropTypes.object,
  progressBarStyle: PropTypes.object,
  progressBarClassname: PropTypes.string,
  className: PropTypes.string,
  percent: PropTypes.number,
  color: PropTypes.oneOf(["default", "dark", "white"]),
  indicators: PropTypes.arrayOf(PropTypes.string),
  isVertical: PropTypes.bool,

  verticalIndicators: PropTypes.arrayOf(PropTypes.any)
};

Progress.displayName = 'Progress';

export default Progress;
