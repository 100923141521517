//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import type { Node } from 'react';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';
import KODIV from '../utils/KeyboardHoverOnlyDiv';


const STATUS = ['default', 'dark', 'success', 'warning', 'negative'];

type BannerProps = {
  children?: Node,
  title?: Node,
  content?: Node,
  status?: 'default' | 'dark' | 'success' | 'warning' | 'negative' | 'danger' ,
  duration?: number,
  onClose?: Function,
  willUnmount?: Function,
  closeable?: Boolean
};

type BannerState = {
  visiable: boolean
};

class Banner extends Component<BannerProps & CommonType, BannerState> {
  timer: number;
  expandContentDom: HTMLElement;
  constructor(props: Object) {
    super(props);
    this.timer = 0;
    this.state = {
      visiable: true
    };
    this.expandVisible = false;
  }

  static defaultProps = {
    title: "",
    duration: 0,
    status: 'default'
  };

  //----------TIMER LOGIC STARTS  HERE
  startTimer() {
    if (this.props.closeable !== true && this.props.duration) {
      this.timer = setTimeout(() => {
        this.onClose();
      }, this.props.duration);
    }
  }

  stopTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  componentDidMount() {
    this.startTimer();
  }

  componentDidUpdate() {
    this.stopTimer();
    this.startTimer();
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  onClose = () => {
    const { onClose, willUnmount } = this.props;
    this.stopTimer();
    this.setState({
      visiable: false
    });
    this.forceUpdate(() => {
      willUnmount && willUnmount();
    });
    onClose && onClose();
  };
  //----------TIMER LOGIC ENDS  HERE

  toggleExpandeContent(){
    this.expandVisible = !this.expandVisible;
    this.forceUpdate();
  }

  renderExpandeButton(){
    const icon = this.expandVisible? (<span ref={el => this.toggleIconDom = el} className="appkiticon icon-up-chevron-fill"/>) :
                                           (<span ref={el => this.toggleIconDom = el} className="appkiticon icon-down-chevron-fill"/>);
    return (this.props.children && 
            <KODIV isSpan className="a-banner-toggle-button" onKeyDown={this.onEnterKeydownExpand.bind(this)}  onClick={this.toggleExpandeContent.bind(this)}>{icon}</KODIV>);
  }

  onEnterKeydownExpand(e){
    if(e.key === "Enter"){
      this.toggleExpandeContent();
    }
  }

  onEnterKeydown(e){
    if(e.key === "Enter"){
      this.onClose();
    }
  }

  renderCountDownTimerOrCloseButton() {
    if (this.props.closeable){
      return (
          <KODIV className="a-banner-close" onClick={this.onClose} onKeyDown={this.onEnterKeydown.bind(this)}>
            <span className="appkiticon icon-close-fill" ></span>
          </KODIV>
      );
    } else if(this.props.duration > 0){
      const animationStyle = {
        "animationDuration": (this.props.duration/1000) + "s"
      };

      return (
        <div className="a-countdown-wrapper">
          <svg height="24" width="24">
            <circle style={animationStyle} className="a-circle a-countdown animated" cx="12" cy="12" r="6"></circle>
          </svg>
        </div>);
    } else {
      return undefined;
    }
  }

  render() {
    let {
      title,
      content,
      status,
      style,
      className,
      children,
      willUnmount,
      onClose,
      duration,
      closeable,
      ...otherProps
    } = this.props;

    const showExpandContent = this.expandVisible && this.props.children;

    const bannerWrapperClsN = ClassNames('a-banner', className,  {
      [`a-banner-${status}`]: status,
      'a-banner-hide': !this.state.visiable,
      'a-banner-show': this.state.visiable
    });

    const expandClassName = ClassNames("a-banner-expand-content-wrap",{
      "a-banner-expand-content-hide": !showExpandContent,
      "a-banner-expand-content-show": showExpandContent
    });

    let expand = this.props.children && <div className={expandClassName}>{this.props.children}</div>;

    return (
      <div className="a-banner-wrap-div">
        <div className={bannerWrapperClsN} {...otherProps} >
          <div className="a-banner-text-content">
            <span className="a-banner-text-user-content">{content}</span>
            {this.renderExpandeButton()}
          </div>
          {this.renderCountDownTimerOrCloseButton()}
        </div>
        {expand}
      </div>
    );
  }
}

Banner.propTypes = {
  content: PropTypes.any,
  duration: PropTypes.number,
  status: PropTypes.oneOf(STATUS),
  style: PropTypes.object,
  className: PropTypes.string,
  closeable: PropTypes.bool,
};

Banner.displayName = 'Banner';
Banner.supportedStatus = STATUS;

export default Banner;
