//@flow
import * as WebFont from "webfontloader";
import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import type { Node } from "react";
import type { CommonType } from "appkit-react-utils/commonType";
import KeyCode from "appkit-react-utils/keyCode";
import KODIV from "../utils/KeyboardHoverOnlyDiv";

type TabBarProps = {
  /**
   * The content of Tabs
   */
  children?: Node,
  /**
   * Classes of TabBar wrapper
   */
  tabBarClass?: string,
  /**
   * The value of tabbar
   */
  value?: string,
  /**
   * If `true`, TabBar will be applied with secondary styling
   */
  secondary?: boolean,
  /**
   * If `true`, TabBar will be centered
   */
  centered?: boolean,
  /**
   * Callback executed when tab is clicked
   */
  onTabClick?: Function,
  /**
   * Callback executed when tab is clicked
   */
  onKeyDown?: Function
};

type TabBarState = {};
const TAB_BAR_ITEM_ACTIVE_CLASS = ".a-tab-bar-item.active";
const FIRST_ITEM_LEFT_MARGIN = 5;

class TabBar extends Component<TabBarProps & CommonType, TabBarState> {
  constructor(props) {
    super(props);
    this.state = {
      showPrevNext: false,
      next: true,
      prev: true,
      indicatorStyle: {
        width: 0,
        transform: "translate3d(0px, -2px, 0px)"
      }
    };
    const o = this;
    WebFont.load({
      custom: {
        families: ["PwC Helvetica Neue", "PwC Helvetica Neue:n2,n3,n4,n5,n7,n9"]
      },
      active: function() {
        o.calcIndicatorStyle();
        o.setPrevNext();
      }
    });
  }

  componentDidMount() {
    setTimeout(() => {
      this.setPrevNext();
      this.calcIndicatorStyle();
    }, 200);

    window.addEventListener("resize", () => {
      this.setPrevNext();
      this.calcIndicatorStyle();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    setTimeout(() => {
      const tabbarScroller = this.tabbarScroller;
      const tabbarScrollerBox = tabbarScroller.getBoundingClientRect();
      const currTabItem =
        tabbarScroller &&
        tabbarScroller.querySelector(TAB_BAR_ITEM_ACTIVE_CLASS);
      const currTabItemBox = currTabItem && currTabItem.getBoundingClientRect();
      const left =
        currTabItemBox &&
        tabbarScrollerBox &&
        Math.round(currTabItemBox.left - tabbarScrollerBox.left);
      if (!currTabItem) return;
      if (
        prevState.indicatorStyle.width !== currTabItem.scrollWidth + 1 ||
        prevState.left !== left
      ) {
        this.calcIndicatorStyle();
      }
    }, 0);
    //this timeout function is for supporting tabs in modal's condition.
    //Recaculate indicatorStyle when modal opens.
    if (
      (prevProps.value && prevProps.value !== this.props.value) ||
      (prevProps.children && prevProps.children.length) !==
        (this.props.children && this.props.children.length)
    ) {
      this.calcIndicatorStyle();
      this.scrollToActiveTab();
      this.setPrevNext();
    }
  }

  getMaxOffset = () => {
    const tabbarScroller = this.tabbarScroller;
    const tabbar = this.tabbar;
    if (!tabbarScroller || !tabbar) return -1;

    const tabbarBox = tabbar.getBoundingClientRect();
    return tabbarScroller.scrollWidth - tabbarBox.width;
  };
  setCurrTabInitPos = () => {
    const { showPrevNext } = this.state;
    const tabbarScroller = this.tabbarScroller;
    // show left/right scroll icons or not
    if (!showPrevNext) {
      tabbarScroller.style.left = "";
      return;
    }
    const maxOffset = this.getMaxOffset();
    let prev, next;
    if (maxOffset === -1) return;

    let leftOffset;
    const currTabItem = tabbarScroller.querySelector(TAB_BAR_ITEM_ACTIVE_CLASS);
    if (!currTabItem) return;
    const currTabItemBox = currTabItem.getBoundingClientRect();
    const tabbarScrollerBox = tabbarScroller.getBoundingClientRect();
    const actualOffset = currTabItemBox.left - tabbarScrollerBox.left;
    // there may exist precision issues on different browsers
    if (actualOffset <= 0.01 + FIRST_ITEM_LEFT_MARGIN) {
      leftOffset = 0;
      prev = false;
      next = true;
    } else if (Math.abs(actualOffset) >= maxOffset) {
      leftOffset = -maxOffset;
      prev = true;
      next = false;
    } else {
      leftOffset = -actualOffset;
      prev = true;
      next = true;
    }

    this.tabbarScroller.style.left = `${leftOffset}px`;
    this.setState({
      prev,
      next
    });
  };
  setPrevNext = () => {
    const tabbarWrapper = this.tabbarWrapper;
    const tabbarScroller = this.tabbarScroller;
    if (!tabbarWrapper || !tabbarScroller) return;
    const tabbarWrapperWidth = this.tabbarWrapper.getBoundingClientRect().width;
    const tabbarScrollerWidth = this.tabbarScroller.getBoundingClientRect()
      .width;
    // a. add/remove scroll icons
    this.setState(
      {
        showPrevNext: tabbarScrollerWidth > tabbarWrapperWidth
      },
      () => {
        this.setCurrTabInitPos();
      }
    );
    // b. scroll currTabItem to the right position
  };

  calcIndicatorStyle = () => {
    const tabbarScroller = this.tabbarScroller;
    if (!tabbarScroller) return;
    const tabbarScrollerBox = tabbarScroller.getBoundingClientRect();
    const currTabItem = tabbarScroller.querySelector(TAB_BAR_ITEM_ACTIVE_CLASS);
    if (!currTabItem) return;
    const currTabItemBox = currTabItem.getBoundingClientRect();
    const width = currTabItem.scrollWidth;
    const left = Math.round(currTabItemBox.left - tabbarScrollerBox.left);
    this.setState({
      indicatorStyle: {
        transform: `translate3d(${left}px, -2px, 0px)`,
        // scrollWidth is calculated like Math.floor, so widht 57.38 goes to scrollWidth 57
        // add 1 more pixel to cover focus visual effect.
        width: width + 1
      },
      left
    });
  };

  getTabBarOffset = () => {
    if (!this.tabbarScroller.style.left) return 0;
    return parseFloat(this.tabbarScroller.style.left.replace("px", ""), 10);
  };

  checkPrevNext = (offset: number, maxOffset: number) => {
    let next: boolean = true;
    let prev: boolean = true;
    if (offset >= 0) {
      prev = false;
    } else if (maxOffset && Math.abs(offset) + 1 >= maxOffset) {
      next = false;
    }
    return { next, prev };
  };

  goPrev = () => {
    const tabbarScroller = this.tabbarScroller;
    if (!tabbarScroller) return;

    let leftOffset = this.getTabBarOffset();
    const moveDist = tabbarScroller.scrollWidth / this.props.children.length;
    leftOffset += Math.round(moveDist);

    const { prev, next } = this.checkPrevNext(leftOffset);
    if (!prev) {
      leftOffset = 0;
    }

    this.tabbarScroller.style.left = `${leftOffset}px`;
    this.setState({
      prev,
      next
    });
  };

  goNext = () => {
    const tabbarScroller = this.tabbarScroller;
    if (!tabbarScroller) return;

    let leftOffset = this.getTabBarOffset();
    const moveDist = tabbarScroller.scrollWidth / this.props.children.length;
    leftOffset -= Math.round(moveDist);
    const maxOffset = this.getMaxOffset();

    const { prev, next } = this.checkPrevNext(leftOffset, maxOffset);
    if (!next) {
      leftOffset = -maxOffset;
    }

    this.tabbarScroller.style.left = `${leftOffset}px`;
    this.setState({
      prev,
      next
    });
  };

  scrollToActiveTab = () => {
    if (!this.state.showPrevNext) return;
    const tabbarScroller = this.tabbarScroller;
    const tabbar = this.tabbar;
    if (!tabbarScroller || !tabbar) return;
    const currTabItem = tabbarScroller.querySelector(TAB_BAR_ITEM_ACTIVE_CLASS);
    if (!currTabItem) return;
    const currTabItemBox = currTabItem.getBoundingClientRect();
    const tabbarBox = tabbar.getBoundingClientRect();
    const tabbarScrollerBox = tabbarScroller.getBoundingClientRect();
    let offset = this.getTabBarOffset();
    if (currTabItemBox.right > tabbarBox.right) {
      offset = -(
        currTabItemBox.left -
        tabbarScrollerBox.left +
        currTabItemBox.width -
        tabbarBox.width
      );
    } else if (currTabItemBox.left < tabbarBox.left) {
      offset = -(currTabItemBox.left - tabbarScrollerBox.left);
    }

    const { prev, next } = this.checkPrevNext(offset, this.getMaxOffset());
    tabbarScroller.style.left = `${offset}px`;
    this.setState({ prev, next });
  };

  scrollToFocusTab = () => {
    const tabbarScroller = this.tabbarScroller;
    const tabbar = this.tabbar;
    if (!tabbarScroller || !tabbar) return;
    const tabbarBox = tabbar.getBoundingClientRect();
    const tabbarScrollerBox = tabbarScroller.getBoundingClientRect();
    const focusedItem = tabbarScroller.querySelector(".a-tab-bar-item:focus");
    const focusedItemBox = focusedItem.getBoundingClientRect();
    const maxLeftOffset = tabbarScroller.scrollWidth - tabbarBox.width;
    const leftOffset = tabbarScrollerBox.left - focusedItemBox.left;
    if (Math.abs(leftOffset) <= maxLeftOffset) {
      tabbarScroller.style.left = 0;
      this.setState({
        prev: true
      });
    } else {
      tabbarScroller.style.left = `-${maxLeftOffset}px`;
      this.setState({
        next: false
      });
    }
  };

  renderTabBarItems = (child: Object, index: number) => {
    const { onKeyDown, onTabClick, value } = this.props;
    const {
      label,
      icon,
      value: itemValue,
      children,
      closable,
      isLoading,
      isLoadingBlocking,
      close,
      ...rest
    } = child ? child.props || {} : {};
    const key = typeof itemValue === "undefined" ? index : itemValue;
    const active = value === key;
    const tabClass = classnames("a-tab-bar-item", {
      [`active`]: active
    });

    if (typeof rest.id === "string") rest.id = `tab-bar-${rest.id}`;
    return [
      <KODIV
        isLi
        className={tabClass}
        onClick={e => {
          onTabClick && onTabClick(e, key, label);
        }}
        onKeyDown={e => {
          onKeyDown && onKeyDown(e, key, label);
        }}
        onKeyUp={e => {
          // scroll to the right tab when tab key pressed
          if (e.key === "Tab" && e.target.nodeName === "LI") {
            this.scrollToFocusTab();
          }
        }}
        key={index}
        role="presentation"
        {...rest}
      >
        <span className="a-tab-bar-label">
          {icon}
          {label && <span className="a-tab-bar-label-text">{label}</span>}
          {child.props.closable && (
            <KODIV
              className="appkiticon icon-circle-delete-outline"
              onClick={child.props.close}
              isSpan
              onKeyDown={e => {
                if (e.key === "Enter") child.props.close(e);
              }}
            />
          )}
        </span>
      </KODIV>
    ];
  };

  render() {
    let {
      className,
      children,
      onKeyDown,
      onTabClick,
      value,
      tabBarClass,
      size = "sm",
      centered = false,
      secondary = false,
      ...otherProps
    } = this.props;

    const { showPrevNext, prev, next } = this.state;
    const tabbarWrapperClasses = classnames("a-tab-bar-wrapper", {
      [tabBarClass]: tabBarClass,
      [`a-tab-bar-${size}`]: size,
      // 'a-text-center-align': centered,
      "a-tab-centered": centered,
      "a-tab-bar-secondary": secondary
    });
    const tabbarClasses = classnames("a-tab-bar", {
      "a-tab-bar-scrolling": showPrevNext
    });
    const leftIconClasses = classnames("a-scroll-icon a-prev-icon", {
      "a-icon-show": showPrevNext,
      "a-icon-disabled": !prev
    });
    const rightIconClasses = classnames("a-scroll-icon a-next-icon", {
      "a-icon-show": showPrevNext,
      "a-icon-disabled": !next
    });

    if (children && !Array.isArray(children)) {
      children = [children];
    }

    return (
      <div
        className={tabbarWrapperClasses}
        ref={node => (this.tabbarWrapper = node)}
        {...otherProps}
      >
        <div className="a-tab-bar-baseline">
          <div
            className={classnames("a-tab-bar-baseline-line", {
              "align-left": !showPrevNext
            })}
          />
        </div>
        <span
          className={leftIconClasses}
          onClick={this.goPrev}
          onKeyDown={() => {}}
        >
          <span className="a-icon-wrapper">
            <span className="appkiticon icon-left-chevron-circle-fill" />
          </span>
        </span>
        <span
          className={rightIconClasses}
          onClick={this.goNext}
          onKeyDown={() => {}}
        >
          <span className="a-icon-wrapper">
            <span className="appkiticon icon-right-chevron-circle-fill" />
          </span>
        </span>
        <div className={tabbarClasses}>
          <div
            className="a-overflow-wrapper"
            ref={node => (this.tabbar = node)}
          >
            <div
              className={classnames("a-tab-bar-scroller", {
                "no-absolute-pos": centered && !showPrevNext
              })}
              ref={node => (this.tabbarScroller = node)}
            >
              {children && children.length > 0 ? (
                <div>
                  <ul>
                    {children.map((child, index) => {
                      return child
                        ? this.renderTabBarItems(child, index)
                        : null;
                    })}
                  </ul>
                  <span
                    className="a-tab-bar-indicator"
                    style={this.state.indicatorStyle}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TabBar.propTypes = {
  className: PropTypes.string,
  secondary: PropTypes.bool,
  value: PropTypes.string,
  tabbarClasses: PropTypes.string,
  onTabClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  centered: PropTypes.bool
};

TabBar.displayName = "TabBar";

export default TabBar;
