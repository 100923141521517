/* istanbul ignore file */
import ClassNames from 'classnames';
import React, { Component } from 'react';
import { Button } from 'appkit-react';
import PropTypes from 'prop-types';
import {isWithin} from '../utils/domHelper';


//theme priority:
// prop > localstorage > state

function getThemeInfo(){
  return parent.appkit_theme_notify_queue;
}

//ie does not have this function, implmenet our own
function getRootNode(node){
  let result = node;

  while(result.parentNode){
    result = result.parentNode;
  }

  return result;
}

function processThemeName(theme){
  if(!theme){
    return ""
  }else if(theme.startsWith && theme.startsWith("theme-")){
    return theme;
  }else{
    return `theme-${theme}`;
  }
}

class ThemeContainer extends Component {

    constructor(props) {
      super(props);
      this.state = {
        theme: null
      };
    }

    handleNotification(info){
      const {theme, level} = info;
      const _theme = processThemeName(theme);

      if(_theme){
        this.setState({
          theme: _theme
        });
      }

      if(level){
        this.setState({
          level: level.toLowerCase()
        });
       }
    }

    isInStoryBook(){
      return this.props.isInStoryBook && window.frameElement && getThemeInfo();
    }

    updateStoybookRoot(theme, level){
      if(this.isInStoryBook()){
        let storybookRoot = window.frameElement.getRootNode ? window.frameElement.getRootNode() : getRootNode(window.frameElement);

        storybookRoot = storybookRoot.getElementById("root");
        storybookRoot.setAttribute("theme", theme || this.getCurrentTheme());

        storybookRoot.setAttribute("level", level|| this.getCurrentLevel());
      }
    }

    componentDidMount() {
      if(this.isInStoryBook()){
        const themeInfo = getThemeInfo();
        this.uid = themeInfo.add(this.handleNotification.bind(this));
        const theme = themeInfo.currentTheme;
        const _theme = processThemeName(theme);
        const _level = themeInfo.currentLevel;

        this.handleNotification({theme: _theme, level: _level});
        this.updateStoybookRoot(_theme, _level);
      }

      this.props.componentDidMountCallBack && this.props.componentDidMountCallBack();
    }

    componentDidUpdate(){
      this.updateStoybookRoot();
      this.props.componentDidUpdateCallback && this.props.componentDidUpdateCallback();
    }

    componentWillUnmount() {
      if(this.isInStoryBook()){
        const themeInfo = getThemeInfo();
        themeInfo.remove(this.uid)
      }

      this.props.componentWillUnmountCallBack && this.props.componentWillUnmountCallBack();
    }

    getCurrentTheme(){
      return this.props.theme || this.state.theme || "";
    }

    getCurrentLevel(){
      return this.props.level || this.state.level || "";
    }

    render() {
      const {
          className,
          children,
          theme,
          componentDidUpdateCallback,
          isInStoryBook,
          level,
          ...otherProps
      } = this.props;


      const _theme = this.getCurrentTheme();
      const _level = this.getCurrentLevel();

      const wrapperClassName = ClassNames("a-theme-container", "100-height",  className, {
        "is-inside-storybook": isInStoryBook
      });

      return (
        <div className={wrapperClassName} theme={_theme} level={_level} {...otherProps}>
            {children}
        </div>
      );
    }
  }

  ThemeContainer.propTypes = {
    className: PropTypes.string,
    componentDidUpdateCallback: PropTypes.func,
    level: PropTypes.oneOf(["level1", "level2", "level3"]),
    theme: PropTypes.oneOf(["theme-dark", "theme-light"])
  };

  export default ThemeContainer;
