//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassName from 'classnames';
import type { Node } from 'react';
import type { CommonType } from 'appkit-react-utils/commonType';
import { preventDefaultEvent } from 'appkit-react-utils/commonFunc';

type TagProps = {
  size?: string,
  children?: Node,
  closeable?: boolean,
  onClose?: Function
};

type TagState = {
  closed: boolean
};

class Tag extends Component<TagProps & CommonType, TagState> {
  state: TagState;

  constructor(props: Object) {
    super(props);
    this.state = {
      closed: false,
      hasOutline: true
    };
  }

  handleClick = (e: SyntheticInputEvent<*>) => {
    e.persist && e.persist();
    if(this.props.disabled){
      return;
    }

    this.setState({
      closed: true
    }, () => {
      this.props.onClose && this.props.onClose(e);
    });
  };

  handleCloseKeyUp(e){
    preventDefaultEvent(e);
    if(e.key === "Enter"){
      this.handleClick(e);
    }
  }

  onMouseDown = () => {
    this.setState({
      hasOutline: false
    });
  };

  render() {
    const {
      style,
      size='md',
      closeable = false,
      children,
      className,
      disabled,
      ...otherProps
    } = this.props;
    const { closed } = this.state;
    const tagClassName = ClassName('a-tag', className, {
      'a-tag-hidden': closed,
      [`a-tag-${size}`]: size,
      disabled: disabled
    });

    return (
      <span className={tagClassName} style={style} {...otherProps}>
        <span className="a-tag-content">
          {children}
        </span>
        {closeable && (
          <button
            type="button"
            className={ClassName("a-icon appkiticon icon-close-fill a-tag-close-button", {
              'without-outline': !this.state.hasOutline
            })}
            onClick={this.handleClick}
            onKeyUp={this.handleCloseKeyUp.bind(this)}
            onMouseDown={this.onMouseDown}
            disabled={disabled}
          >
          </button>
        )}
      </span>
    );
  }
}

Tag.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  closeable: PropTypes.bool,
  onClose: PropTypes.func,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  disabled: PropTypes.bool
};

Tag.displayName = 'Tag';

export default Tag;
