//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import type { Node } from 'react';
import type { CommonType } from 'appkit-react-utils/commonType';
import Validator from 'appkit-react-utils/validator';

type AvatarProps = {
  /**
   * The content of Avatar
   */
  children?: Node,
  /**
   * The shape of avatar
   */
  shape?: 'circle' | 'square',
  /**
   * The size of the avatar
   * default : 32 * 32
   * large : 48 * 48
   * xlarge : 64 * 64
   */
  size?: 'default' | 'middle' | 'large' | 'xlarge',
  /**
   * The address of the image for an image avatar
   */
  src?: string | Object,
  /**
   * The label of avatar
   */
  label?: string,
  /**
   * If `true`, the border of the avatar is removed
   */
  noBorder?: boolean
};

type AvatarState = {};

class Avatar extends Component<AvatarProps & CommonType, AvatarState> {
  render() {
    const {
      style,
      className,
      shape = 'circle',
      size = 'default',
      label,
      icon,
      src,
      noBorder,
      ...otherProps
    } = this.props;
    let children = this.props.children;
    const classes = ClassNames('a-avatar', className, {
      [`a-avatar-${size}`]: size,
      [`a-avatar-${shape}`]: shape,
      [`a-avatar-icon`]: icon,
      [`a-avatar-image`]: src || Validator.isImageComponent(children),
      [`a-avatar-text`]: (label && !src) || Validator.isString(children),
      [`no-border`]: noBorder
    });

    if (src && typeof src === 'string') {
      children = <img src={src} alt="avatar" />;
    } else if (icon) {
      children = icon;
    } else if (label && typeof label === 'string') {
      children = <span>{label}</span>;
    } else {
      children = src || label || children;
    }

    return (
      <span style={style} className={classes} {...otherProps}>
        {children}
      </span>
    );
  }
}

Avatar.propTypes = {
  shape: PropTypes.oneOf(['circle', 'square']),
  size: PropTypes.oneOf(['default', 'middle', 'large', 'xlarge']),
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  icon: PropTypes.object,
  style: PropTypes.object,
  className: PropTypes.string,
  noBorder: PropTypes.bool
};

Avatar.displayName = 'Avatar';

export default Avatar;
