//@flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import ClassNames from "classnames";
import type { CommonType } from "appkit-react-utils/commonType";
import SelectList from "./SelectList";
import { ButtonGroup, ButtonGroupItem, SelectOption, Tag } from "../index";
import { isWithin } from "../utils/domHelper";
import { alignElement } from "dom-align";
import { getCurrentTheme, getCurrentLevel } from "../index";
import { _uuid } from "../utils/uuidGenerator";
const getText = require("./react-text-content");

function isExist(e) {
  return e !== null && e !== undefined;
}

type SelectProps = {
  defaultValue?: number | string | Array<any>,
  value?: number | string | Array<any>,
  placeholder?: string,
  size?: "default" | "large",
  multiple?: boolean,
  showSelectAll?: boolean,
  disabled?: boolean,
  onSelect?: Function,
  children?: Node,
  showSearchOnToggle?: boolean,
  showSearchOnList?: boolean,
  tabs: array,
  tabsKind: string,
  toggleMode?: "select" | "search",
  customSelectedCountStringGenerator?: Function,
  customSelectAllStringOnToggle?: string,
  customSelectAllStringOnList?: string,
  dropdownSelectWidth?: number,
  getPopupContainer?: Function
};

type SelectState = {
  value?: number | string | Array<any>,
  text?: string,
  visible: boolean,
  selectOptions: Array<any>,
  selectAll: boolean,
  selectAllDisabled: boolean
};

function convertToArray(value) {
  if (!Array.isArray(value)) {
    if (value !== undefined && value !== null) {
      return [value];
    } else {
      return [];
    }
  }
  return value;
}

const SELECT_ALL_KEY = "appkit-select-component-select-all-key";
const SELECT_ALL_STRING = "Select all";
const SUBSELECT_ALL_KEY = "appkit-select-group-title-subselectall";

const toggle_height = 34;
const large_toggle_height = 50;

function isSelectOption(reactChild) {
  return reactChild && reactChild.type.displayName === "SelectOption";
}

function isSelectGroupTitle(reactChild) {
  return reactChild && reactChild.type.displayName === "SelectGroupTitle";
}

const cancelPropagation = e => {
  if (!e) {
    return;
  }
  e.cancelBubble = true;
  if (e.stopPropagation) {
    e.stopPropagation();
  }
};

class Select extends Component<SelectProps & CommonType, SelectState> {
  state: SelectState;
  static defaultProps = {
    size: "default",
    className: "",
    showSearchOnToggle: false,
    showSearchOnList: false,
    tabsKind: "primary",
    toggleMode: "select",
    showSelectAll: false,
    dynamicUI: true,
    maxListHeight: 204,
    dropdownRenderMode: "default",
    dropdownAlwaysDown: false
  };
  selectList: any;
  selectDiv: any;

  constructor(props: Object) {
    super(props);
    this.state = {
      value: this.props.value || this.props.defaultValue,
      text: this.props.placeholder,
      visible: false,
      selectOptions: [],
      selectAll: false,
      selectAllDisabled: false,
      searchWord: "",
      keyNavIndex: -1,
      allowOutline: true,
      currentTabValue:
        (this.props.tabs && this.props.tabs[0] && this.props.tabs[0].value) ||
        ""
    };

    this.childrenArray = React.Children.toArray(this.props.children);
    this.el = document.querySelector("body");
    this.hasSetMaxHeight = false;
    this.selectOptionNum = 0;
    this.allSelectedString =
      this.props.customSelectAllStringOnToggle || "All selected";
    this.uuid = _uuid(8, 16);
  }

  applyMaxHeight() {
    if (!this.state.visible) {
      return;
    }

    let maxHeight = this.props.maxListHeight;
    let _selectList = ReactDOM.findDOMNode(this.selectList);
    const selectscroll = _selectList.querySelector(
      ".a-select-list-scroll-wrap"
    );

    if (!selectscroll || selectscroll.clientHeight <= maxHeight) {
      return;
    }

    this.hasSetMaxHeight = true;
    selectscroll.style.setProperty("max-height", `${maxHeight}px`);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
  }

  hasUserValue() {
    return (
      this.props.hasOwnProperty("value") &&
      this.props.value !== null &&
      this.props.value !== undefined
    );
  }

  getActiveItems(selectOptions) {
    return selectOptions.filter(item => {
      if (item.value.startsWith && item.value.startsWith(SUBSELECT_ALL_KEY)) {
        return false;
      }
      return item.isActive;
    });
  }

  reInitState() {
    const { placeholder } = this.props;
    
    let selectOptions = [];
    let currentText = "";
    const that = this;
    let disableCount = 0;
    let selectOptionCount = 0;
    //create select options
    if (this.selectList) {
      this.childrenArray.forEach(v => {

        // if (isSelectOption(v) && !v.props.disabled) {
        if (isSelectOption(v)) {
          selectOptionCount ++;
          if (v.props.disabled) {
            disableCount ++;
          }
          const value =
            v.props.value === undefined || v.props.value === null
              ? ""
              : v.props.value;
          selectOptions.push({
            value: value,
            isActive: that.isChildActive(v),
            isDisabled: v.props.disabled,
            text: v.props.searchableText || getText(v.props.children)
          });
        }
      });

      if (disableCount === selectOptionCount) {
        this.setState({
          selectAllDisabled: true
        });
      }
      //Set current text
      let activeItems = this.getActiveItems(selectOptions);
      currentText = this.chooseSelectedText(activeItems, selectOptions);
      this.setState({
        selectOptions: selectOptions,
        text: currentText || placeholder,
      });

      //set default selectAll
      // if (currentText === this.allSelectedString) {
      //   this.setState({
      //     selectAll: true
      //   });
      // }

  
      let newSelectAll = false;
      if (selectOptions.filter(item => (!item.isDisabled) && (!item.isActive)).length === 0) {
        newSelectAll = true;
      }
      const disabledNoActiveOptions = selectOptions.filter(item => item.isDisabled && (!item.isActive));

      if (disabledNoActiveOptions.length === selectOptions.length) {
        newSelectAll = false;
      }

      this.setState({
        selectAll: newSelectAll
      });

    }
    //set the max height for select
    this.applyMaxHeight();
  }

  componentDidMount() {
    window.addEventListener("resize", this.onWindowResize);
    this.reInitState();
    let toggleDiv = ReactDOM.findDOMNode(this.selectToggleDiv);
    this.toggleWidth =
      this.props.dropdownSelectWidth || toggleDiv.getBoundingClientRect().width;
  }

  componentDidUpdate() {
    if (this.state.visible && (this.props.dynamicUI || !this.hasSetMaxHeight)) {
      this.applyMaxHeight();
      this.setSelectListPos();
      let toggleDiv = ReactDOM.findDOMNode(this.selectToggleDiv);
      this.toggleWidth =
        this.props.dropdownSelectWidth ||
        toggleDiv.getBoundingClientRect().width;
    }

    //adjust scroll for keyboard navigation
    const { keyNavIndex, visible } = this.state;
    if (visible && keyNavIndex > -1) {
      let _selectList = ReactDOM.findDOMNode(this.selectList);
      const selectscroll = _selectList.querySelector(
        ".a-select-list-scroll-wrap"
      );
      const highlightDiv = _selectList.querySelector(".keyboardHighlight");

      if (this.scrollBackToZero || keyNavIndex === 0) {
        selectscroll.scrollTop = 0;
        this.cleanScrollTemp();
        return;
      }

      if (!highlightDiv) {
        this.cleanScrollTemp();
        return;
      }

      if (this.scrollToEnd) {
        this.cleanScrollTemp();
        selectscroll.scrollTop = selectscroll.scrollHeight;
      }

      const divOffset = highlightDiv.offsetTop;
      const divHeight = highlightDiv.clientHeight;

      const bottomExceed =
        divOffset +
        divHeight -
        (selectscroll.scrollTop + selectscroll.clientHeight);
      const topExceed = divOffset - selectscroll.scrollTop;

      // the debugging is very difficult, lets keep this
      // console.log("---------------------");
      // console.table({
      //   "base": base,
      //   "divHeight": divHeight,
      //   "divOffset": divOffset,
      //   "selectscroll.clientHeight":  selectscroll.clientHeight,
      //   "selectscroll.scrollTop": selectscroll.scrollTop
      // })
      // console.log(bottomExceed,  divOffset + divHeight - (selectscroll.scrollTop + selectscroll.clientHeight));
      // console.log(topExceed, divOffset - selectscroll.scrollTop)

      if (bottomExceed > 0) {
        selectscroll.scrollTop = selectscroll.scrollTop + bottomExceed;
      } else if (topExceed < 0) {
        selectscroll.scrollTop = divOffset;
      }
    }
  }

  onWindowResize = () => {
    this.componentDidUpdate();
    //force render for dropdown list width change.
    this.forceUpdate();
  };

  UNSAFE_componentWillReceiveProps(props) {

    this.childrenArray = React.Children.toArray(this.props.children);
    let forceSync = false;
    let propsChildrenArray = React.Children.toArray(props.children);
    if (
      JSON.stringify(propsChildrenArray.map(v => v.props.value)) !==
      JSON.stringify(this.childrenArray.map(v => v.props.value))
    ) {
      this.childrenArray = propsChildrenArray;
      this.reInitState();
      //forcely sync state.value with props.value
      forceSync = true;
    }

    if (
      forceSync ||
      JSON.stringify(this.props.value) !== JSON.stringify(props.value)
    ) {
      this.setState(
        {
          value: props.value
        },
        () => {
          this.childrenArray = React.Children.toArray(this.props.children);
          this.handleValueChange(this.state.value);
        }
      );
    }
  }

  selectSingleOne(value) {
    let result;
    this.childrenArray.some(ele => {
      if (ele && ele.props && ele.props.value === value) {
        result = isExist(ele.props.selectedDisplay)
          ? ele.props.selectedDisplay
          : ele.props.children;
        return true;
      }
      return false;
    });
    return isExist(result) ? result : "";
  }

  handleValueChange = v => {
    const { multiple, placeholder } = this.props;
    const { selectOptions } = this.state;
    //set for multiple select
    if (multiple) {
      this.handleMultipleSelect(v);
    } else {
      const _selectedText = this.selectSingleOne(v) || placeholder;
      this.updateStateOnSelect(v, _selectedText, selectOptions, false);
    }
  };

  setSelectListPos() {
    const { size, dropdownAlwaysDown } = this.props;
    let selectListDiv = ReactDOM.findDOMNode(this.selectList);
    if (!selectListDiv) {
      return;
    }

    let _optionContainerHeight = selectListDiv.getBoundingClientRect().height;
    let toggleDiv = ReactDOM.findDOMNode(this.selectToggleDiv);

    let togglePosition = toggleDiv.getBoundingClientRect();

    let _clientHeight;
    //assume only one modal in one page
    let isWithinNode = isWithin(toggleDiv, ".modal-content");
    if (!!isWithinNode) {
      const modalContent = isWithinNode;
      _clientHeight = modalContent.clientHeight;
    } else {
      _clientHeight = document.documentElement
        ? document.documentElement.clientHeight
        : 0;
    }

    let _bottomOffset = _clientHeight - togglePosition.bottom;
    let _topOffset = togglePosition.top;

    let toggleHeight = size === "large" ? large_toggle_height : toggle_height;
    toggleHeight = toggleHeight + "px";
    this.selectToggleDiv.style.setProperty("height", toggleHeight);
    const alignConfig = {
      points: ["bl", "tl"],
      offset: [0, -10],
      targetOffset: ["0", "0"],
      overflow: {
        adjustX: true,
        adjustY: false
      }
    };
    this.source = ReactDOM.findDOMNode(this.selectList);
    this.target = ReactDOM.findDOMNode(this.selectToggleDiv);
    if (
      _bottomOffset < _optionContainerHeight &&
      _topOffset > _optionContainerHeight &&
      !dropdownAlwaysDown
    ) {
      alignConfig.points = ["bl", "tl"];
      alignConfig.offset = [0, -10];
      alignElement(this.source, this.target, alignConfig);
    } else {
      const alignConfig = {
        points: ["tl", "bl"],
        offset: [0, 10],
        targetOffset: ["0", "0"],
        overflow: {
          adjustX: true,
          adjustY: false
        }
      };
      alignElement(this.source, this.target, alignConfig);
    }
  }

  checkInterval(prevKey, interval) {
    const now = new Date().getTime();
    if (this[prevKey] && now - this[prevKey] < interval) {
      return true;
    }
    this[prevKey] = now;
    return false;
  }

  toggleMenu = () => {
    if (this.checkInterval("prevToggleMenu", 300)) {
      return;
    }

    const { disabled } = this.props;
    const { visible } = this.state;

    if (disabled) {
      return;
    }

    if (visible) {
      this.cleanTemp();
    } else {
      this.setState(
        {
          visible: true,
          searchWord: ""
        },
        () => {
          this.reInitState();
          const alignConfig = {
            points: ["tl", "bl"],
            offset: [0, 10],
            targetOffset: ["0", "0"],
            overflow: {
              adjustX: true,
              adjustY: false
            }
          };
          this.source = ReactDOM.findDOMNode(this.selectList);
          this.target = ReactDOM.findDOMNode(this.selectToggleDiv);
          alignElement(this.source, this.target, alignConfig);
        }
      );
    }
  };

  handleClickOutside(e) {
    let targetId = e.target.getAttribute("id");
    if (
      !(
        (this.state.visible && targetId && targetId === this.uuid) ||
        (e.target.className.indexOf &&
          e.target.className.indexOf("arrow-icon") > 0)
      )
    ) {
      this.cleanTemp();
    }
  }

  chooseSelectedText(activeItems, selectOptions) {
    let newSelectedText = this.props.placeholder;

    if (activeItems.length === 1) {
      newSelectedText = this.selectSingleOne(activeItems[0].value);
    } else if (
      activeItems.length > 1 &&
      activeItems.length < selectOptions.length
    ) {
      newSelectedText = this.props.customSelectedCountStringGenerator
        ? this.props.customSelectedCountStringGenerator(activeItems.length)
        : `${activeItems.length} items selected`;
    } else if (
      activeItems.length === selectOptions.length &&
      selectOptions.length !== 0
    ) {
      newSelectedText = this.allSelectedString;
    }
    return newSelectedText;
  }

  handleMultipleSelect(val) {
    const { placeholder } = this.props;
    const { selectOptions, selectAll } = this.state;
    let newSelectedText = placeholder;
    let newSelectedValue = [];
    let newSelectAll = false;

    function getSelectAll() {
      let newSelectAll = false;

      if (selectOptions.filter(item => (!item.isDisabled) && (!item.isActive)).length === 0) {
        newSelectAll = true;
      }
      const disabledNoActiveOptions = selectOptions.filter(item => item.isDisabled && (!item.isActive));

      if (disabledNoActiveOptions.length === selectOptions.length) {
        newSelectAll = false;
      }
      return newSelectAll;
    }

    if (val && val.startsWith && val.startsWith(SUBSELECT_ALL_KEY)) {
      const index = parseInt(val.split(" ")[1]);
      let subgroup = this.getSubgroup(index);
      const activeNum = this.getSubgroupActiveNum(subgroup);
      const { isActive } = this.isSubgroupActive(subgroup);

      subgroup = subgroup.map(e => e.props.value);
      selectOptions.forEach(item => {
        if (subgroup.includes(item.value) && !item.isDisabled) {
          if (isActive) {
            // turn off
            item.isActive = false;
          } else {
            //turn on
            item.isActive = true;
            newSelectedValue.push(item.value);
          }
        } else if (item.isActive) {
          newSelectedValue.push(item.value);
        }
      });

      let activeItems = this.getActiveItems(selectOptions);
      newSelectedText = this.chooseSelectedText(activeItems, selectOptions);
      newSelectAll = getSelectAll();
    } else if (val === SELECT_ALL_KEY) {
      selectOptions.forEach(item => {
        if (!item.isDisabled) {
          item.isActive = !selectAll;
        }
        
        if (item.isActive) {
        // if (!selectAll) {
          newSelectedValue.push(item.value);
        }
      });
      newSelectAll = !selectAll; //toggle
      
      // newSelectedText = newSelectAll ? this.allSelectedString : newSelectedText;
      let activeItems = this.getActiveItems(selectOptions);
      newSelectedText = this.chooseSelectedText(activeItems, selectOptions);
    } else {
      if (this.hasUserValue()) {
        if (!Array.isArray(val)) {
          val = [val];
        }
        
        selectOptions.find(item => {
          item.isActive = val.includes(item.value);
        });
      } else {
        selectOptions.find(item => {
          if (item.value === val) {
            item.isActive = !item.isActive; //toggle
          }
        });
      }
      //get all active items
      let activeItems = this.getActiveItems(selectOptions);
      newSelectedValue = activeItems.map(item => item.value);

      newSelectedText = this.chooseSelectedText(activeItems, selectOptions);
      newSelectAll = getSelectAll();
    }
    this.updateStateOnSelect(
      newSelectedValue,
      newSelectedText,
      selectOptions,
      newSelectAll
    );
  }

  updateStateOnSelect(
    selectedValue,
    selectedText,
    selectedOptions,
    selectedAll
  ) {
    const { onSelect, multiple } = this.props;
    this.setState(
      {
        value: selectedValue,
        text: selectedText,
        selectOptions: selectedOptions,
        selectAll: selectedAll
      },
      () => {
        if (!this.hasUserValue()) {
          onSelect && onSelect(selectedValue);
        }
      }
    );
    // close the select list if not a multiple list
    if (!multiple) {
      this.setState({
        visible: false
      });

      this.props.onClose && this.props.onClose();
    }
  }

  onSelect(e, v) {
    if (this.checkInterval("prevOnSelect", 300)) {
      return;
    }
    let { onSelect, multiple, value } = this.props;
    let vals = [];
    if (this.hasUserValue()) {
      if (multiple) {
        value = convertToArray(value);
        if (value.includes && value.includes(v)) {
          vals = value.filter(ele => {
            return ele !== v;
          });
        } else {
          if (v === "appkit-select-component-select-all-key") {
            
            const disabledOptions = this.childrenArray.filter(
              item => (!!item.props.value) && item.props.disabled
            ).map(item => item.props.value);

            const disableNoActiveOptions = disabledOptions.filter(v => !value.includes(v));


            let valueOptions = this.childrenArray.filter(
              item => !!item.props.value
            );

            vals = valueOptions.map(item => {
              return item.props.value;
            });


            let canActiveOptions = vals;

            if (disableNoActiveOptions.length > 0) {
              canActiveOptions = vals.filter(v => !disableNoActiveOptions.includes(v))
            }

            if (canActiveOptions.length > value.length) {
              vals = canActiveOptions;
            } else {
              vals = disabledOptions.filter(v => value.includes(v))
            }

          } else if (v.startsWith && v.startsWith(SUBSELECT_ALL_KEY)) {

            let optionsLength = this.childrenArray.length;
            let currentGroupIndex = Number(v.split(" ")[1]);
            let nextGroupIndex = currentGroupIndex;
            for (let i = currentGroupIndex + 1; i < optionsLength; i++) {
              if (this.childrenArray[i].props.hasOwnProperty("showSelectAll")) {
                nextGroupIndex = i;
                break;
              }
              if (i === optionsLength - 1) {
                nextGroupIndex = optionsLength;
              }
            }

            let currentGroupFirstOption = this.childrenArray[
              currentGroupIndex + 1
            ];
            if (!currentGroupFirstOption) {
              return;
            }
            let currentGroupOptionsValue = this.childrenArray
              .filter((child, index) => {
                return index > currentGroupIndex && index < nextGroupIndex;
              })
              .map(item => {
                return item.props.value;
              });
            let allInclude = true; //flag to identify if any option has been checked in this group
            for (let val of currentGroupOptionsValue) {
              if (value.indexOf(val) === -1) {
                allInclude = false;
                break;
              }
            }
            if (allInclude) {
              //uncheck all options in group
              vals = value.filter(val => {
                return currentGroupOptionsValue.indexOf(val) === -1;
              });
            } else {
              //check all options in group
              vals = value.concat(
                currentGroupOptionsValue.filter(
                  item => value.indexOf(item) === -1
                )
              );
            }












          } else {
            vals = value.concat(v);
          }
        }
      } else {
        vals = v;
        this.setState({
          visible: false
        });
      }
      onSelect && onSelect(vals);
    } else {
      this.handleValueChange(v);
    }
  }

  onSearch(e) {
    const str = e.target.value;
    this.setState({
      searchWord: str
    });
  }

  cleanScrollTemp() {
    this.scrollToEnd = null;
    this.scrollBackToZero = null;
  }

  cleanTemp(restoreFocus) {
    this.cleanScrollTemp();
    this.keyboardNavItemValue = null;
    const prevVisible = this.state.visible;
    this.setState({ visible: false, keyNavIndex: -1, searchWord: "" });
    if (restoreFocus) {
      this.focusWrapperAgain();
    }

    prevVisible && this.props.onClose && this.props.onClose();
  }

  cleanTempAfterKeySelect() {
    this.keyboardNavItemValue = null;
    this.setState({ keyNavIndex: -1, searchWord: "" });
  }

  focusWrapperAgain() {
    const wrapperDom = ReactDOM.findDOMNode(this.selectWrapperDiv);
    wrapperDom.focus();
  }

  onKeyDown(e) {
    const { visible, currentTabValue, keyNavIndex } = this.state;
    const { tabs, showSearchOnList, showTagsOnToggle, multiple } = this.props;

    let needPreventDefault = true;

    const isDown = e.key === "ArrowDown";
    const isUp = e.key === "ArrowUp";
    const isLeft = e.key === "ArrowLeft";
    const isRight = e.key === "ArrowRight";
    const isEnter = e.key === "Enter";
    const isEsc = e.key === "Escape";
    const isTab = e.key === "Tab";

    //a very special case handling for toggle tags
    if (
      showTagsOnToggle &&
      this.getActiveItems(this.state.selectOptions).length > 0 &&
      !!isWithin(e.target, ".a-select-toggle-inside-tag")
    ) {
      return;
    }

    if (!visible) {
      if (isEnter || isDown || isUp) {
        this.setState({ visible: true });
      } else if (isTab) {
        this.cleanTemp();
        needPreventDefault = false;
      }
    } else {
      //handle when visible
      if (isEsc) {
        this.cleanTemp(true);
      } else if (
        isEnter &&
        keyNavIndex > -1 &&
        isExist(this.keyboardNavItemValue)
      ) {
        this.onSelect(e, this.keyboardNavItemValue);
        if (!multiple) {
          this.cleanTempAfterKeySelect();
          this.focusWrapperAgain();
        }
      } else if (isEnter && keyNavIndex === -1) {
        this.cleanTemp(true);
        needPreventDefault = false;
      } else if (isDown) {
        let nextIndex = keyNavIndex + 1;
        nextIndex = Math.min(this.selectOptionNum - 1 || 0, nextIndex);
        nextIndex = Math.max(nextIndex, 0);
        this.setState({ keyNavIndex: nextIndex, visible: true });
      } else if (isUp) {
        let nextIndex = keyNavIndex - 1;
        nextIndex = Math.max(nextIndex, 0);
        this.setState({ keyNavIndex: nextIndex, visible: true });
      } else if ((isLeft || isRight) && this.hasTabs()) {
        const tabValues = tabs.map(e => e.value);
        let index = tabValues.indexOf(currentTabValue);
        index = isLeft ? index - 1 : index + 1;
        index = Math.max(index, 0);
        index = Math.min(index, tabs.length - 1);

        this.scrollBackToZero = true;
        this.setState({
          currentTabValue: tabValues[index]
        });
      } else if (isTab && !showSearchOnList) {
        this.cleanTemp(true);
      } else {
        needPreventDefault = false;
      }
    }

    if (needPreventDefault) {
      e.preventDefault && e.preventDefault();
    }
  }

  onTabChange(e) {
    this.setState({
      currentTabValue: e.target.getAttribute("data-value")
    });
  }

  isChildActive(child) {
    let result = false;
    if (
      child.props &&
      child.props.value !== undefined &&
      child.props.value !== null
    ) {
      if (this.props.multiple && Array.isArray(this.state.value)) {
        result = this.state.value.includes(child.props.value);
      } else {
        result = Boolean(child.props.value === this.state.value);
      }
    }
    return result;
  }

  isSearchAllowed() {
    const { multiple, tabs } = this.props;
    const { searchWord } = this.state;
    return multiple && !tabs && !searchWord;
  }

  getListSelectAll() {
    const { multiple, showSelectAll } = this.props;
    const { selectAll, value, keyNavIndex, selectAllDisabled } = this.state;

    if (this.isSearchAllowed() && showSelectAll) {
      let activeNum = 0;
      this.childrenArray.forEach(child => {
        if (
          isSelectOption(child) &&
          value &&
          value.includes(child.props.value)
        ) {
          activeNum++;
        }
      });

      let keyboardHighlight = false;
      if (keyNavIndex === 0) {
        keyboardHighlight = true;
        this.keyboardNavItemValue = SELECT_ALL_KEY;
      }

      let _selectAll = (
        <SelectOption
          className="a-select-select-all"
          key={SELECT_ALL_KEY}
          value={SELECT_ALL_KEY}
          multiple={multiple}
          keyboardHighlight={keyboardHighlight}
          isActive={selectAll}
          disabled={selectAllDisabled}
        >
          {" "}
          {this.props.customSelectAllStringOnList || SELECT_ALL_STRING}{" "}
          <span className="a-select-all-select-count"> ({activeNum}) </span>
        </SelectOption>
      );

      const result = [];
      result.push(_selectAll);
      return <React.Fragment> {result}</React.Fragment>;
    } else {
      return undefined;
    }
  }

  filterBySearchword(childrenArr) {
    let foundNum = 0;
    const { selectOptions, searchWord } = this.state;

    const _children = childrenArr.filter(child => {
      if (isSelectOption(child)) {
        const childValue = child.props.value;
        let text;
        if (child.props.disabled) {
          text = child.props.searchableText || getText(child);
        } else {
          text = selectOptions.filter(e => e.value === childValue)[0].text;
        }

        if (text.toLowerCase().includes(searchWord.toLowerCase())) {
          foundNum++;
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });

    return {
      children: _children,
      foundNum: foundNum
    };
  }

  hasTabs() {
    const { tabs } = this.props;
    return this.state.visible && tabs && tabs.length > 0;
  }

  renderContent() {
    const {
      multiple,
      children,
      tabs,
      tabsKind,
      showTagsOnToggle,
      showSelectAll
    } = this.props;
    const { searchWord, currentTabValue } = this.state;
    let keyNavIndex = this.state.keyNavIndex;
    if (!children) {
      return undefined;
    }
    this.childrenArray = React.Children.toArray(children);

    let _children = this.childrenArray.slice();

    //due to ButtonGroup mechanism, only draw it when visible
    if (this.hasTabs()) {
      //render tabs
      const tabComponents = tabs.map(e => (
        <ButtonGroupItem tabIndex={-1} key={e.value} data-value={e.value}>
          {e.text}
        </ButtonGroupItem>
      ));

      const tabsGrounp = (
        <div
          className="a-select-radio-group-wrapper"
          key="a-select-radio-group-wrapper"
        >
          <ButtonGroup
            tabIndex={-1}
            kind={tabsKind}
            size={"sm"}
            className="a-select-radio-group"
            value={currentTabValue.toString()}
            onChange={this.onTabChange.bind(this)}
          >
            {tabComponents}
          </ButtonGroup>
        </div>
      );
      //filter children by tab value
      _children = children.filter(e => {
        return (
          e.props.hasOwnProperty("tab") &&
          e.props.tab.toString() === currentTabValue.toString()
        );
      });
      _children.unshift(tabsGrounp);
    }

    //filter by search word
    if (searchWord && !tabs) {
      const temp = this.filterBySearchword(_children);
      const foundNum = temp.foundNum;
      _children = temp.children;

      if (foundNum === 0) {
        _children.push(
          <div
            className="no-match"
            key="a-no-match-key-here"
          >{`No matches for "${searchWord}"`}</div>
        );
      }
    }

    const getSubselectKey = index => SUBSELECT_ALL_KEY + " " + index;

    //send prop to selectOption
    let selectOptionIndex = showSelectAll ? 0 : -1;
    const totalLength = _children.length;
    _children = _children.map((child, index) => {
      if (child) {
        // For single select or multislect
        const option = { multiple: multiple };
        if (isSelectOption(child)) {
          option.isActive = this.isChildActive(child);
          if (showTagsOnToggle && !child.props.showAddButton) {
            option.disabledForTags = true;
          }
        } else if (isSelectGroupTitle(child)) {
          const next = _children[index + 1];
          option.spacing = {};
          if (next) {
            if (isSelectGroupTitle(next)) {
              option.spacing.bottom = 8;
            } else if (isSelectOption(next)) {
              option.spacing.bottom = 4;
            }
          }

          const prev = _children[index - 1];
          if (prev) {
            if (isSelectOption(prev)) {
              option.spacing.top = 4;
            }
          }
        }

        if (
          isSelectOption(child) ||
          (isSelectGroupTitle(child) && child.props.showSelectAll)
        ) {
          selectOptionIndex++;

          if (keyNavIndex > -1) {
            if (selectOptionIndex === keyNavIndex) {
              option.keyboardHighlight = true;

              if (!child.props.disabled && !option.disabledForTags) {
                this.keyboardNavItemValue = isSelectGroupTitle(child)
                  ? getSubselectKey(index)
                  : child.props.value;

                if (index === totalLength - 1) {
                  this.scrollToEnd = true;
                }
              } else {
                //hover on it but no selectable
                this.keyboardNavItemValue = null;
              }
            }
          }
        }
        return React.cloneElement(child, option);
      }
    });
    this.selectOptionNum = selectOptionIndex + 1;

    //If showSelectall, add it into children
    if (this.isSearchAllowed()) {
      //add sub select all
      const tempChildren = [];
      _children.forEach((e, index) => {
        tempChildren.push(e);
        if (isSelectGroupTitle(e) && e.props.showSelectAll) {
          //find subgroup to decide isActive and display text
          const subgroup = this.getSubgroup(index);
          const subgroupValues = subgroup.map(item => item.props.value);
          const { selectOptions } = this.state;
          const subgroupOptions = selectOptions.filter(item => subgroupValues.includes(item.value));
          const activeNum = this.getSubgroupActiveNum(subgroup);
          const { isActive, isDisabled } = this.isSubgroupActive(subgroup);
          const key = getSubselectKey(index);

          const subselectAll = (
            <SelectOption
              className="a-select-subselect-all"
              key={key}
              value={key}
              multiple={multiple}
              keyboardHighlight={e.props.keyboardHighlight}
              isActive={isActive}
              disabled={isDisabled}
            >
              {this.props.customSelectAllStringOnList || SELECT_ALL_STRING}{" "}
              <span className="a-select-all-select-count"> ({activeNum}) </span>
            </SelectOption>
          );
          tempChildren.push(subselectAll);
        }
      });
      _children = tempChildren;
    }
    return _children;
  }

  isSubgroupActive(subgroup) {
    const { selectOptions } = this.state;
    let isActive = false;
    let isDisabled = false;
    const subgroupValues = subgroup.map(item => item.props.value);
    const subgroupOptions = selectOptions.filter(item => subgroupValues.includes(item.value));

    isActive = subgroupOptions.filter(item => (!item.isDisabled) && (!item.isActive)).length === 0
    const disabledNoActiveOptions = subgroupOptions.filter(item => item.isDisabled && (!item.isActive));

    if (disabledNoActiveOptions.length === subgroupOptions.length) {
      isActive = false;
    }

    if (subgroupOptions.filter(item => item.isDisabled).length === subgroupOptions.length) {
      isDisabled = true;
    }

    return { isActive, isDisabled };
  }

  getSubgroupActiveNum(subgroup) {
    let activeNum = 0;
    subgroup.forEach(item => {
      if (
        this.props.multiple &&
        this.state.value &&
        this.state.value.includes(item.props.value)
      ) {
        activeNum++;
      }
    });
    return activeNum;
  }

  getSubgroup(index) {
    const result = [];
    const arr = this.childrenArray;

    for (let ii = index + 1; ii < arr.length; ii++) {
      let e = arr[ii];
      if (e) {
        if (isSelectGroupTitle(e)) {
          break;
        } else if (isSelectOption(e)) {
          result.push(e);
        }
      }
    }
    return result;
  }

  hasSelectGroupTitle() {
    return this.childrenArray.some(e => {
      return isSelectGroupTitle(e);
    });
  }

  getTagsForToggle() {
    const { showTagsOnToggle, disabled } = this.props;

    if (!showTagsOnToggle) {
      return null;
    }
    const that = this;
    const tags = this.getActiveItems(this.state.selectOptions).map(e => {
      const onClose = event => {
        that.onSelect(event, e.value);
        //disable toggle
        this.toggleMenu(true);
      };

      const comp = this.childrenArray.filter(child => {
        return isSelectOption(child) && child.props.value === e.value;
      })[0];

      const tagStyle = Object.assign({}, comp.props.tagStyle);
      tagStyle.marginRight = "0.3125rem";

      const tagHeader = comp.props.tagHeader;
      const text = comp.props.tagText || e.text;
      const size = that.props.size === "large" ? "md" : "sm";

      return (
        <Tag
          disabled={disabled}
          className="a-select-toggle-inside-tag"
          closeable
          key={e.text}
          size={size}
          style={tagStyle}
          onClose={onClose}
        >
          {" "}
          {tagHeader} {text}
        </Tag>
      );
    });
    return tags;
  }

  renderToggle() {
    const {
      showSearchOnToggle,
      tabs,
      placeholder,
      toggleMode,
      showTagsOnToggle,
      disabled
    } = this.props;

    const isSearch = toggleMode === "search";
    const isSelect = toggleMode === "select";
    const showingPlaceholder = placeholder === this.state.text;

    const tags = this.getTagsForToggle();

    let need_opacity;
    if (showTagsOnToggle) {
      //tags === [], it shows placeholder
      need_opacity = !this.state.visible && tags.length === 0;
    } else {
      need_opacity = !this.state.visible && showingPlaceholder;
    }

    const icon_need_opacity = !this.state.visible;

    const iconCn = ClassNames("select-toggle-icon appkiticon", {
      "arrow-icon icon-up-chevron-fill": isSelect && this.state.visible,
      "arrow-icon icon-down-chevron-fill": isSelect && !this.state.visible,
      "icon-search-outline": isSearch,
      "apply-opacity-in-closed-toggle": icon_need_opacity
    });

    const icon = <span className={iconCn} />;

    const selectToggleCn = ClassNames("select-toggle", {
      "disable-outline": disabled
    });

    if (
      showSearchOnToggle &&
      !showTagsOnToggle &&
      !tabs &&
      this.state.visible
    ) {
      const searchInput = (
        <input
          value={this.state.searchWord || ""}
          autoFocus
          className="select-toggle-input"
          onChange={this.onSearch.bind(this)}
        />
      );

      return (
        <div className={selectToggleCn}>
          {isSearch && icon}
          {searchInput}
        </div>
      );
    } else {
      let cn = ClassNames("select-toggle-text-wrapper", {
        "select-toggle-place-holder": showingPlaceholder,
        "apply-opacity-in-closed-toggle": need_opacity
      });

      let contentDiv;
      if (showTagsOnToggle) {
        if (tags.length === 0) {
          cn = "select-toggle-text-wrapper select-toggle-place-holder";
        } else if (tags.length > 0 && this.state.visible) {
          cn += " flex-end";
        }

        const searchInput = (
          <input
            autoFocus
            className="select-toggle-input select-toggle-input-for-tags"
            onClick={e => cancelPropagation(e)}
            onChange={this.onSearch.bind(this)}
          />
        );

        let tempTags;
        let tempInput;
        if (this.state.visible) {
          if (tags.length > 0) {
            tempTags = tags;
          }
          tempInput = searchInput;
        } else {
          if (tags.length > 0) {
            tempTags = tags;
          } else {
            tempTags = placeholder;
          }
        }

        const tagDivCn = ClassNames("a-select-toggle-tag-text", {
          "apply-opacity-in-closed-toggle": need_opacity
        });

        contentDiv = (
          <div className={cn}>
            <div className={tagDivCn}> {tempTags} </div>
            {tempInput}
          </div>
        );
      } else {
        contentDiv = (
          <div className={cn} id={this.uuid}>
            {this.state.text}
          </div>
        );
      }

      return (
        <div className={selectToggleCn} onClick={this.toggleMenu}>
          {icon}
          {contentDiv}
        </div>
      );
    }
  }

  renderSelectList = () => {
    const {
      tabs,
      showSearchOnList,
      multiple,
      size,
      className,
      getPopupContainer
    } = this.props;
    const theme = getCurrentTheme();
    const level = getCurrentLevel();
    const cn = ClassNames({ "a-theme-container": theme });
    const customerCn = ClassNames(className);
    if (this.props.getPopupContainer) {
      let popupContainer = getPopupContainer();
      if (popupContainer) {
        this.el = popupContainer;
      }
    }
    return ReactDOM.createPortal(
      <div className={cn} theme={theme} level={level}>
        <div className={customerCn}>
          <SelectList
            ref={node => (this.selectList = node)}
            onSelect={this.onSelect.bind(this)}
            showSearchOnList={!tabs && showSearchOnList}
            selectAll={this.getListSelectAll()}
            onSearch={this.onSearch.bind(this)}
            searchWord={this.state.searchWord}
            hasSelectGroupTitle={this.hasSelectGroupTitle()}
            visible={this.state.visible}
            style={{ width: this.toggleWidth }}
            multiple={multiple}
            handleClickOutside={e => this.handleClickOutside(e)}
            size={size}
          >
            {this.renderContent()}
          </SelectList>
        </div>
      </div>,
      this.el
    );
  };

  render() {
    const {
      size,
      className,
      multiple,
      showSelectAll,
      children,
      disabled,
      showSearchOnList,
      showSearchOnToggle,
      value,
      defaultValue,
      tabsKind,
      tabs,
      dynamicUI,
      onSelect,
      showTagsOnToggle,
      toggleMode,
      maxListHeight,
      onClose,
      customSelectAllStringOnList,
      customSelectAllStringOnToggle,
      customSelectedCountStringGenerator,
      dropdownSelectWidth,
      getPopupContainer,
      dropdownRenderMode,
      dropdownAlwaysDown,
      ...others
    } = this.props;
    const selectWrapper = ClassNames("a-dropdown", className, {
      "multiple-select": multiple,
      "select-all": multiple && showSelectAll,
      [`a-dropdown-${size}`]: size,
      disabled: disabled,
      open: this.state.visible
    });

    const wrapperCn = ClassNames("a-select-wrapper", {
      open: this.state.visible
    });
    const UsingPortal = dropdownRenderMode === "portal";

    return (
      <div
        className={wrapperCn}
        tabIndex={disabled ? -1 : 0}
        ref={node => (this.selectWrapperDiv = node)}
        onKeyDown={this.onKeyDown.bind(this)}
      >
        <div
          className={selectWrapper}
          ref={node => (this.selectToggleDiv = node)}
          {...others}
        >
          {this.renderToggle()}
          {UsingPortal ? (
            this.el && this.renderSelectList()
          ) : (
            <SelectList
              ref={node => (this.selectList = node)}
              onSelect={this.onSelect.bind(this)}
              showSearchOnList={!tabs && showSearchOnList}
              selectAll={this.getListSelectAll()}
              onSearch={this.onSearch.bind(this)}
              searchWord={this.state.searchWord}
              hasSelectGroupTitle={this.hasSelectGroupTitle()}
              handleClickOutside={e => this.handleClickOutside(e)}
              multiple={multiple}
            >
              {this.renderContent()}
            </SelectList>
          )}
        </div>
      </div>
    );
  }
}

Select.propTypes = {
  placeholder: PropTypes.string,
  customSelectAllStringOnToggle: PropTypes.string,
  customSelectAllStringOnList: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(["default", "large"]),
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),
  multiple: PropTypes.bool,
  showSearchOnToggle: PropTypes.bool,
  showSearchOnList: PropTypes.bool,
  showSelectAll: PropTypes.bool,
  maxListHeight: PropTypes.number,
  tabs: PropTypes.array,
  tabsKind: PropTypes.string,
  toggleMode: PropTypes.oneOf(["select", "search"]),
  showTagsOnToggle: PropTypes.bool,
  onClose: PropTypes.func,
  dynamicUI: PropTypes.bool,
  customSelectedCountStringGenerator: PropTypes.func,
  dropdownSelectWidth: PropTypes.number,
  getPopupContainer: PropTypes.func,
  dropdownRenderMode: PropTypes.string,
  dropdownAlwaysDown: PropTypes.bool
};

export default Select;
