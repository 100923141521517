//@flow
import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import type { Node } from "react";
import classnames from "classnames";
import Popper from "popper.js";
import ClickOutside from "react-click-outside";
import type { CommonType } from "appkit-react-utils/commonType";
import { isWithin, matches } from "../utils/domHelper";
import KeyCode from "appkit-react-utils/keyCode";
import { getCurrentTheme, getCurrentLevel } from "../index";
export type TooltipProps = {
  /**
   * The content of Tooltip
   */
  content: Node,
  /**
   * The placement of tooltip, 12 directions
   */
  placement?:
    | "auto-start"
    | "auto"
    | "auto-end"
    | "top-start"
    | "top"
    | "top-end"
    | "right-start"
    | "right"
    | "right-end"
    | "bottom-end"
    | "bottom"
    | "bottom-start"
    | "left-end"
    | "left"
    | "left-start",
  position?: {},
  /**
   * The event trigger to show tooltip
   */
  trigger?: "hover" | "click" | "focus",
  /**
   * whether tooltip should be closed if reference element clicked
   */
  clickToClose?: boolean,
  /**
   * The delay time when mouse enter
   */
  mouseEnterDelay?: number,
  /**
   * The delay time when mouse leave
   */
  mouseLeaveDelay?: number,

  hideAfterMouseLeave?: boolean,

  disabled?: boolean,
  // visible?: boolean,
  children: Node,
  /**
   * The tooltip card theme
   */
  tooltipTheme?: "dark" | "light",
  /**
   * Customize reference element style
   */
  refStyle?: {},
  /**
   * Customize reference element classes
   */
  refClassName?: string,
  /**
   * To show or hide the arrow
   */
  hasArrow?: boolean,
  /**
   * The callback function when tooltip show
   */
  onShow?: Function,
  /**
   * The callback function when tooltip hide
   */
  onHide?: Function,
  offset: number | string,
  /**
   * The id of the dom element,which the tooltip content would be appended to. The container would be body if no containerid is assigned.
   */
  containerid?: string,
  showClose?: boolean
};

type TooltipState = {
  showTooltip: boolean
};

type TimeoutID = number;

class Tooltip extends Component<TooltipProps & CommonType, TooltipState> {
  state: TooltipState;
  timeout: TimeoutID;
  hidetipID: TimeoutID;
  popperJS: any;
  wrapper: any;
  tooltip: any;
  popperNode: HTMLDivElement;

  static defaultProps = {
    hideAfterMouseLeave: true
  };

  constructor(props: Object) {
    super(props);
    this.state = {
      showTooltip: false
    };
  }

  componentDidMount() {
    const { disabled, clickToClose = false, containerid } = this.props;
    this.container = document.getElementById(containerid) || document.body;
    if (this.props.trigger === "click") {
      this.wrapper.addEventListener("click", () => {
        if (!disabled && !this.state.showTooltip) {
          this.showTooltip();
          return;
        }
 
        if (clickToClose && !disabled && this.state.showTooltip) {
          this.hideTooltip();
        }
      });
    }

  
    //react bug https://github.com/facebook/react/issues/10396
    this.containerNode.addEventListener("mouseleave", e => {
      this.handleMouseLeave();
    });
  }

  _checkPlacement(
    currentPlacement: string,
    placements: Array<string>
  ): boolean {
    if (placements && placements.length > 0) {
      if (Array.from(placements).indexOf(currentPlacement) != -1) {
        return true;
      }
    }
    return false;
  }

  handleTrigger(eventType: string): void {
    const { trigger = "hover" } = this.props;
    if (trigger === eventType) {
      this.showTooltip();
    }
  }

  handleClickOutside(e) {
    //clicking on tooltip content will be ignore
    const selector = ".a-tooltip-inner";

    if (e) {
      //no e during code coverage test
      if (matches(e.target, selector) || isWithin(e.target, selector)) {
        return;
      }
    }

    this.hideTooltip();
  }

  handleMouseLeave(): void {
    const { trigger = "hover", hideAfterMouseLeave } = this.props;
    if (trigger === "hover" && hideAfterMouseLeave) {
      this.hideTooltip();
    }
  }

  getOppositePosition(position: string) {
    const table = {
      left: "right",
      right: "left",
      top: "bottom",
      bottom: "top"
    };
    return table[position] || null;
  }

  updateTooltipStyle = (tooltip, position) => {
    Object.keys(position).forEach(k => {
      let oppositePos = this.getOppositePosition(k);
      if (oppositePos) {
        tooltip.style.removeProperty("transform");
        tooltip.style.setProperty(k, position[k]);
        tooltip.style.removeProperty(oppositePos);
      }
    });
  };

  onKeyPressHandler() {}

  onKeyDown(e: SyntheticKeyboardEvent<*>) {
    if (this.props.trigger !== "click") return;
    if (e.keyCode === KeyCode.ENTER) {
      if (!this.state.showTooltip) {
        this.showTooltip();
      } else {
        this.hideTooltip();
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.hidetipID);
  }

  showTooltip(): void {
    const {
      placement,
      onShow,
      position = {},
      offset = null,
      disabled = false,
      containerid,
      trigger
    } = this.props;
    if (disabled) {
      return;
    }

    this.popperNode = document.createElement("div");
    let container = this.container;
    if (containerid) {
      container = document.getElementById(containerid);
      this.container = container;
    }
        
    container && container.appendChild(this.popperNode);
    
    let that = this;
    const mouseEnterDelay = trigger === 'click' ? 0 : this.props.mouseEnterDelay;

    this.timeout = setTimeout(() => {
      this.setState(
        {
          showTooltip: true
        },
        () => {
          const tooltip = that.tooltip;
          if (!tooltip) {
            return;
          }


          const wrapper = that.wrapper;
          let targetChildren = wrapper ? wrapper.children : [];
          let target =
            targetChildren.length === 1 ? targetChildren[0] : wrapper;
          this.popperJS = new Popper(target, tooltip, {
            placement: that.props.placement ? that.props.placement : "bottom",
            modifiers: Object.assign(
              {},
              {
                offset: {
                  enable: true,
                  offset: offset
                }
              }
            ),
            onCreate: data => {
              let _tooltip = this.tooltip;
              if (!this._checkPlacement(data.placement, _tooltip.classList)) {
                // IE 11 browser compatible issue: DOMTokenList.replace is not supported on IE 11
                _tooltip.className = _tooltip.className.replace(
                  placement,
                  data.placement
                );
              }
              this.updateTooltipStyle(_tooltip, position);

              this.popperJS.scheduleUpdate();
            },
            onUpdate: data => {
              let _tooltip = this.tooltip;
              if (_tooltip && _tooltip.classList) {
                let classList = this.tooltip.classList;
                this.updateTooltipStyle(_tooltip, position);
                if (!this._checkPlacement(data.placement, classList)) {
                  // IE 11 browser compatible issue: DOMTokenList.replace is not supported on IE 11
                  _tooltip.className = _tooltip.className.replace(
                    classList[classList.length - 1],
                    data.placement
                  );
                }
              }
            }
          });
          if (onShow) {
            onShow();
          }
        }
      );
    }, mouseEnterDelay);
  }

  hideTooltip() {

    if (!this.popperNode) return;

    const { onHide, disabled, trigger } = this.props;
    if (disabled) {
      return;
    }

    const mouseLeaveDelay = trigger === 'click' ? 0 : this.props.mouseLeaveDelay;

    clearTimeout(this.timeout);
    if (this.state.showTooltip) {
      this.hidetipID = setTimeout(() => {
        this.setState({ showTooltip: false });
        this.onAfterLeave();
        if (onHide) onHide();
        let container = this.container;
        container && this.popperNode && container.removeChild(this.popperNode);
        this.popperNode = null;
      }, mouseLeaveDelay);
    }
  }

  onAfterLeave(): void {
    if (this.popperJS) {
      this.popperJS.destroy();
    }
  }

  render() {
    const {
      children,
      content,
      disabled,
      placement = "bottom",
      tooltipTheme = "dark",
      style,
      refStyle,
      className,
      refClassName,
      position,
      hasArrow = true,
      clickToClose,
      trigger,
      onShow,
      onHide,
      offset,
      mouseEnterDelay,
      mouseLeaveDelay,
      hideAfterMouseLeave,
      showClose,
      containerid,
      ...otherProps
    } = this.props;

    const tooltipClasses = classnames(
      className,
      "a-tooltip",
      {
        [`a-${tooltipTheme}-panel`]: tooltipTheme,
        "a-tooltip-show": this.state.showTooltip
      },
      [`${placement}`]
    );
    const refClasses = classnames("a-tooltip-ref", refClassName);
    const level = getCurrentLevel();
    const tooltipInnerClassName = classnames("a-tooltip-inner", level);
    const tooltipArrowClassName = classnames("a-tooltip-arrow", level);
    let tooltipStyle = Object.assign({}, style);
    tooltipStyle.zIndex = 9999;
    return (
      <div className={refClasses} style={refStyle} id={containerid} ref={node => (this.containerNode = node)}>
        <div
          tabIndex="0" // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
          onKeyPress={this.onKeyPressHandler.bind(this)}
          onKeyDown={this.onKeyDown.bind(this)}
          onFocus={this.handleTrigger.bind(this, "focus")}
          onMouseEnter={this.handleTrigger.bind(this, "hover")}
          className="a-tooltip-ref-inner"
          ref={node => (this.inner = node)}
          {...otherProps}
        >
          <span ref={node => (this.wrapper = node)}>{children}</span>
        </div>
        {this.state.showTooltip &&
          !disabled &&
          this.popperNode &&
          ReactDOM.createPortal(
            <div
              ref={node => (this.tooltip = node)}
              className={tooltipClasses}
              style={tooltipStyle}
            >
              {hasArrow && (
                <div
                  className={tooltipArrowClassName}
                  x-arrow={hasArrow ? 1 : 0}
                />
              )}
              <div className={tooltipInnerClassName}>
                {/* {
                  this.props.showClose &&
                  <div className="a-tooltip-close-icon" style={{ textAlign: 'right', cursor: 'pointer' }}>
                    <span className="appkiticon appkiticon icon-close-fill"></span>
                  </div>
                } */}
                {content}
              </div>

            </div>,
            this.popperNode
          )}
      </div>
    );
  }
}

Tooltip.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  placement: PropTypes.oneOf([
    "top",
    "top-start",
    "top-end",
    "bottom",
    "bottom-start",
    "bottom-end",
    "left",
    "left-start",
    "left-end",
    "right",
    "right-start",
    "right-end"
  ]),
  position: PropTypes.object,
  trigger: PropTypes.string,
  clickToClose: PropTypes.bool,
  mouseEnterDelay: PropTypes.number,
  mouseLeaveDelay: PropTypes.number,
  hideAfterMouseLeave: PropTypes.bool,
  tooltipTheme: PropTypes.string,
  refStyle: PropTypes.object,
  refClassName: PropTypes.string,
  // visible: PropTypes.bool,
  disabled: PropTypes.bool,
  onShow: PropTypes.func,
  onHide: PropTypes.func,
  showClose: PropTypes.bool
};

export default ClickOutside(Tooltip);
