//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import type { Node } from 'react';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';

type StepsProps = {
  currentStep?: number,
  showTooltip?: boolean,
  children?: Node
};

type StepsState = {};

class Steps extends Component<StepsProps & CommonType, StepsState> {
  static defaultProps = {
    kind : 'steps'
  }

  getStatus(index: number) {
    let status = '';
    const { currentStep = 1 } = this.props;
    if (currentStep > index) {
      status = 'complete';
    } else if (currentStep === index) {
      status = 'current';
    }
    return status;
  }

  renderChildren() {
    const {
      kind,
      children,
      showTooltip = false
    } = this.props;
    return React.Children.map(children, (child, index) => {
      const status = this.getStatus(index + 1);
      const isLastChild = index === React.Children.count(children) - 1;
      return React.cloneElement(child, {
        kind,
        status,
        showTooltip,
        isLastChild
      });
    });
  }

  render() {
    const {
      kind,
      style,
      className,
      showTooltip,
      currentStep,
      children,
      ...otherProps
    } = this.props;
    const stepsClasses = ClassNames("a-steps", {
      'a-steps-timeline': kind === 'timeline',
      'a-steps-horizontal step-tracker--center ': kind === 'steps'
    });
    return (
      <ol style={style} className={stepsClasses} {...otherProps}>
        {this.renderChildren()}
      </ol>
    );
  }
}

Steps.propTypes = {
  kind: PropTypes.oneOf(["timeline", "steps"]),
  currentStep: PropTypes.number,
  showTooltip: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string
};

Steps.displayName = 'Steps';

export default Steps;
