//@flow
import React, { Component } from 'react';
import type { Node } from 'react';
import type { CommonType } from 'appkit-react-utils/commonType';
import { Select, SelectOption } from '../Select';

type PaginationOptionsProps = {
  /**
   * fields : Paginator with field and buttons
   * numeric : Pagination with numbers
   * carousel : Carousel Paginator
   * @type {('default' | 'simple' | 'numeric' | 'carousel')}
   */
  kind?: 'fields' | 'numeric' | 'carousel',
  /**
   * a callback function, executed when the page number is changed
   *
   * @type {Function}
   */
  onPageChange?: Function,
  pageSizeOptions: Array<*>,
  onPageSizeChange?: Function,
  pageSize?: number,
  current?: number,
  total?: number,
  text?: any,
  pageNums?: number,
  showTextWithOnlyOneOption?: boolean, // this Will avaliable when with one option
  children?: Node
};

type PaginationOptionsState = {
  value: number
};

class PaginationOptions extends Component<
  PaginationOptionsProps & CommonType,
  PaginationOptionsState
> {
  constructor(props: Object) {
    super(props);
  }
  // componentWillReceiveProps(nextProps: Object) {
  //   const { pageSize } = nextProps;
  //   console.log(nextProps);
  //   // this.setState({ value: current });
  // }

  onSelect = (v: any) => {
    const { onPageSizeChange } = this.props;
    onPageSizeChange && onPageSizeChange(v);
  };

  render() {
    const {
      pageSizeOptions,
      pageSize,
      current,
      total,
      showTextWithOnlyOneOption
    } = this.props;
    const defaultValue = pageSize ? pageSize : Number(pageSizeOptions[0]);
    const startItem: number = total > 0 ? (Number(current) - 1) * Number(pageSize) + 1 : 0;
    const endItem: number = total > 0 ? Number(current) * Number(pageSize) : 0;

    const _disabledSelect =
      pageSizeOptions.length === 1 && showTextWithOnlyOneOption;

    return (
      <div className="pagination-content pagination-options">
        <Select
          kind="text"
          size="default"
          placeholder="Choose one"
          className="pageSizeChanger"
          onSelect={this.onSelect}
          disabled={_disabledSelect}
          defaultValue={defaultValue}
        >
          {pageSizeOptions.map((option: any, i: number) => {
            const value: number = Number(option);
            return (
              <SelectOption key={i} value={value}>
                {`${value} / page`}
              </SelectOption>
            );
          })}
        </Select>
        <span className="show-total-text">
          {startItem} - {endItem > Number(total) ? total : endItem} of {total}{' '}
          items
        </span>
      </div>
    );
  }
}

PaginationOptions.displayName = 'PaginationOptions';

export default PaginationOptions;
