//@flow
import * as React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';
import calcTextareaHeight from './calcTextareaHeight'



type Props = {
  defaultValue?: string,
  value?: string,
  // customize input styling
  label?: React.Element<any>,
  textBoxSize?: 'lg' | 'sm',
  // optional Input addons
  action?: React.Element<any>,
  // event hooks
  onChange?: Function,
  onFocus?: Function,
  onBlur?: Function,
  onKeyDown?: Function,
};

export default class TextArea extends React.Component<Props & CommonType> {

  constructor(props) {
    super(props)

    this.state = {
      textareaStyle: { resize: props.resize || 'none', height: "" }
    }
  }

  componentDidMount() {
    this.resizeTextarea();
  }

  resizeTextarea = () => {
    const { autosize } = this.props;

    if (!autosize) {
      return;
    }

    const minRows = autosize.minRows;
    const maxRows = autosize.maxRows;
    const textareaCalcStyle = calcTextareaHeight(this.refs.textarea, minRows, maxRows);

    this.setState({
      textareaStyle: Object.assign({}, this.state.textareaStyle, textareaCalcStyle)
    });
  }

  handleChange = (e: SyntheticInputEvent<*>) => {
    const { onChange } = this.props;
    onChange && onChange(e.target.value, e);

    this.resizeTextarea();
  };

  handleFocus = (e: SyntheticFocusEvent<*>) => {
    const { onFocus } = this.props;
    if (onFocus) onFocus(e);
  };

  handleBlur = (e: SyntheticEvent<*>) => {
    const { onBlur } = this.props;
    if (onBlur) onBlur(e);
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<*>) => {
    const { onKeyDown } = this.props;
    onKeyDown && onKeyDown(e);
  };

  render() {
    const {
      defaultValue,
      value,
      label,
      textBoxSize = 'sm',
      resize,
      action,
      onChange,
      onBlur,
      onFocus,
      onKeyDown,
      className,
      style,
      autosize,
      ...otherProps
    } = this.props;

    const textareaPrefix = 'a-textarea';
    const textareaWrapperClasses = ClassNames(className, {
      [`${textareaPrefix}-${textBoxSize}`]: textBoxSize,
    });

    return (
      <div className={textareaWrapperClasses} style={style}>
        {label && <label className='a-form-label'>{label}</label>}
        <div className='a-textarea-compound'>
          <textarea
            ref="textarea"
            className={textareaPrefix}
            defaultValue={defaultValue}
            value={value}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onKeyDown={this.handleKeyDown}
            style={this.state.textareaStyle}
            {...otherProps}
          />
        </div>
        { action && <div className='a-textarea-action'>{action}</div> }
      </div>
    )
  }
}

TextArea.propTypes = {
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.node,
  // customize input stying
  textBoxSize: PropTypes.string,
  // event hooks
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  // optional Input addons
  action: PropTypes.any,
  autosize: PropTypes.oneOfType([ PropTypes.bool, PropTypes.object ]),
};
