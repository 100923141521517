export function matches(e, selector) {
  //https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
  e.matches = e.matches || e.msMatchesSelector || e.webkitMatchesSelector;
  if (!e.matches) {
    return false;
  }

  return e.matches(selector);
}


export function isWithin(node, selector) {
  if (!node || !selector) {
    return null;
  }

  let parent = node.parentNode;
  while (parent) {
    if (matches(parent, selector)) {
      return parent;
    }
    parent = parent.parentNode;
  }
  return null;
}
