//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import type { Node } from 'react';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';
import { Badge } from '../Badge';
import KODIV from '../utils/KeyboardHoverOnlyDiv';


const STATUS = ['dark', 'info', 'success', 'warning', 'error'];

type AlertProps = {
  children?: Node,
  status?: 'success' | 'warning' | 'info' | 'dark' | 'error',
  icon?: Node,
  showDefaultIcon: boolean,
  closeable?: boolean,
  message?: Node,
  onClose?: Function,
  duration?: number,
  oneLineDisplay: boolean,
  willUnmount?: Function
};

type AlertState = {
  visiable: boolean,
  status: string
};

class Alert extends Component<AlertProps & CommonType, AlertState> {
  state: AlertState;
  timer: number;
  constructor(props: Object) {
    super(props);
    this.timer = 0;
    this.state = {
      visiable: true
    };
  }

  static defaultProps = {
    duration: 0,
    status: 'success',
    oneLineDisplay: true,
    showDefaultIcon: false
  };

  //----------TIMER LOGIC STARTS  HERE
  startTimer() {
    if (this.props.closeable !== true && this.props.duration) {
      this.timer = setTimeout(() => {
        this.onClose();
      }, this.props.duration);
    }
  }

  stopTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  componentDidMount() {
    this.startTimer();
  }

  componentDidUpdate() {
    this.stopTimer();
    this.startTimer();
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  onClose = () => {
    const { onClose, willUnmount } = this.props;
    this.stopTimer();
    this.setState({
      visiable: false
    });
    this.forceUpdate(() => {
      willUnmount && willUnmount();
    });
    onClose && onClose();
  };
  //----------TIMER LOGIC ENDS  HERE
  renderBadge() {
    const {showDefaultIcon, icon, status } = this.props;

    if (icon) {
      return (<div className="a-alert-icon-wrapper">{icon}</div>);
    } else if (showDefaultIcon) {
      if (status === "dark") {
         return ( <div className="a-alert-icon-wrapper"><span className="a-alert-icon appkiticon icon-help-question-fill"></span></div>);
      } else if(status === "info"){
         return ( <div className="a-alert-icon-wrapper"><span className="a-alert-icon appkiticon icon-information-fill"></span></div>);
      } else if(status === "success"){
        return ( <div className="a-alert-icon-wrapper"><span className="a-alert-icon appkiticon icon-confirmed-fill"></span></div>);
      } else if(status === "warning"){
         return ( <div className="a-alert-icon-wrapper"><span className="a-alert-icon appkiticon icon-alert-fill"></span></div>);
      } else if(status === "error"){
         return ( <div className="a-alert-icon-wrapper"><span className="a-alert-icon appkiticon  icon-alert-fill a-icon-error"></span></div>);
      } else {
        //unsupport
        return ( <div className="a-alert-icon-wrapper"></div>);
      }
    } else {
      return (<Badge type={status}>{status && status.toUpperCase()}</Badge>);
    }
  }

  onEnterKeydown(e){
    if(e.key === "Enter"){
      this.onClose();
    }
  }

  renderCountDownTimerOrCloseButton() {
    if (this.props.closeable) {
      return  (
        <KODIV className="a-alert-close" onClick={this.onClose} onKeyDown={this.onEnterKeydown.bind(this)}>
          <span className="appkiticon icon-close-fill" ></span>
        </KODIV>
      );
    } else if(this.props.duration > 0) {
      const animationStyle = {
        "animationDuration": (this.props.duration/1000) + "s"
      };
      return (
        <div className="a-countdown-wrapper">
            <svg height="16" width="16">
                <circle style={animationStyle} className="a-circle a-countdown animated" cx="8" cy="8" r="4"></circle>
            </svg>
        </div>);
    } else {
      return undefined;
    }
  }

  renderFooter(){
    return this.props.children && (
      <div className="alert-footer">
        {this.props.children}
      </div>);
  }

  render() {
    const {
      status,
      icon,
      closeable,
      style,
      className,
      message,
      children,
      oneLineDisplay,
      willUnmount,
      showDefaultIcon,
      ...otherProps
    } = this.props;

    const mode = oneLineDisplay?  "one-line" : "mulitiple-line";

    const alertClassName = ClassNames('a-alert', mode, {
      [`a-alert-${status}`]: status,
      ['a-alert-has-icon']: icon || showDefaultIcon
    });

    const wrapperClassName = ClassNames("a-alert-wrapper", mode, className, {
      'hidden': !this.state.visiable
    });

    return (
      <div className={wrapperClassName}>
        <div className={alertClassName} style={style} {...otherProps}>
          {this.renderBadge()}
          <div className="a-alert-text-content">{message}</div>
          {this.renderCountDownTimerOrCloseButton()}
        </div>
        {this.renderFooter()}
      </div>
    );
  }
}

Alert.propTypes = {
  status: PropTypes.oneOf(STATUS),
  icon: PropTypes.any,
  closeable: PropTypes.bool,
  oneLineDisplay: PropTypes.bool,
  message: PropTypes.any,
  duration:PropTypes.number,
  onClose: PropTypes.func,
  showDefaultIcon: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string
};

Alert.displayName = 'Alert';
Alert.supportedStatus = STATUS;

export default Alert;
