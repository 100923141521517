//@flow
import * as WebFont from 'webfontloader';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';
import KeyCode from 'appkit-react-utils/keyCode';

type ButtonGroupProps = {
  /**
   * The size of the Radio Group
   */
  size?: 'sm' | 'md' | 'lg' | 'xl',
  /**
   * The kind of the Radio Group
   */
  kind?: 'primary' | 'negative' | 'gray',
  className?: string,
  style?: Object
};

type ButtonGroupState = {
  indicatorStyle: Object
} & RadioGroupState;

const INIT_LOAD_CLASS = 'a-init-load';
const DATA_VALUE = 'data-value';

class ButtonGroup extends React.Component<
  ButtonGroupProps & CommonType,
  ButtonGroupState
> {
  constructor(props) {
    super(props);
    // isControlled true means it is controlled by user prop
    // isControlled false means it is controlled by component state
    this.isControlled = props.value != null;
    this.state = {
      indicatorStyle: {
        width: 0,
        left: 0
      }
    };
    this.valueToIndex = {};
    if (!this.isControlled) {
      this.state.groupValue = props.defaultValue || '';
    }
    const o = this;
    WebFont.load({
      custom: {
        families: ['PwC Helvetica Neue', 'PwC Helvetica Neue:n2,n3,n4,n5,n7,n9']
      },
      active: function() {
        o.calcIndicatorStyle();
      }
    });
  }

  onChange(evt, value) {
    const { onChange } = this.props;
    this.setState({
      groupValue: value
    });
    onChange && onChange(evt, value);
  }

  componentDidMount() {
    this.getValueToIndex();
    this.timeout = setTimeout(this.calcIndicatorStyle.bind(this));
    this.correctInitCheckedItemStyle();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    this.timeout = null;
  }

  UNSAFE_componentWillReceiveProps() {
    let self = this;
    setTimeout(() => {
      self.calcIndicatorStyle();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // case 1: isControlled false, use this.state.groupValue !== prevState.groupValue condition
    // case 2: isControlled true, use this.props.value !== prevProps.value condition
    if (
      (this.isControlled && this.props.value !== prevProps.value) ||
      (!this.isControlled && this.state.groupValue !== prevState.groupValue)
    ) {
      this.clearInitCheckedItemStyle();
      this.calcIndicatorStyle();
    }
  }

  correctInitCheckedItemStyle() {
    if (!this.radioGroupRef) return;
    const btnGroup = this.radioGroupRef;
    btnGroup.className += ` ${INIT_LOAD_CLASS}`;
  }

  clearInitCheckedItemStyle() {
    if (!this.radioGroupRef) return;
    const btnGroup = this.radioGroupRef;

    // clear .a-init-load class
    if (btnGroup.className.indexOf(INIT_LOAD_CLASS) >= 0) {
      btnGroup.className = btnGroup.className.replace(
        ` ${INIT_LOAD_CLASS}`,
        ''
      );
    }
  }

  getValueToIndex() {
    if (!this.radioGroupRef) return;
    const groupItems = this.radioGroupRef.querySelectorAll(
      '.a-btn-group .a-btn'
    );

    for (let i = 0, len = groupItems.length; i < len; i++) {
      const button = groupItems[i];
      const dataValue = button.getAttribute(DATA_VALUE);
      this.valueToIndex[dataValue] = i;
    }
  }

  calcIndicatorStyle() {
    if (!this.radioGroupRef) return;
    const btnGroup = this.radioGroupRef;
    if (!btnGroup) return;

    const groupItems = btnGroup.querySelectorAll('.a-btn-group .a-btn');
    const currIdx = this.isControlled
      ? this.valueToIndex[this.props.value]
      : this.valueToIndex[this.state.groupValue];
    if (currIdx == null) return;
    const currBtnItem = groupItems[currIdx];
    // do not calculate indicator style if defaultValue and disabled used together.
    // see: https://trello.com/c/G7vHF643/313-buttonthe-hover-effect-is-incorrect-when-combine-defaultvalue-and-disabled-for-button-group
    if (currBtnItem.classList.contains('disabled')) return;
    const currBtnItemBox = currBtnItem.getBoundingClientRect();
    const btnGroupBox = btnGroup.getBoundingClientRect();
    const indicatorStyle = {
      left: currBtnItemBox.left - btnGroupBox.left,
      width: currBtnItemBox.width
    };

    if (currIdx === 0) {
      indicatorStyle.borderRadius = '0.125rem 0 0 0.125rem';
    } else if (currIdx === groupItems.length - 1) {
      indicatorStyle.borderRadius = '0 0.125rem 0.125rem 0';
    }

    this.setState({
      indicatorStyle
    });
  }

  render() {
    const {
      kind = 'primary',
      size = 'sm',
      onChange,
      className,
      style,
      children,
      ...otherProps
    } = this.props;
    const { indicatorStyle } = this.state;
    const btnGroupPrefixCls = 'a-btn-group';
    const classes: string = classnames(btnGroupPrefixCls, className);
    const indicatorClasses: string = classnames('a-indicator', {
      [`a-${kind}`]: true
    });

    return (
      <div
        ref={el => (this.radioGroupRef = el)}
        //onChange={this.onChange.bind(this)}
        className={classes}
        style={style}
        onMouseDown={e => {
          const value = e.target.getAttribute(DATA_VALUE);
          this.onChange(e, value);
        }}
        onKeyDown={e => {
          if (e.keyCode === KeyCode.ENTER) {
            const value = e.target.getAttribute(DATA_VALUE);
            this.onChange(e, value);
          }
        }}
        {...otherProps}
      >
        <div className={indicatorClasses} style={indicatorStyle} />
        {React.Children.map(children, child => {
          if (!React.isValidElement(child)) {
            return null;
          }

          const isActive = this.isControlled
            ? this.props.value === child.props[DATA_VALUE]
            : this.state.groupValue === child.props[DATA_VALUE];
          const { className, style, ...childOthers } = child.props;
          return React.cloneElement(child, {
            size,
            kind,
            negative: kind === 'negative',
            gray: kind === 'gray',
            className: classnames(className, {
              'a-btn-selected': isActive
            }),
            style,
            ...childOthers
          });
        })}
      </div>
    );
  }
}

ButtonGroup.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  kind: PropTypes.oneOf(['primary', 'negative', 'gray']),
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};

export default ButtonGroup;
