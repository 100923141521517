//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';
import KeyCode from 'appkit-react-utils/keyCode';
import TabBar from './TabBar';

type TabsProps = {
  /**
   * The content of Tabs
   */
  children?: any,
  /**
   * Classes of TabBar wrapper
   */
  tabBarClass?: string,
  /**
   * The value of the currently selected Tab.
   */
  defaultValue?: string,
  /**
   * Size of TabBar
   */
  size?: 'sm' | 'md' | 'lg',
  /**
   * If `true`, TabBar will be applied with secondary styling
   */
  secondary?: boolean,
  /**
   * If `true`, the tabs will be centered. This property is intended for large views.
   */
  centered?: boolean,
  /**
   * Callback executed when tab is changed
   */
  onChange?: Function,
  /**
   * Callback executed during render tabbar. It's used to custome tarbar header.
   * `function(header: Node) => Node` header: The tabbar header node
   */
  renderTabBar?: Function,
  /**
   * Callback executed during render tab content. It's used to custome tab content.
   * `function(content: Node) => Node` content: The tabbar content node
   */
  renderTabContent?: Function,
};

type TabsState = {
  currentValue?: any
};

class Tabs extends Component<TabsProps & CommonType, TabsState> {
  state: TabsState;
  constructor(props: Object) {
    super(props);
    // add isControlled logic here
    if (props.defaultValue) {
      this.state = { currentValue: props.defaultValue };
    } else if (props.children && props.children.length > 0) {
      this.state = { currentValue: props.children[0].props.value };
    }
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.value) {
      return {
        currentValue: nextProps.value
      }
    }
    return {}
  }
  onTabClick: any = (e: SyntheticInputEvent<*>, v: any) => {
    this.setState({
      currentValue: v
    });
    this.props.onChange && this.props.onChange(e, v);
  };
  onKeyDown: any = (e: SyntheticKeyboardEvent<*>, v: any) => {
    if (e.keyCode === KeyCode.ENTER) this.onTabClick(e, v);
  };
  renderHeader: any = () => {
    const {
      children,
      renderTabBar,
      centered = false,
      size,
      tabBarClass,
      secondary = false,
    } = this.props;
    const header: any = React.cloneElement(
      <TabBar key="tabbar" />,
      {
        onTabClick: this.onTabClick,
        onKeyDown: this.onKeyDown,
        value: this.state.currentValue,
        centered,
        size,
        tabBarClass,
        secondary,
      },
      children
    );
    if (renderTabBar) {
      return renderTabBar(header);
    }
    return header;
  };

  renderContent: any = () => {
    const {
      children,
      renderTabContent,
    } = this.props;
    const tabContentClasses: string = classnames(
      'a-tab-content',
    );
    let results: Array<*> = [];

    React.Children.forEach(children, (child, index) => {
      if (!child) {
        return;
      }
      const { value: itemValue } = child.props;
      const key: any = typeof itemValue === 'undefined' ? index : itemValue;
      const active: boolean = this.state.currentValue === key;
      results.push(
        React.cloneElement(child, {
          active,
          key: index,
          ...child.props
        })
      );
    });
    const content: any = (
      <div key="tabcontent" className={tabContentClasses}>
        {results}
      </div>
    );
    if (renderTabContent) {
      return renderTabContent(content);
    }
    return content;
  };

  render() {
    const {
      style,
      className,
      tabBarClass,
      children,
      onChange,
      defaultValue,
      centered,
      renderTabBar,
      renderTabContent,
      secondary,
      ...otherProps
    } = this.props;

    const { renderHeader, renderContent } = this;
    const tabswrapperClasses = classnames('a-tabs-wrapper', {
      [className]: className,
    });

    return (
      <div style={style} className={tabswrapperClasses} {...otherProps}>
        {renderHeader()}
        {renderContent()}
      </div>
    );
  }
}

Tabs.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  tabBarClass: PropTypes.string,
  defaultValue: PropTypes.string,
  centered: PropTypes.bool,
  onChange: PropTypes.func,
  renderTabBar: PropTypes.func,
  renderTabContent: PropTypes.func,
  secondary: PropTypes.bool,
};

Tabs.displayName = 'Tabs';

export default Tabs;
