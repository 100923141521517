//@flow
import React, { PureComponent } from 'react';
import ClickOutside from 'react-click-outside';
import keyCode from 'appkit-react-utils/keyCode';
import type { Element, Node } from 'react';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';
import SwitchBase from '../utils/SwitchBase';

type CheckboxProps = {
  /**
   * name of the input
   */
  name?: string,
  /**
   * If `true`, the component is checked.
   */
  checked?: boolean | string,
  /*
   * The default state of our checkbox component.
   */
  defaultChecked?: boolean,
  /**
   * If `true`, the checkbox will be disabled.
   */
  disabled?: boolean,
  /**
   * The icon to display when the component is unchecked.
   */
  uncheckedIcon?: Element<any>,
  /**
   * The icon to display when the component is checked.
   */
  checkedIcon?: Element<any>,

  /**
   * Callback fired when the state is changed.
   */
  onChange?: Function,
  /**
   * The content of checkbox
   */
  children?: Node
} & CommonType;

class Checkbox extends PureComponent<CheckboxProps, {}> {
  constructor(props: CheckboxProps) {
    super(props);

    this.state = {
      hasOutLine: true,
    };
  }

  onMouseDown = () => {
    this.setState({
      hasOutLine: false,
    });
  };

  handleClickOutside = () => {
    this.setState({
      hasOutLine: true
    });
  };

  onKeyDown = evt => {
    if (evt.keyCode === keyCode.TAB) {
      this.setState({
        hasOutLine: true
      });
    }
  };

  render() {
    const { style, className, children, switchTabIndex, ...otherProps } = this.props;
    const classes: string = ClassNames('a-checkbox-wrapper', className, {
      [`a-checkbox-icon-wrapper`]: this.props.uncheckedIcon,
      [`a-checkbox-wrapper-disabled`]: this.props.disabled,
      [`a-checkbox-wrapper-checked`]: this.props.checked,
      [`without-outline`]: !this.state.hasOutLine
    });

    const opt = {};
    if(typeof switchTabIndex === "number") {
      opt["tabIndex"] = switchTabIndex;
    }
    
    return (
      <label
        ref={node => (this.node = node)}
        style={style}
        className={classes}
        onMouseDown={this.onMouseDown}
        onKeyDown={this.onKeyDown}
      >
        <SwitchBase
          type="checkbox"
          defaultCheckedIcon={<span className="appkiticon icon-check-mark-fill" />}
          {...opt}
          {...otherProps}
          ref={node => (this.switchBase = node)}
        />
        {children !== undefined && children !== '' && <span className="a-checkbox-text">{children}</span>}
      </label>
    );
  }
}

export default ClickOutside(Checkbox);
export const NoClickOutsideCheckbox = Checkbox;

