//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';
import Divider from './Divider';

import SortableList from './SortableList';

export type ListProps = {
  /**
   * The content of List
   */
  children?: any,
  /**
   * If `true`, a 1px border is added to the bottom of each list item.
   */
  divider?: boolean,
  /**
   * If `true`, it should be the expansion list
   */
  expansion?: boolean,
  draggable?: boolean,
  dragIcon?: Node,
  /**
   * If `true`, the left and right padding is removed from all list items
   */
  disableAllGutters?: boolean,
  /**
   * If `true`, the border will be removed from the list
   */
  disableBorder?: boolean,
  onStart?: Function,
  onMove?: Function,
  onEnd?: Function
};

type ListState = {};

class List extends Component<ListProps & CommonType, ListState> {
  renderChildren = (divider?: boolean, items: Array<*>): Object => {
    //output items
    let results: Array<*> = [],
      hasSubheader: boolean = false;
    const renderChildren = this.renderChildren;
    items.map((item, index) => {
      if (!item) {
        return;
      }
      if (item instanceof Array) {
        results = [...results, ...renderChildren(divider, item)];
      } else {
        results.push(item);
        /**
         * Add Divider after each listitem except subheader
         */
        if (
          divider &&
          index !== items.length - 1 &&
          (!item.props || !item.props.subHeader) &&
          !items[index + 1].props.subHeader
        ) {
          results.push(<Divider key={`divider-${index}`} />);
        }

        if (item.props && item.props.subHeader) {
          hasSubheader = true;
        }
      }
    });
    return {
      hasSubheader,
      content: results
    };
  };
  render() {
    const {
      style,
      className,
      disableBorder,
      disableAllGutters,
      divider,
      children,
      expansion,
      draggable = false,
      ...otherProps
    } = this.props;

    const { renderChildren } = this;

    const items: Array<*> = children
      ? Array.isArray(children)
        ? children
        : [children]
      : [];

    const childrenInfo = renderChildren(divider, items);

    let classes: string = ClassNames(className, 'a-list', {
      'no-border': disableBorder,
      'with-divider': divider,
      [`disable-all-gutters`]: disableAllGutters,
      [`expansion-list`]: expansion,
      'drag-list': draggable,
      'drag-with-handle': this.props.dragIcon
    });

    if (draggable) {
      const sortableProps = {
        children,
        ...otherProps
      };
      return (
        <SortableList
          {...sortableProps}
          style={style}
          items={items}
          className={classes}
        />
      );
    } else {
      classes = ClassNames(classes, {
        'with-subheader': childrenInfo.hasSubheader
      });
      return (
        <ul style={style} className={classes} {...otherProps}>
          {childrenInfo.content}
        </ul>
      );
    }
  }
}

List.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  disableBorder: PropTypes.bool,
  disableAllGutters: PropTypes.bool,
  divider: PropTypes.bool,
  expansion: PropTypes.bool,
  draggable: PropTypes.bool,
  children: PropTypes.any,
  onStart: PropTypes.func,
  onMove: PropTypes.func,
  onEnd: PropTypes.func
};

List.displayName = 'List';

export default List;
