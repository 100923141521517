function isImageComponent(item) {
  return typeof item === 'object' && item.type === 'img';
}

function isString(item) {
  return typeof item === 'string';
}

export default {
  isImageComponent,
  isString
};
