//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import type { Node } from 'react';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';

type BreadcrumbItemProps = {
  /**
   * The content of BreadcrumbItem
   */
  children?: Node
};

type BreadcrumbItemState = {};

class BreadcrumbItem extends Component<
  BreadcrumbItemProps & CommonType,
  BreadcrumbItemState
> {
  render() {
    const { style, className, children, separator, ...otherProps } = this.props;
    const crumbItemWrapper = ClassNames('a-bread-item', className);
    return (
      <span style={style} className={crumbItemWrapper} {...otherProps}>
        <span className="a-breadcrumb-content">{children}</span>
        <span className="a-breadcrumb-separator">{separator}</span>
      </span>
    );
  }
}

BreadcrumbItem.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string
};

export default BreadcrumbItem;
