export function getCurrentTheme(){
  //!!only one theme for one page
  const container = document && document.getElementsByClassName("a-theme-container");
  if(container && container[0]){
    return container[0].getAttribute("theme") || "";
  }
  return "";
}


export function getCurrentLevel(){
  //!!only one theme for one page
  const container = document && document.getElementsByClassName("a-theme-container");
  if(container && container[0]){
    return container[0].getAttribute("level") || "";
  }
  return "";
}
