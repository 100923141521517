//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';

type AccordionItemProps = {
  itemId: string,
  isActive?: boolean,
  children?: Node,
  onClick: Function
};

type AccordionItemState = {};

class AccordionItem extends Component<
  AccordionItemProps & CommonType,
  AccordionItemState
> {
  render() {
    const { isActive, onClick, itemId, children, style, className } = this.props;
    const classes: string = ClassNames('a-accordion-item-wrapper', className, {
      'is-active': isActive
    });
    const content = React.Children.map(children, child => {
      let element;
      if (child.type.displayName === 'AccordionItemHeader') {
        element = React.cloneElement(child, {
          itemId: itemId,
          onClick: item => onClick(item),
          isActive
        });
      } else if (child.type.displayName === 'AccordionItemBody'){
        element = React.cloneElement(child, {
          isActive
        });
      }
      return element;
    });

    return (
      <div className={classes} style={style}>
        {content}
      </div>
    );
  }
}

AccordionItem.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  itemId: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func
};

AccordionItem.displayName = 'AccordionItem';

export default AccordionItem;
