//@flow
import React from 'react';
import type { Element, Node } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';
import KODIV from '../utils/KeyboardHoverOnlyDiv';


type ButtonProps = {
  /**
   * The kind of the button
   */
  kind?: 'primary' | 'secondary' | 'transparent',
  /**
   * The size of the button
   */
  size?: 'sm' | 'md' | 'lg' | 'xl',
  /**
   * If `true`, the button will be disabled
   */
  disabled?: boolean,
  /**
   * If `true`, the button will be applied with negative style
   */
  negative?: boolean,
  /**
   * if `true`, the button will be applied with gray style
   */
  gray?: boolean,
  /**
   * if `true`, display loading icon
   */
  isLoading?: boolean,
  /**
   * The content of the button
   */
  children?: ?Node
};

const Button = function(props: ButtonProps & CommonType): Element<any> {

  const {
    kind = 'primary',
    size = 'sm',
    disabled = false,
    negative = false,
    gray = false,
    children,
    style,
    isLoading = false,
    isLoadingBlocking = true,
    className,
    onMouseDown,
    onBlur,
    isNoStyleButton,
    ...others
  } = props;

  if(isNoStyleButton){
    return (
      <KODIV
        isButton
        className={classnames("a-no-style-button", className)}
        style={style}
        {...others}
      >
       {children}
      </KODIV>
    );
  }else{

    const classNames: string = classnames('a-btn', `a-btn-${kind}`, `a-btn-${size}`,  className, {
      'a-btn-negative': negative,
      'a-btn-gray': gray,
      'a-btn-no-interaction': isLoading
    });

    return (
      <KODIV
        isButton
        className={classNames}
        disabled={disabled}
        style={style}
        isLoading={isLoading}
        isLoadingBlocking={isLoadingBlocking}
        onMouseDown={onMouseDown}
        onBlur={onBlur}
        {...others}
      >
        {isLoading ? <div className="a-loading-one" /> : children}
      </KODIV>
    );
  }
};

Button.propTypes = {
  kind: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  negative: PropTypes.bool,
  gray: PropTypes.bool,
  isLoading: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  isNoStyleButton: PropTypes.bool
};

export default Button;
