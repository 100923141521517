//@flow
import React from 'react';
import keyCode from 'appkit-react-utils/keyCode';
import type { Element, Node } from 'react';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';
import SwitchBase from '../utils/SwitchBase';

type RadioProps = {
  /**
   * name of the input
   */
  name?: string,
  /**
   * If `true`, the component is checked.
   */
  checked?: boolean | string,
  /*
   * The default state of our checkbox component.
   */
  defaultChecked?: boolean,
  /**
   * The value of the component.
   */
  value?: any,
  /**
   * The icon to display when the component is unchecked.
   */
  uncheckedIcon?: Element<any>,
  /**
   * The icon to display when the component is checked.
   */
  checkedIcon?: Element<any>,
  /**
   * If `true`, the radio will be disabled.
   */
  disabled?: boolean,
  /**
   * Callback fired when the state is changed.
   *
   * @param {object} event The event source of the callback
   * @param {boolean} checked The `checked` value of the switch
   */
  onChange?: Function,
  /**
   * The content of checkbox
   */
  children?: Node,
};

class Radio extends React.Component<RadioProps & CommonType, {}> {
  constructor(props: RadioProps) {
    super(props);
    this.state = {
      hasOutLine: true
    };
  }

  removeOutline = () => {
    this.setState({
      hasOutLine: false
    });
  };

  onBlur = () => {
    this.setState({
      hasOutLine: true
    });
  };

  onKeyDown = evt => {
    if (evt.keyCode === keyCode.TAB) {
      this.setState({
        hasOutLine: true
      });
    }
  };

  render() {
    const {
      style,
      className,
      children,
      disabled,
      ...otherProps
    } = this.props;

    const classes: string = ClassNames('a-radio-wrapper', className, {
      [`a-radio-icon-wrapper`]: this.props.uncheckedIcon,
      [`a-radio-wrapper-disabled`]: disabled,
      [`a-radio-wrapper-checked`]: this.props.checked,
      [`without-outline`]: !this.state.hasOutLine
    });
    return (
      <label
        style={style}
        className={classes}
        onMouseDown={this.removeOutline}
        onKeyDown={this.onKeyDown}
        onBlur={this.onBlur}
      >
        <SwitchBase
          type="radio"
          disabled={disabled}
          {...otherProps}
        />
      {children !== undefined && <span className="a-radio-text" onClick={this.removeOutline}>{children}</span>}
      </label>
    );
  }
}

export default Radio;
