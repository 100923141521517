//@flow
/* istanbul ignore file */
import React, { Component } from 'react';
import type { CommonType } from 'appkit-react-utils/commonType';

export type CommonPaginatorProps = {
  /**
   * current page number
   * @type {number}
   */
  current?: number,
  /**
   * default initial page number
   * @type {number}
   */
  defaultCurrent?: number,
  /**
   * number of items per page
   *
   * @type {number}
   */
  pageSize?: number,
  /**
   * default number of items per page
   *
   * @type {number}
   */
  defaultPageSize?: number,
  /**
   * total number of  items
   *
   * @type {number}
   */
  total?: number,
  /**
   * callback function, executed when the page number is changed
   *
   * @type {Function}
   */
  onChange?: Function,
  /**
   * Callback executed when the page size is changed
   *
   * @type {Function}
   */
  onPageSizeChange?: Function
};

type CommonPaginatorState = {
  current: number,
  pageSize: number
};

/**
 * Exposed Pagination Props will be passed into wrappedcomponent
 */
export type exposedPaginatorProps = {
  prev: Function,
  next: Function,
  hasPrev: Function,
  hasNext: Function,
  setCurrentPage: Function,
  calculatePages: Function,
  changePageSize: Function,
  value: number,
  pageSize: number
};

const defaultPageSizeOptions = [5, 10, 15, 20];

function withPaginator(WrappedComponent: any) {
  return class PrivatePaginator extends Component<
    CommonPaginatorProps & CommonType,
    CommonPaginatorState
  > {
    constructor(props: Object) {
      super(props);
      let {
        defaultCurrent = 1,
        current,
        pageSize,
        defaultPageSize,
        pageSizeOptions = defaultPageSizeOptions
      } = props;
      if (!pageSize) {
        pageSize = defaultPageSize
          ? defaultPageSize
          : Number(pageSizeOptions[0]);
      }
      this.state = {
        current: current ? current : defaultCurrent,
        pageSize
      };
    }

    UNSAFE_componentWillReceiveProps(nextProps: Object) {
      const { current, pageSize } = nextProps;
      let state;
      if (current && current !== this.state.current) {
        state = { current };
      }

      if (pageSize && pageSize !== this.state.pageSize) {
        if(state){
          state = { ...state, pageSize }
        }else{
          state = { pageSize }
        }
      }
      if (state) {
        this.setState(state);
      }
    }

    hasPrev = () => {
      return this.state.current > 1;
    };

    hasNext = () => {
      const pageNums: number = this.calculatePages();
      return this.state.current < Number(pageNums);
    };

    prev = () => {
      if (this.state.current <= 1) {
        return;
      }
      const current: number = this.state.current - 1;
      this.setCurrentPage(current);
    };

    next = () => {
      const pageNums: number = this.calculatePages();
      if (this.state.current >= Number(pageNums)) {
        return;
      }
      const current = this.state.current + 1;
      this.setCurrentPage(current);
    };

    setCurrentPage = (page: any) => {
      const { onChange } = this.props;
      const pageNums: number = this.calculatePages();
      if (page < 1 || page > pageNums) {
        return;
      }
      const { current: previousPage } = this.state;
      if (previousPage !== page) {
        this.setState({
          current: page
        });
        let eventType: string = 'jump';
        if (page - previousPage === 1) {
          eventType = 'next';
        } else if (page - previousPage === -1) {
          eventType = 'prev';
        }
        onChange && onChange(eventType, page);
      }
    };

    calculatePages = (): number => {
      const { total } = this.props;
      const { pageSize } = this.state;
      const pageNums: number = Math.floor((Number(total) - 1) / pageSize) + 1;
      return pageNums === 0 ? 1 : pageNums;
    };

    changePageSize = (pageSize: number) => {
      const { onPageSizeChange } = this.props;
      if (pageSize < 1) {
        return;
      }
      if (pageSize !== this.state.pageSize) {
        this.setState({
          current: 1,
          pageSize
        });
        onPageSizeChange && onPageSizeChange(pageSize);
      }
    };

    render() {
      const {
        next,
        prev,
        hasNext,
        hasPrev,
        calculatePages,
        setCurrentPage,
        changePageSize
      } = this;
      const { current, pageSize } = this.state;
      const { ...otherProps } = this.props;
      const passedProps = {
        ...otherProps,
        next,
        prev,
        calculatePages,
        hasNext,
        hasPrev,
        value: current,
        setCurrentPage,
        changePageSize,
        pageSize
      };

      return <WrappedComponent {...passedProps} />;
    }
  };
}

export default withPaginator;
