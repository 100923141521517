//@flow
import * as React from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import type { CommonType } from 'appkit-react-utils/commonType';
import KODIV from '../utils/KeyboardHoverOnlyDiv';


type FABProps = {
  /**
   * The kind of the Icon Button
   */
  kind?: 'primary' | 'secondary' | 'gray' | 'negative',
  /**
   * The size of the Icon Button
   */
  size?: 'sm' | 'lg',
  /**
   * If `true`, will use disbled floating action button styling.
   */
  disabled?: boolean,
  /**
   * If `true`, will display loading btn
   */
  isLoading?: boolean,
  /**
   * The content of the button
   */
  children?: ?React.Node
};

const FloatingActionButton = function(
  props: FABProps & CommonType
): React.Element<any> {



  const {
    kind = 'primary',
    size = 'lg',
    disabled = false,
    children,
    style,
    className,
    isLoading = false,
    isLoadingBlocking = true,
    onMouseDown,
    onBlur,
    ...other
  } = props;

  let classes;
  classes = ClassNames(className, 'a-btn-floating', `a-btn-floating-${kind}`,  {
    [`a-btn-floating-${size}`]: size !== 'lg',
    'a-btn-no-interaction': isLoading,
  });

  return (
    <KODIV
      isButton
      style={style}
      className={classes}
      disabled={disabled}
      isLoading={isLoading}
      isLoadingBlocking={isLoadingBlocking}
      {...other}
    >
      {isLoading ? <div className='a-loading-one'></div> : children}
    </KODIV>
  );
};

FloatingActionButton.propTypes = {
  kind: PropTypes.oneOf(['primary', 'secondary', 'gray', 'negative']),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string
};

export default FloatingActionButton;
