//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import type { Node } from 'react';
import type { CommonType } from 'appkit-react-utils/commonType';
import BreadcrumbItem from './BreadcrumbItem';

type BreadcrumbProps = {
  /**
   * The separator of content. Like '/'.
   */
  separator?: string | Node,
  /**
   * The content of Breadcrumb
   */
  children?: Node,
  /**
   * To render the breadcrumb items
   * [{link:'/index',value:'index'}]
   */
  items?: []
};

type BreadcrumbState = {};

class Breadcrumb extends Component<
  BreadcrumbProps & CommonType,
  BreadcrumbState
> {
  renderChildren(children: any) {
    let items = this.props.items;
    const { separator = '/' } = this.props;
    if (Array.isArray(items)) {
      let renderItems = [];
      renderItems = items.map(function(item, index) {
        let valueDiv;

        if(typeof item.value !== "object"){
          valueDiv = (<span className="a-breadcrumb-item-text"> {item.value} </span>);
        }else{
          valueDiv = item.value;
        }

        if (item && item.link) {
          return (
            <BreadcrumbItem key={index} separator={separator}>
              <a className="text-underline" href={item.link}>
                {valueDiv}
              </a>
            </BreadcrumbItem>
          );
        } else {
          return (
            <BreadcrumbItem
              separator={separator}
              key={index}>
              {valueDiv}
            </BreadcrumbItem>
          );
        }
      });
      return renderItems;
    } else {
      return React.Children.map(children, (child, idx) => {
        return React.cloneElement(
          child,
          {
            separator,
            key: `breadcrumb-${idx}`
          },
        )
      })
    }
  }

  render() {
    const {
      style,
      className,
      items,
      children,
      separator,
      ...otherProps
    } = this.props;
    const breadcrumbClasses = ClassNames('a-breadcrumbs', className);
    return (
      <div style={style} className={breadcrumbClasses} {...otherProps}>
        {' '}
        {this.renderChildren(children)}{' '}
      </div>
    );
  }
}

Breadcrumb.propTypes = {
  separator: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  style: PropTypes.object,
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object)
};

export default Breadcrumb;
