//@flow
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';

import {SlideDown} from 'react-slidedown'

type Props = {
  children?: React.Element<any>
};

class AccordionItemBody extends PureComponent{
  render(){
    const {
      style,
      className,
      children,
      isActive,
      hasLeftPadding,
      ...others
    } = this.props;

    const classNames = ClassNames('a-accordion-content', className, {
      "text-left-align": hasLeftPadding
    });

    if (isActive) {
      return (<SlideDown transitionOnAppear={false} className='a-accordion-dropdown-slidedown'>
                <div className={classNames} style={style} {...others}>
                  <div className="with-bottom-padding">{children}</div>
                </div>
              </SlideDown>);
    } else {
        return  (<SlideDown transitionOnAppear={false} className='a-accordion-dropdown-slidedown'>
                </SlideDown>);
    }
  }
};

AccordionItemBody.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  isActive: PropTypes.bool,
  hasLeftPadding: PropTypes.bool
};

AccordionItemBody.displayName = 'AccordionItemBody';
export default AccordionItemBody;
