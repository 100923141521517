//@flow
import * as React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';
import {guid} from 'appkit-react-utils/commonFunc'

type Props = {
  defaultValue?: string,
  value?: string,
  readonly?: boolean,
  disabled?: boolean,
  // customize input styling
  label?: React.Element<any>,
  inputBoxSize?: 'lg' | 'sm',
  hasError?: boolean,
  errMsg?: string,
  // optional Input addons
  addonBefore?: React.Element<any>,
  addonAfter?: React.Element<any>,
  prefix?: React.Element<any>,
  suffix?: React.Element<any>,
  // event hooks
  onChange?: Function,
  onFocus?: Function,
  onBlur?: Function,
  onKeyDown?: Function,
  onEnterPress?: Function,
};

export default class Input extends React.Component<Props & CommonType> {
  constructor(props){
    super(props);
    this.state={
      isFocus:false
    }
  }

  handleChange = (e: SyntheticInputEvent<*>) => {
    const { onChange } = this.props;
    onChange && onChange(e.target.value);
  };

  handleFocus = (e: SyntheticFocusEvent<*>) => {
    const { onFocus } = this.props;
    if (onFocus) onFocus(e);
    this.setState({
      isFocus:true
    })
  };

  handleBlur = (e: SyntheticEvent<*>) => {
    const { onBlur } = this.props;
    if (onBlur) onBlur(e);
    this.setState({
      isFocus:false
    })
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<*>) => {
    const { onKeyDown, onEnterPress } = this.props;
    onKeyDown && onKeyDown(e);
    // cope with Enter key action
    if (e.keyCode === 13) {
      onEnterPress && onEnterPress(e.target.value, e);
    }
  };

  render() {
    const {
      defaultValue,
      value,
      label,
      readonly = false,
      disabled = false,
      inputBoxSize = 'sm',
      hasError,
      errMsg,
      addonBefore,
      addonAfter,
      prefix,
      suffix,
      onChange,
      onBlur,
      onFocus,
      onEnterPress,
      onKeyDown,
      className,
      style,
      htmlFor,
      ...otherProps
    } = this.props;

    const inputPrefix = 'a-input';
    const inputWrapperClasses = ClassNames(className, {
      [`a-input-size-${inputBoxSize}`]: inputBoxSize,
    });
    const inputClasses = ClassNames(`a-input-text`, {
      [`a-input-error`]: hasError
    });
    const compoundInputClasses = ClassNames(`a-input-compound`, {
      [`a-input-prefix-container`]: prefix,
      [`a-input-suffix-container`]: suffix
    });

    const prefixCn = ClassNames(`${inputPrefix}-prefix`, `a-input-prefix-${this.state.isFocus?"focus":"unfocus"}`);

    const fieldId=htmlFor||guid();

    return (
      <div className={inputWrapperClasses} style={style}>
        { label && <label htmlFor={fieldId} className='a-form-label'>{label}</label> }
        { addonBefore }
        <div className={compoundInputClasses}>
          { prefix && <div className={prefixCn}>{prefix}</div> }
          <input
            className={inputClasses}
            defaultValue={defaultValue}
            value={value}
            readOnly={readonly}
            disabled={disabled}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onKeyDown={this.handleKeyDown.bind(this)}
            aria-label={fieldId}
            {...otherProps}
          />
          { suffix && <div className={`${inputPrefix}-suffix`}>{suffix}</div> }
        </div>
        { addonAfter }
        { hasError && <p className="a-form-error">{errMsg}</p> }
      </div>
    )
  }
}

Input.propTypes = {
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.node,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  // customize input stying
  inputBoxSize: PropTypes.string,
  hasError: PropTypes.bool,
  errMsg: PropTypes.string,
  // event hooks
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onEnterPress: PropTypes.func,
  // optional Input addons
  addonBefore: PropTypes.any,
  addonAfter: PropTypes.any,
  prefix: PropTypes.any,
  suffix: PropTypes.any,
};
