//@flow
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import type { CommonType } from 'appkit-react-utils/commonType';
import {
  sortableContainer,
  sortableElement,
  arrayMove
} from 'react-sortable-hoc';

const SortableItem = sortableElement(({value, dragIcon}) => {
  return (
    React.cloneElement(
      value,
      {dragIcon}
    )
  );
});

const SortableContainer = sortableContainer(({children, className}) => {
  return <div className={className}>{children}</div>;
});

type SortableListProps = {
  items?: any,
  dragIcon?: any
};

type SortableListState = {
  items?: Array
};

class SortableList extends Component<
  SortableListProps & CommonType,
  SortableListState
> {
  constructor(props) {
    super(props);

    this.state = {
      items: this.props.children
    };
  }

  componentDidMount() {
    this.mynode = ReactDOM.findDOMNode(this.node);
    this.forceUpdate();
  }

  onSortStart = ({index}) => {
    this.props.onStart && this.props.onStart(index);
  };

  onSortMove = (event) => {
    this.props.onMove && this.props.onMove(event);
  };

  onSortEnd = ({oldIndex, newIndex}, e) => {
    this.setState(({items}) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }), () => {
      this.props.onEnd && this.props.onEnd(oldIndex, newIndex, e);
    });
  };

  render() {
    const { items } = this.state;
    const { dragIcon, className } = this.props;
    return (
      <SortableContainer
        useDragHandle={dragIcon != null}
        ref={(n) => this.node = n}
        onSortStart={this.onSortStart}
        onSortMove={this.onSortMove}
        onSortEnd={this.onSortEnd}
        helperContainer={this.mynode}
        helperClass="help"
        className={className}
      >
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            dragIcon={dragIcon}
          />
        ))}
      </SortableContainer>
    );
  }
}

SortableList.displayName = 'SortableList';

export default SortableList;
