//@flow
import React from 'react';
import type { Element } from 'react';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';

type SwitchBaseProps = {
  /**
   * is checkbox or radio
   */
  type: 'checkbox' | 'radio',
  /**
   * name of the input
   */
  name?: string,
  /**
   * If `true`, the component is checked.
   */
  checked?: boolean | string,
  /**
   * If `true`, the component is diabled.
   */
  disabled?: boolean,
  /*
   * The default state of our checkbox component.
   */
  defaultChecked?: boolean,
  /**
   * The value of the component.
   */
  value?: string,
  /**
   * The icon to display when the component is unchecked.
   */
  uncheckedIcon?: Element<any>,
  defaultCheckedIcon?: Element<any>,
  /**
   * The icon to display when the component is checked.
   */
  checkedIcon?: Element<any>,
  /**
   * Callback fired when the state is changed.
   *
   * @param {object} event The event source of the callback
   * @param {boolean} checked The `checked` value of the switch
   */
  onChange?: Function
};

type SwitchBaseState = {
  checked: boolean
};

/**
 * This is the internal component used by checkbox and radio
 */
class SwitchBase extends React.Component<
  SwitchBaseProps & CommonType,
  SwitchBaseState
> {
  useInternalState: boolean;
  constructor(props: Object) {
    super(props);

    this.isControlled = props.checked != null;
    this.state = {};
    if (!this.isControlled) {
      // not controlled, use internal state
      this.state.checked = props.defaultChecked !== undefined ? props.defaultChecked : false;
    }
  }

  onChange = (e: SyntheticInputEvent<*>) => {
    if (this.props.disabled) return;
    const checked: boolean = e.target.checked;
    if (!this.isControlled) {
      this.setState({ checked })
    }
    if (this.props.onChange) {
      this.props.onChange(e, e.target.value, checked);
    }
  };

  renderCheckbox = (
    type?: String,
    checked?: Boolean,
    checkedIcon?: Element<any>,
    uncheckedIcon?: Element<any>,
    defaultCheckedIcon?: Element<any>
  ) => {
    if (checked && React.isValidElement(checkedIcon)) {
      return checkedIcon;
    }
    if (!checked && React.isValidElement(uncheckedIcon)) {
      return uncheckedIcon;
    }
    if (checked && type === 'checkbox') {
      return defaultCheckedIcon;
    }
    return null;
  };

  render() {
    const {
      name,
      type = 'checkbox',
      style,
      className,
      checkedIcon,
      uncheckedIcon,
      defaultCheckedIcon,
      value,
      checked: checkedProp,
      disabled,
      defaultChecked,
      onChange,
      ...others
    } = this.props;

    const checked = this.isControlled ? checkedProp : this.state.checked;
    const { renderCheckbox } = this;
    const classes: string = ClassNames(className, `a-${type}`, {
      [`a-${type}-checked`]: checked,
      [`a-${type}-disabled`]: disabled
    });
    return (
      <span className={classes}>
        <input
          name={name}
          type={type}
          checked={checked}
          disabled={disabled}
          onChange={this.onChange}
          style={style}
          value={value}
          className={`a-${type}-input`}
          {...others}
        />
        <span className={`a-${type}-inner`}>
          {renderCheckbox(type, checked, checkedIcon, uncheckedIcon, defaultCheckedIcon)}
        </span>
      </span>
    );
  }
}

export default SwitchBase;
