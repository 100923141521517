export function preventDefaultEvent(e) {
  e && e.preventDefault && e.preventDefault();
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function isFunction(func) {
  return typeof func === 'function';
}

export function getTransitionEvent() {
  var t;
  var el = document.createElement('fakeelement');
  var evt = '';
  var transitions = {
    transition: 'transitionend',
    OTransition: 'oTransitionEnd',
    MozTransition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd'
  };

  for (t in transitions) {
    if (el.style[t] !== undefined) {
      evt = transitions[t];
    }
  }

  return evt;
}

export function getAnimationEvent() {
  var t;
  var el = document.createElement('fakeelement');
  var evt = '';
  var transitions = {
    Animation: 'animationend',
    OAnimation: 'animationend',
    MozAnimation: 'animationend',
    WebkitAnimation: 'webkitAnimationEnd'
  };

  for (t in transitions) {
    if (el.style[t] !== undefined) {
      evt = transitions[t];
    }
  }

  return evt || transitions.Animation;
}

// bitwiseAnd is the substitute for operand `&`
// add this function because `&` cannot pass sonar code smell
// this function suits the cases for 0 and positive integer.
// negative integer isn't taken into account
function bitwiseAnd(num1, num2) {
  const binary1Arr = num1.toString(2).split('').reverse();
  const binary2Arr = num2.toString(2).split('').reverse();
  const loops = Math.min(binary1Arr.length, binary2Arr.length);
  let res = [];
  for (let i = 0; i < loops; i++) {
    res.push(binary1Arr[i] === '1' && binary2Arr[i] === '1' ? '1' : '0')
  }
  res.reverse();
  return parseInt(res.join(''), 2);
}

// bitwiseOr is the substitute for operand `|`
// add this function because `|` cannot pass sonar code smell
// this function suits the cases for 0 and positive integer.
// negative integer isn't taken into account
function bitwiseOr(num1, num2) {
  let longerBinary;
  let shorterBinary;
  let res = [];
  if (num1 < num2) {
    longerBinary = num2.toString(2).split('').reverse();
    shorterBinary = num1.toString(2).split('').reverse();
  } else {
    longerBinary = num1.toString(2).split('').reverse();
    shorterBinary = num2.toString(2).split('').reverse();
  }

  for (let i = 0, len = shorterBinary.length; i < len; i++) {
    res.push(longerBinary[i] === '1' || shorterBinary[i] === '1' ? '1' : '0');
  }
  for (let i = shorterBinary.length, len = longerBinary.length; i < len; i++) {
    res.push(longerBinary[i]);
  }

  res.reverse();
  return parseInt(res.join(''), 2);
}

export function guid() {
  return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    /**
     * previous version is:
     * var r = (Math.random() * 16) | 0,
     *    v = c === 'x' ? r : (r & 0x3) | 0x8;
     */
    const r = Math.floor(Math.random() * 16);
    const v = c === 'x' ? r : bitwiseOr(bitwiseAnd(r, 3), 8);
    return v.toString(16);
  });
}

export function chunk(array, chunkSize) {
  const R = [];
  for (var i = 0; i < array.length; i += chunkSize)
    R.push(array.slice(i, i + chunkSize));
  return R;
}
