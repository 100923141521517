//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';

type SwitchProps = {
  size?: 'default' | 'small',
  onText?: string,
  offText?: string,
  toggleBtnStyle?: string,
  on?: boolean,
  defaultChecked?: boolean,
  checked?: boolean,
  disabled?: boolean,
  onChange?: Function
};

class Switch extends Component<SwitchProps & CommonType, SwitchState> {
  constructor(props) {
    super(props);

    let checked = false;
    this.isControlled = props.checked != null;
    if (!this.isControlled) {
      // not controlled, use internal state
      checked = props.defaultChecked !== undefined ? props.defaultChecked : false;
    } else {
      checked = !!props.checked;
    }

    this.state = { checked };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.isControlled && nextProps.checked !== this.state.checked) {
      this.setState({
        checked: nextProps.checked
      });
    }
  }

  handleClick = (e: SyntheticEvent<*>) => {
    const { checked } = this.state;
    const { disabled, onChange } = this.props;
    if (disabled) {
      return;
    }
    const newChecked = !checked;
    if (!this.isControlled) {
      this.setState({
        checked: newChecked
      });
    }
    if (onChange) {
      onChange(newChecked, e);
    }
  };

  onKeyUp = (e: SyntheticKeyboardEvent<*>) => {
    if (e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 13) {
      e.preventDefault && e.preventDefault();
      this.handleClick(e);
    }
  };

  render() {
    const {
      onText,
      offText,
      size = 'default',
      toggleBtnStyle,
      className = '',
      disabled,
      on,
      label,
      onChange,
      ariaLabel,
      ...others
    } = this.props;

    const { checked } = this.state;

    const toggleWrapper = ClassNames(className, 'a-toggle',  {
      [`a-toggle-${size}`]: size === 'small',
      'a-toggle-text': onText && offText,
      disabled,
      checked
    });
    return (
      <div
        className={toggleWrapper}
        onClick={this.handleClick}
        onKeyUp={this.onKeyUp}
        {...others}
      >
        <input  type="checkbox" disabled={disabled} aria-label={ariaLabel}></input>
        <div className="a-toggle-mark">
          <span className="on">{onText}</span>
          <span className="off">{offText}</span>
          <span className="switch" style={{ toggleBtnStyle }} />
        </div>
        {
          label && (<div className="a-toggle-text">{label}</div>)
        }
      </div>
    );
  }
}

Switch.propTypes = {
  size: PropTypes.string,
  onText: PropTypes.string,
  offText: PropTypes.string,
  label: PropTypes.string,
  toggleBtnStyle: PropTypes.string,
  on: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool
};

export default Switch;
