import React from 'react';
import Button from './Button';

export default function ButtonGroupItem(props) {
  const {
    children,
    // eslint-disable-next-line react/prop-types
    isLoading, /* eslint-disable-line no-unused-vars */
    ...otherProps
  } = props;
  return (
    <Button {...otherProps}>
      {children}
    </Button>
  )
}
