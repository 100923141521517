//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';
import { KODIV } from '../index';
import {SlideDown} from 'react-slidedown'

type ProgressVerticalIndicatorProps = {
  children?: React.Element<any>
};

type ProgressVerticalIndicatorState = {};

class ProgressVerticalIndicator extends Component<ProgressVerticalIndicatorProps & CommonType,  ProgressVerticalIndicatorState>{
  constructor(props: Object) {
    super(props);
  }

  static defaultProps = {
    defaultActive: false,
    doneIndicators: []
  }

  onToggleActive(){
    this.props.onToggle && this.props.onToggle(this, !this.props.isActive);
  }

  onKeyDown(e){
    if(e.key === "Enter"){
      this.onToggleActive();
    }
  }

  render(){
   let {
    className = '',
    text,
    isActive,
    subIndicators,
    doneIndicators = [],
    } = this.props;

    const classForIcon = ClassNames("appkiticon", (isActive? "icon-up-chevron-fill": "icon-down-chevron-fill"));

    const parent = (<KODIV className="a-parent-indicator" tabIndex={0} onKeyDown={this.onKeyDown.bind(this)} onClick={this.onToggleActive.bind(this)}>
                      <span className="a-indicator-text"> {text}  </span><span className={classForIcon} />
                    </KODIV>);

    const subIndicatorDivs = subIndicators.map((e, index) => {
      const isDone = doneIndicators.includes(e);
      const subItemCn = ClassNames("a-child-indicator-item", {
        "a-progress-done": isDone
      })
      return (<div key={e+index} className={subItemCn}>{e}</div>);
    })
    const subCn = ClassNames("a-child-indicator")
    const slidedown = isActive? (<SlideDown transitionOnAppear={false}><div className={subCn}>{subIndicatorDivs}</div></SlideDown>) :
                                (<SlideDown transitionOnAppear={false}></SlideDown>);

    const cn = ClassNames('a-indicator', className);
    return  (
        <div className={cn}>
            {parent}
            {slidedown}
        </div>);
  }
}
ProgressVerticalIndicator.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  isActive: PropTypes.bool,
  onToggle: PropTypes.func,

  defaultActive: PropTypes.bool,
  subIndicators: PropTypes.arrayOf(PropTypes.string),
  doneIndicators: PropTypes.arrayOf(PropTypes.string)
};

ProgressVerticalIndicator.displayName = 'ProgressVerticalIndicator';
export default ProgressVerticalIndicator;
