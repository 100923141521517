//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import type { Node } from 'react';
import type { CommonType } from 'appkit-react-utils/commonType';

type TabProps = {
  /**
   * The content of Tabs
   */
  children?: Node,
  /**
   * The title of tab bar
   */
  label: string | Node,
  /**
   * The value of tab bar
   */
  value: string,
  /**
   * The icon element. If a string is provided, it will be used as the icon name.
   */
  icon?: any,
  /**
   * @ignore internal props
   * If `true, the tabbar will be actived
   */
  active?: boolean,
  /**
   * id of the tab content
   * this global attr is used for unit test
   */
  id?: string,
};

type TabState = {};

class Tab extends Component<TabProps & CommonType, TabState> {
  render() {
    const {
      style,
      className,
      children,
      active,
      label,
      // separate tabIndex to avoid tabindex attr on Tab content
      tabIndex,
      closable,
      close,
      ...otherProps
    } = this.props;

    if (typeof otherProps.id === 'string') otherProps.id = `tab-content-${otherProps.id}`;
    const classes: string = classnames(className, 'a-tab-pane', {
      [`active fadeIn animated`]: active
    });
    const tabContent = <div className="a-pane-detail-basic">{children}</div>;

    return (
      <div className={classes} {...otherProps}>
        {tabContent}
      </div>
    );
  }
}

Tab.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.any,
  value: PropTypes.string,
  active: PropTypes.bool,
  icon: PropTypes.any,
  id: PropTypes.string,
  children: PropTypes.any,
  closable:PropTypes.bool,
  colse:PropTypes.func,
};

Tab.displayName = "Tab";
export default Tab;
