import ClickOutside from 'react-click-outside';
import React, { Component } from 'react'
import classnames from 'classnames'

let outlineAction = {
  NOACTION: 0,
  CLICK: 1,
  CHANGE: 2,
  BLUR: 3,
  TAB: 4,
};

export default class DefaultButton extends ClickOutside(Component) {
  constructor(props) {
    super(props);

    this.state = {
      hasOutline: true,
      lastAction: outlineAction.NOACTION
    }
  }

  handleClickOutside = () => {
    if (this.state.lastAction === outlineAction.TAB || this.props.disabled) {
      this.setState({
        hasOutline: true
      });
    }
  };

  render() {
    const {
      className,
      ...props
    } = this.props;

    return (
      <button
        type="button"
        {...props} 
        className={classnames('-btn', className, {
          'without-outline': !this.state.hasOutline
        })}
        onMouseDown={() => {
          this.setState({
            hasOutline: false,
            lastAction: outlineAction.CLICK
          });
        }}
        onBlur={() => {
          this.setState({
            hasOutline: true
          });
        }}
        onKeyDown={e => {
          if (e.which === 9 || e.keyCode === 9) {
            this.setState({
              hasOutline: true,
              lastAction: outlineAction.TAB
            })
          }
        }}
      >
        {props.children}
      </button>
    );
  }
}
