import * as React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

const TYPES = ['success', 'warning', 'info', 'error', 'primary', 'secondary', 'light' , 'dark', 'count', ''];
const prefixCls = 'a-badge';

//Stateless Component
const Badge = function({
  type = 'primary',
  className = '',
  style,
  children,
  size='medium',
  ...others
}) {
  let sizeCls = '';
  if (size === 'small') {
    sizeCls = 'sm';
  }

  const classNames = ClassNames(prefixCls, className,  {
    [`${prefixCls}-${type}`]: type,
    [`${prefixCls}-${sizeCls}`]: sizeCls
  });

  return (
    <span className={classNames} style={style} {...others}>
      {children}
    </span>
  );
};

Badge.propTypes = {
  type: PropTypes.oneOf(TYPES),
  className: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.oneOf(["small", "medium"])
};

Badge.supportedTypes = TYPES;

export default Badge;
