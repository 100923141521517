//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import type { Node } from 'react';
import classnames from 'classnames';
import RcRate from 'rc-rate';
import type { CommonType } from 'appkit-react-utils/commonType';

type RatingProps = {
  /**
   * The content of Rating
   */
  children?: Node,
  size?: 'sm' | 'lg',
  count?: number,
  value?: number,
  defaultValue?: number,
  allowHalf?: boolean,
  allowClear?: boolean,
  disabled?: boolean,
  onChange?: (value: number) => any,
  onHoverChange?: (value: number) => any,
  character?: Node
};

type RatingState = {};

let outlineAction = {
  NOACTION: 0,
  CLICK: 1,
  CHANGE: 2,
  BLUR: 3,
};

class Rating extends Component<RatingProps & CommonType, RatingState> {
  constructor(props) {
    super(props)
    this.state = {
      hasOutLine: true,
      lastAction: outlineAction.NOACTION
    };
  }
  rcRate: any;
  initRate = (node: any) => {
    this.rcRate = node;
  };
  onMouseDown = () => {
    this.setState({
      hasOutLine: false,
      lastAction: outlineAction.CLICK
    });
  };
  onBlur = () => {
    const { lastAction } = this.state;
    if (lastAction === outlineAction.CLICK) {
      this.setState({
        hasOutLine: false,
        lastAction: outlineAction.BLUR
      });
    }
    else {
      this.setState({
        hasOutLine: true,
        lastAction: outlineAction.BLUR
      });
    }
  };
  onChange = (value) => {
    this.props.onChange && this.props.onChange(value)
    this.setState({
      lastAction: outlineAction.CHANGE
    });
  };
  render() {
    const {
      style,
      className,
      size = 'lg',
      ...otherProps
    } = this.props;
    const classes: string = classnames(className, {
      'a-rating': true,
      [`a-rating-${size}`]: true,
      [`a-rating-no-outline-click`]: !this.state.hasOutLine
    });
    //Fix default value bug
    let defaultValue;
    if (this.props.defaultValue === true) {
      defaultValue = 0;
    } else {
      defaultValue = this.props.defaultValue;
    }
    return (
      <div
        style={style}
        className={classes}
        onMouseDown={this.onMouseDown}
        onBlur={this.onBlur}
      >
        <RcRate
          ref={this.initRate}
          {...otherProps}
          onChange={this.onChange}
          defaultValue={defaultValue}
        />
      </div>
    );
  }
}

Rating.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
  count: PropTypes.number,
  value: PropTypes.number,
  defaultValue: PropTypes.number,
  allowHalf: PropTypes.bool,
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onHoverChange: PropTypes.func,
  character: PropTypes.node
};

Rating.displayName = 'Rating';

export default Rating;
