//@flow
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ClassNames from "classnames";
import type { CommonType } from "appkit-react-utils/commonType";
import { List } from "../List";
import ClickOutside from "react-click-outside";

type SelectListProps = {
  /**
   * Callback fired when the listitem is clicked.
   */
  onSelect?: Function,
  handleClickOutside?: Function,
  showSearchOnList?: boolean,
  onSearch?: Function
};

type SelectListState = {};

class SelectList extends PureComponent<
  SelectListProps & CommonType,
  SelectListState
> {
  static childContextTypes = {
    selectList: PropTypes.any
  };

  getChildContext() {
    return {
      selectList: {
        onSelect: this.props.onSelect
      }
    };
  }

  handleClickOutside = e => {
    this.props.handleClickOutside(e);
  };

  render() {
    const {
      style,
      className,
      onSelect,
      showSearchOnList,
      selectAll,
      searchWord,
      onSearch,
      children,
      hasSelectGroupTitle,
      visible,
      multiple,
      size,
      handleClickOutside,
      ...otherProps
    } = this.props;
    const classes: string = ClassNames(className, "a-select-list");

    const cn = ClassNames(
      "a-select-list-out",
      {
        "has-group": hasSelectGroupTitle
      },
      { open: visible },
      { "multiple-select": multiple },
      { [`a-select-list-out-${size}`]: size }
    );

    return (
      <div className={cn}>
        {selectAll}
        {showSearchOnList && (
          <input
            className="a-select-list-search"
            value={searchWord}
            placeholder="Search..."
            onChange={onSearch}
          />
        )}
        <div className="a-select-list-scroll-wrap" style={style}>
          <List className={classes} {...otherProps}>
            {children}
          </List>
        </div>
      </div>
    );
  }
}

SelectList.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.any,
  showSearchOnList: PropTypes.bool,
  selectAll: PropTypes.any,
  searchWord: PropTypes.string,
  onSearch: PropTypes.func,
  handleClickOutside: PropTypes.func
};

SelectList.displayName = "SelectList";

export default ClickOutside(SelectList);
