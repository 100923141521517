//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import type { Node } from 'react';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';
import { Animations, getCurrentTheme, getCurrentLevel } from '../index';

type ModalProps = {
  /**
   * The content of Modal
   */
  children?: Node,
  /**
   * The value of z-index property in css
   */
  zIndex?: number,
  /**
   * The modal visibility
   */
  visible?: boolean,
  /**
   * Cancel callback function
   */
  onCancel: Function,
  /**
   * Confirm callback function
   */
  backdropClosable?: boolean
};

type ModalState = {
  toggleAnimation: boolean,
  hideModal: boolean
};

const OPEN_CLASS = "a-modal-opened";
const TIME_DURATION = 300;

class Modal extends Component<ModalProps & CommonType, ModalState> {
  static defaultProps = {
    backdropClosable: true
  };
  constructor(props: Object) {
    super(props);
    this.state = {
      toggleAnimation: props.visible,
      hideModal: !props.visible
    };
  }
  openTime: number;

  componentDidUpdate(prevProps) {
    const props = this.props;
    const bodyClass = document.body && document.body.classList;
    if (props.visible) {
      // first show
      if (!prevProps.visible) {
        this.openTime = Date.now();
      }
      //use mouse scroll for the modal
      if (bodyClass && !bodyClass.contains(OPEN_CLASS)) {
        bodyClass.add(OPEN_CLASS);
      }
    } else {
      if (bodyClass) {
        setTimeout(() => {
          bodyClass.remove(OPEN_CLASS);
        }, TIME_DURATION);
      }
    }
  }

  componentWillUnmount(){
    const bodyClass = document.body && document.body.classList;
    if (bodyClass) {
      bodyClass.remove(OPEN_CLASS);
    }
  }

  onCancel = (e: SyntheticEvent<*>): void => {
    const onCancel = this.props.onCancel;
    if (onCancel) {
      onCancel(e);
    }
  };

  onMaskClick = (e: SyntheticEvent<*>): void => {
    if (Date.now() - this.openTime < TIME_DURATION) {
      return;
    }
    if (e.target === e.currentTarget) {
      this.onCancel(e);
    }
  }

  cleanTimer(){
    if(this.setStateTimer){
      clearTimeout(this.setStateTimer)
      this.setStateTimer = null;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    this.setState({ toggleAnimation: nextProps.visible });
    let self = this;
    /** Make sure that disappear animation **/
    this.cleanTimer();
    this.setStateTimer = setTimeout(() => {
      self && self.setState({ hideModal: !nextProps.visible });
    }, nextProps.visible ? 0 : TIME_DURATION);
  }

  render() {
    const {
      style,
      className,
      children,
      visible,
      onCancel,
      backdropClosable,
      zIndex,
      ...otherProps
    } = this.props;

    const classes = ClassNames('modal-dialog', className);

    const modalWrapperClass = ClassNames('a-modal appkit-animation animated fadeIn', {
      'modal-opened': !this.state.hideModal,
      'modal-closed': this.state.hideModal
    });

    const wrapperStyle = zIndex ? { zIndex: zIndex } : null;

    const content = React.Children.map(children, child => {
      let element;
      if (child.type && child.type.displayName === 'ModalHeader') {
        element = React.cloneElement(child, {
          onClick: item => this.onCancel(item)
        });
      } else {
        element = child;
      }

      return element;
    });

    const theme = getCurrentTheme();
    const level = getCurrentLevel();

    //turn modal into a themeContainer
    const contentCn = ClassNames("modal-content", {
      "a-theme-container": theme,
    });

    return (
      <div className={modalWrapperClass} style={wrapperStyle}>
        <Animations
          effect={this.state.toggleAnimation ? 'fadeInDown' : 'fadeOutUp'}
          active
        >
          <div className={classes}>
            <div style={style} className={contentCn} theme={theme} level={level} {...otherProps}>
              {content}
            </div>
          </div>
        </Animations>
        <Animations
          effect={this.state.toggleAnimation ? 'fadeIn' : 'fadeOut'}
          active
        >
          <div
            className="modal-backdrop"
            onKeyUp={backdropClosable ? this.onMaskClick : undefined}
            onClick={backdropClosable ? this.onMaskClick : undefined}
          />
        </Animations>
      </div>
    );
  }
}

Modal.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  visible: PropTypes.bool,
  backdropClosable: PropTypes.bool,
  onCancel: PropTypes.func
};

Modal.displayName = 'Modal';
export default Modal;
