//@flow


export function add(arg: number, step: number): number {
  let n1, n2;
  if (typeof arg === 'number') {
    return arg + step;
  } else {
    n1 = arg.toString().split('.')[0];
    n2 = arg.toString().split('.')[1];
    return Number((Number(n1) + step).toString() + '.' + n2);
  }
}

export function sub(arg: number, step: number): number {
  let n1, n2;
  if (typeof arg === 'number') {
    return arg - step;
  } else {
    n1 = arg.toString().split('.')[0];
    n2 = arg.toString().split('.')[1];
    return Number((Number(n1) - step).toString() + '.' + n2);
  }
}
