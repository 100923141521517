//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import type { Node } from 'react';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';

type StepProps = {
  kind: 'steps' | 'timeline',
  icon?: any,
  content?: string,
  status?: 'complete' | 'current',
  tooltip?: Node,
  isLastChild: boolean,
  children?: Node
};

type StepState = {};

class Step extends Component<StepProps & CommonType, StepState> {
  renderStepIcon() {
    const { icon } = this.props;
    return icon ? icon : <span className="appkiticon icon-check-mark-fill a-step-fill-hook" />;
  }

  renderMarker() {
    const { status, content } = this.props;
    return status === 'complete' ? this.renderStepIcon() : content;
  }

  render() {
    const {
      status,
      showTooltip,
      kind,
      style,
      className = '',
      content,
      isLastChild,
      children,
      ...otherProps
    } = this.props;

    if (kind === 'steps') {
      const stepClasses = ClassNames(className, status, 'a-single-step');
      return (
        <li className={stepClasses} style={style} {...otherProps}>
          <span className="a-step-marker">{this.renderMarker()}</span>
          <span className="a-single-step-text">
            <p className="a-step-single-title">{children}</p>
          </span>
        </li>
      );
    } else if (kind === 'timeline') {
      return (
        <li className={ClassNames(status, "a-single-time-step")} {...otherProps}>
          <span className="empty-placeholder"/>
          {children}
        </li>
      );
    }else{
      return (<div>{"unhandled kind={" + kind +  "} case" }</div>)
    }
  }
}

Step.propTypes = {
  kind: PropTypes.oneOf(["timeline", "steps"]),
  icon: PropTypes.any,
  showTooltip: PropTypes.bool,
  content: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string
};

Step.displayName = 'Step';

export default Step;
