//@flow
import * as React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';
import { KODIV } from '../index';


type Props = {
  closable?: boolean,
  onClick?: Function,
  children?: React.Element<any>
};

const ModalHeader = function({
  style,
  className = '',
  children,
  closable,
  onClick,
  ...others
}: Props & CommonType): React.Element<any> {
  const classNames = ClassNames('modal-header', className, {
    "a-increase-child-color-level-by-1": !(className && className.includes("a-increase-child-color-level-by"))
  });
  let handleClick = (e: SyntheticInputEvent<*>): void => {
    if (closable !== false && onClick) {
      onClick(e);
    }
  };
  return (
    <div className={classNames} style={style} {...others}>
      {children}
      {closable !== false && <KODIV className="a-close-btn"  onClick={handleClick} ><span className='appkiticon icon-close-fill'></span></KODIV>}
    </div>
  );
};

ModalHeader.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  closable: PropTypes.bool,
  onClick: PropTypes.func
};
ModalHeader.displayName = 'ModalHeader';

export default ModalHeader;
