//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';
import KeyCode from 'appkit-react-utils/keyCode';

import DefaultButton from '../Table/defaultButton'

type PagerProps = {
  /**
   * fields : Paginator with field and buttons
   * numeric : Pagination with numbers
   * carousel : Carousel Paginator
   * @type {('default' | 'simple' | 'numeric' | 'carousel')}
   */
  kind?: 'fields' | 'numeric' | 'carousel',
  /**
   * a callback function, executed when the page number is changed
   *
   * @type {Function}
   */
  onPageChange?: Function,
  current: number,
  text?: any,
  pageNums?: number
};

type PagerState = {
  value: number
};

class Pager extends Component<PagerProps & CommonType, PagerState> {
  constructor(props: Object) {
    super(props);
    const { current = 1 } = props;
    this.state = {
      value: current
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Object) {
    const { current } = nextProps;
    this.setState({ value: current });
  }

  handleInputChange = (e: SyntheticInputEvent<*>): void => {
    const value: number = Number(e.target.value);
    if (
      value < 0 ||
      value > Number(this.props.pageNums) ||
      Number.isNaN(value)
    ) {
      return;
    }
    this.setState({ value });
  };

  onKeyDown = (e: SyntheticKeyboardEvent<*>): void => {
    const { onPageChange } = this.props;
    switch (e.keyCode) {
      case KeyCode.ENTER: // enter
        e.preventDefault && e.preventDefault();
        onPageChange && onPageChange(this.state.value);
        break;
      case KeyCode.ARROW_UP:
        onPageChange && onPageChange(this.state.value - 1)
        break;
      case KeyCode.ARROW_DOWN:
        onPageChange && onPageChange(this.state.value + 1)
        break;
      default:
        break;
    }
  };

  onBlur = (e: SyntheticInputEvent<*>): void => {
    if (e) {
      e.preventDefault && e.preventDefault();
    }

    let value = this.state.value;
    if (value === 0) {
      value = 1;
    }

    const { onPageChange } = this.props;
    onPageChange && onPageChange(value);
  };

  onClick = (v: number) => {
    const { onPageChange } = this.props;
    onPageChange && onPageChange(v);
  };

  renderPagerWithFields = (): any => {
    const { pageNums = 1 } = this.props;
    const { value } = this.state;
    return (
      <span>
        <input
          type="text"
          onChange={this.handleInputChange}
          onKeyDown={this.onKeyDown}
          onBlur={this.onBlur}
          className="a-current-page"
          value={value}
          pattern="\d+"
        />{' '}
        <span className="page-text">of {pageNums}</span>
      </span>
    );
  };

  renderNumericPager = (page: any): any => {
    const { current, onPageChange } = this.props;
    const buttonClasses = ClassNames('pager-numeric', {
      [`active`]: page === current,
      [`unclickable-pager`]: !onPageChange
    });
    return (
      <a className={buttonClasses} onClick={this.onClick.bind(this, page)}>
        {page}
      </a>
    );
  };

  renderCarousel = (page: any): any => {
    const { current } = this.props;
    const buttonClasses = ClassNames('a-pager-carousel', {
      [`active`]: page === current
    });
    return (
      <DefaultButton
        className={buttonClasses}
        onClick={this.onClick.bind(this, page)}
        ></DefaultButton>
    );
  };

  renderPager = (): any => {
    const { kind, text } = this.props;
    if (kind === 'fields') {
      return this.renderPagerWithFields();
    } else if (kind === 'numeric') {
      return this.renderNumericPager(text);
    } else if (kind === 'carousel') {
      return this.renderCarousel(text);
    }else{
      return (null);          
    }
  };

  render() {
    const {
      style,
      className,
      current,
      kind,
      onPageChange,
      text,
      pageNums,
      ...otherProps
    } = this.props;
    const shape: string = kind === 'numeric' ? 'circle' : 'default';
    return (
      <li
        className={
          ClassNames('', {
            'a-page a-page-number': kind !== 'carousel',
            'a-carousel': kind === 'carousel',
            [`${shape}`]: shape
          })
        }
        {...otherProps}
      >
        {this.renderPager()}
      </li>
    );
  }
}

Pager.propTypes = {
  kind: PropTypes.string,
  onPageChange: PropTypes.func,
  current: PropTypes.number,
  text: PropTypes.any,
  pageNums: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string
};

Pager.displayName = 'Pager';

export default Pager;
