//@flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import type { CommonType } from 'appkit-react-utils/commonType';

type DividerProps = {};

class Divider extends Component<DividerProps & CommonType, {}> {
  render() {
    const { ...otherProps } = this.props;
    const classes: string = classNames('a-list-divider');
    return <hr className={classes} {...otherProps} />;
  }
}

Divider.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string
};

Divider.displayName = 'Divider';

export default Divider;
