//@flow
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import type { Node } from 'react';
import type { CommonType } from 'appkit-react-utils/commonType';
import Modal from './Modal';

type ModalProps = {
  /**
   * Cancel callback function
   */
  onCancel: Function,
  /**
   * The content of Modal
   */
  children?: Node
};

/**
 * ModalInstaller will setup the modal under <body/>.
 * We use new portal API, which is a new feature after react 16, to append the modal in body
 * refer to: 1. https://reactjs.org/docs/portals.html
 *           2. https://codepen.io/gaearon/pen/yzMaBd
 */
class ModalInstaller extends Component<ModalProps & CommonType> {
  node: HTMLDivElement;
  constructor(props: ModalProps & CommonType) {
    super(props);
    this.node = document.createElement('div');
  }

  componentDidMount() {
    document.body && document.body.appendChild(this.node);
  }

  componentWillUnmount() {
    document.body && document.body.removeChild(this.node);
  }

  render() {
    const { onCancel, children, ...otherProps } = this.props;

    return ReactDOM.createPortal(
      <Modal ref={ref => this.modal = ref}  onCancel={onCancel} {...otherProps}>
        {children}
      </Modal>,
      this.node
    );
  }
}

export default ModalInstaller;
